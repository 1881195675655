import { AnyAction } from "@reduxjs/toolkit";
import { Middleware } from "redux";
import { AppDispatch } from "..";
import { logout } from "../ducks/auth";

const createHandleExpiredTokenMiddleware = () => {
  const handleExpiredMiddleware: Middleware = (state) => (
    next: AppDispatch
  ) => (action: AnyAction) => {
    if (
      state.getState().auth.data &&
      action.type.endsWith("Error") &&
      action.payload &&
      action.payload.status === 403
    ) {
      localStorage.removeItem("token");
      state.dispatch(logout());
      return;
    }

    return next(action);
  };

  return handleExpiredMiddleware;
};

export default createHandleExpiredTokenMiddleware;
