import styled from "styled-components";

export const Nav = styled.nav`
  flex-direction: row;
  z-index: 3;
  top: 0;
  border-width: 0;
  background-color: rgba(60, 60, 59, 1);
  color: ${(props) => props.theme.color.lightgray};
  position: absolute;
  width: 100%;
  margin-bottom: 50px;
  z-index: 99999999;

  a {
    color: ${(props) => props.theme.color.white};
  }

  @media (min-width: ${(props) => props.theme.breakpoint.mobile}) {
    position: sticky;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    width: 225px;
    color: initial;
    flex: initial;
    max-height: 100vh;
    overflow-y: auto;
    margin-bottom: 0;

    .collapse {
      display: block !important;
    }
  }
`;

export default Nav;
