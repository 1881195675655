import styled from "styled-components";

const SimpleInput = styled.input`
  font-family: inherit;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  color: ${(props) => props.theme.color.editorbackground};
  border: 1px solid ${(props) => props.theme.color.editorborder};
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
  min-height: 30px;
  outline: 0;

  &:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    border-color: rgba(0, 159, 227, 1);
  }
`;

export default SimpleInput;
