import React from "react";
import { Field } from "react-final-form";

import MatInput from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "./styled/StyledWrapper";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  auth?: boolean;
  inputProps?: any;
  validation?: (value: string) => string | undefined;
}

const Input = ({
  name,
  label,
  type,
  required,
  disabled,
  auth,
  inputProps,
  validation,
}: Props) => (
  <Field
    name={name}
    validate={(value: string) => {
      return (
        (required ? checkRequired(value) : undefined) ||
        (validation ? validation(value) : undefined)
      );
    }}
  >
    {({ input, meta }) => (
      <StyledWrapper auth={auth}>
        <FormControl>
          <InputLabel required={required} error={meta.touched && meta.error}>
            {label}
          </InputLabel>
          <MatInput
            {...input}
            type={type || "text"}
            required={required}
            disabled={disabled}
            inputProps={inputProps}
          />
          <FormHelperText error>
            {meta.touched && meta.error ? meta.error : " "}
          </FormHelperText>
        </FormControl>
      </StyledWrapper>
    )}
  </Field>
);

export default Input;
