import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all } from "redux-saga/effects";

import { fetchList, fetchListSuccess, fetchListError } from ".";
import { FilteringParams, Language } from "./types";
import { GET, Response } from "../../../utils/request";
import toast from "../../../components/Toaster";

const path = `/api/locale/languages`;
const api = {
  list: () => `${path}/get`,
};

function* fetchListSaga(
  action: PayloadAction<{
    filtering: FilteringParams;
  }>
) {
  try {
    const response: Response<{ languages: Language[] }> = yield GET(
      api.list(),
      action.payload.filtering
    );

    yield put(fetchListSuccess(response.data.languages));
  } catch (error) {
    yield put(fetchListError(error));
    toast.error(error.message);
  }
}

function* fetchListWatcher() {
  yield takeLatest(fetchList.type, fetchListSaga);
}

export function* rootWatcher() {
  yield all([fork(fetchListWatcher)]);
}

export default rootWatcher;
