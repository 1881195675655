import React, { ReactFragment } from "react";
import SpinnerWrapper from "./styled/SpinnerWrapper";
import CircularProgress from "./styled/CircularProgress";
import Wrapper from "./styled/Wrapper";

export interface LoadingProps {
  isLoading: boolean;
  absolute?: "true";
}

interface Props extends LoadingProps {
  children: ReactFragment;
  text?: string;
}

const Loader = ({ children, isLoading, text, absolute }: Props) => (
  <Wrapper isLoading={isLoading}>
    <SpinnerWrapper isLoading={isLoading} absolute={absolute}>
      <CircularProgress size={150} />
      {text && <h1>{text}</h1>}
    </SpinnerWrapper>
    {children}
  </Wrapper>
);

export default Loader;
