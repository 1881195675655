import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import { useDispatch } from "react-redux";
import createFormSubmissionMiddleware from "./middlewares/form-submission";
import rootReducer from "./reducers";
import createHandleExpiredTokenMiddleware from "./middlewares/expiredToken";

const sagaMiddleware = createSagaMiddleware();
const formSubmissionMiddleware = createFormSubmissionMiddleware();
const handleExpiredTokenMiddleware = createHandleExpiredTokenMiddleware();

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  middleware: [
    ...getDefaultMiddleware<
      RootState,
      { thunk: boolean; serializableCheck: boolean }
    >({
      thunk: false,
      serializableCheck: false,
    }),
    formSubmissionMiddleware,
    sagaMiddleware,
    handleExpiredTokenMiddleware,
  ],
  reducer: rootReducer,
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducers", () => {
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
