import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  State,
  Group,
  FilteringParams,
  DefaultFilteringParams,
  DefaultPaginationParams,
} from "./types";
import {
  Child,
  FilteringParams as ChildrenFilteringParams,
  DefaultPaginationParams as ChildrenDefaultPaginationParams,
  DefaultFilteringParams as ChildrenDefaultFilteringParams,
} from "../../../types/children";
import { FormSubmissionMeta } from "../../../types";
import { CreateGroupData } from "../../../pages/CreateGroup";
import { UpdateGroupData } from "../../../pages/UpdateGroup";
import { Pagination } from "../../../types/pagination";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    current: null,
    list: null,
    children: null,
  },
  loading: {
    current: false,
    list: false,
    children: false,
  },
  error: {
    current: null,
    list: null,
    children: null,
  },
  pagination: {
    list: DefaultPaginationParams,
    children: ChildrenDefaultPaginationParams,
  },
  filtering: {
    list: DefaultFilteringParams,
    children: ChildrenDefaultFilteringParams,
  },
};

const slice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    fetchCurrent: (state, _: PayloadAction<string>) => {
      state.data.current = null;
      state.loading.current = true;
      state.error.current = null;
    },
    fetchCurrentSuccess: (state, action: PayloadAction<Group>) => {
      state.data.current = action.payload;
      state.loading.current = false;
    },
    fetchCurrentError: (state, action: PayloadAction<RequestError>) => {
      state.error.current = action.payload.message;
      state.loading.current = false;
    },
    fetchList: (state, _: PayloadAction<{}>) => {
      state.data.list = null;
      state.loading.list = true;
      state.error.list = null;
    },
    fetchListSuccess: (
      state,
      action: PayloadAction<{
        data: Array<Group>;
        pagination: Pagination;
        filtering: FilteringParams;
      }>
    ) => {
      state.data.list = action.payload.data;
      state.loading.list = false;
      state.pagination.list = action.payload.pagination;
      state.filtering.list = action.payload.filtering;
    },
    fetchListError: (state, action: PayloadAction<RequestError>) => {
      state.error.list = action.payload.message;
      state.loading.list = false;
    },
    fetchChildren: (state, action: PayloadAction<{}>) => {
      state.data.children = null;
      state.loading.children = true;
      state.error.children = null;
    },
    fetchChildrenSuccess: (
      state,
      action: PayloadAction<{
        data: Array<Child>;
        pagination: Pagination;
        filtering: ChildrenFilteringParams;
      }>
    ) => {
      state.data.children = action.payload.data;
      state.loading.children = false;
      state.pagination.children = action.payload.pagination;
      state.filtering.children = action.payload.filtering;
    },
    fetchChildrenError: (state, action: PayloadAction<RequestError>) => {
      state.error.children = action.payload.message;
      state.loading.children = false;
    },
    create: {
      reducer: (state) => {
        state.data.current = null;
        state.error.current = null;
        state.loading.current = true;
      },
      prepare: (payload: CreateGroupData, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    createSuccess: {
      reducer: (state, action: PayloadAction<Group>) => {
        state.data.current = action.payload;
        state.loading.current = false;
      },
      prepare: (payload: Group, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    createError: {
      reducer: (state, action: PayloadAction<RequestError>) => {
        state.error.current = action.payload.message;
        state.loading.current = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    update: {
      reducer: (state) => {
        state.data.current = null;
        state.error.current = null;
        state.loading.current = true;
      },
      prepare: (payload: UpdateGroupData, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateSuccess: {
      reducer: (state, action: PayloadAction<Group>) => {
        state.data.current = action.payload;
        state.loading.current = false;
      },
      prepare: (payload: Group, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateError: {
      reducer: (state, action: PayloadAction<RequestError>) => {
        state.error.current = action.payload.message;
        state.loading.current = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    delete_: (state, action: PayloadAction<string>) => {
      state.error.current = null;
      state.loading.current = true;
    },
    deleteSuccess: (state) => {
      state.data.current = null;
      state.loading.current = false;
    },
    deleteError: (state, action: PayloadAction<RequestError>) => {
      state.error.current = action.payload.message;
      state.loading.current = false;
    },
  },
});

export const {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,

  fetchList,
  fetchListSuccess,
  fetchListError,

  fetchChildren,
  fetchChildrenSuccess,
  fetchChildrenError,

  create,
  createSuccess,
  createError,

  update,
  updateSuccess,
  updateError,

  delete_,
  deleteSuccess,
  deleteError,
} = slice.actions;

export default slice.reducer;
