import { NumberSamplesListElement } from "../../../pages/Sessions/types";
import { Pagination } from "../../../types/pagination";

export interface Provider {
  firstname?: string;
  userId?: string;
  email?: string;
  lastname?: string;
}

export interface SessionStatistic {
  duration: number;
  sessions: number;
  sessionTime: Date;
  avgDuration: number;
}

export interface GroupedSessionStatistic extends SessionStatistic {
  duration: number;
  sessions: number;
  avgDuration: number;
  sessionName?: string;
  provider?: Provider;
  user?: Provider;
}

export interface Session {
  duration: number;
  sessionId: string;
  sessionTime: Date;
  sessionName?: string;
  provider?: Provider;
  user?: Provider;
}

export interface DatasetEntry {
  label: string;
  fillColor: string;
  strokeColor: string;
  pointColor: string;
  pointStrokeColor: string;
  pointHighlightFill: string;
  pointHighlightStroke: string;
  data: string[];
}

export interface Channel {
  visualMin: number;
  data?: number[][];
  visualMax: number;
  channelName: string;
  type: number;
  nodeId: string;
  sampleRate: number;
  dataUrl?: string;
  totalSamples: number;
  dataSamples: number;
  dataSampleRate: number;
}

export interface Parameter {
  parameter: number;
  name: string;
  type: number;
  userId: string;
}

export interface Parameters {
  previousSessionParameters?: Parameter[];
  globalParameters?: Parameter[];
  globalSessionParameters?: Parameter[];
  currentSessionParameters?: Parameter[];
}

export interface SessionDetails {
  debitorId: string;
  stopDateTime?: Date;
  startDateTime?: Date;
  channels: Channel[];
  dataChunkId: string;
  supervisorId: string;
  userId?: string;
  parameters: Parameters;
  classifierName?: string;
  stateMachineName?: string;
  experienceName?: string;
}

export type sortDir = "asc" | "desc";

export interface SortingParams {
  sortKey?: string;
  sortDir?: sortDir;
}

export interface FilteringParams {
  filterKey?: string;
  filterValue?: string;
}

export interface StatisticFilteringParams {
  interval?: string;
  offset?: number;
  startDateTime?: string;
  endDateTime?: string;
}

export const DefaultSortingParams = {
  sortKey: "sessionTime",
  sortDir: "desc" as sortDir,
};

export const DefaultStatisticFilteringParams = {};

export const DefaultFilteringParams = {};

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 10,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export interface State {
  data: {
    current: SessionDetails | null;
    list: Array<Session> | null;
    monthlyStatisticList: Array<SessionStatistic> | null;
    groupedStatisticList: Array<GroupedSessionStatistic> | null;
  };
  loading: {
    current: boolean;
    list: boolean;
    monthlyStatisticList: boolean;
    groupedStatisticList: boolean;
  };
  error: {
    current: string | null;
    list: string | null;
    monthlyStatisticList: string | null;
    groupedStatisticList: string | null;
  };
  pagination: {
    list: Pagination;
    groupedStatisticList: Pagination;
  };
  sorting: {
    groupedStatisticList: SortingParams;
  };
  filtering: {
    statisticList: StatisticFilteringParams;
    groupedStatisticList: FilteringParams;
  };
  needUpgrade: boolean;
}

export interface SessionParams {
  sessionId: string;
  clamping?: boolean;
  token?: string;
  numberSamples: NumberSamplesListElement;
}

export interface SessionHistoryParams {
  userId?: string | undefined;
  supervisorId?: string | undefined;
}
