import styled from "styled-components";

const Wrapper = styled.div`
  && .MuiTabs-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .MuiButtonBase-root {
      padding: 12px 24px;
      .MuiTab-wrapper {
        /* TODO: global constant */
        font-family: "SourceSansPro-Light", "Helvetica Neue", "Helvetica";
      }
    }

    .MuiButtonBase-root.Mui-selected {
      background-color: ${(props) => props.theme.color.blue};
      color: ${(props) => props.theme.color.white};
    }

    .MuiTabs-indicator {
      background-color: ${(props) => props.theme.color.blue};
    }
  }
`;

export default Wrapper;
