import React, { Dispatch, SetStateAction } from "react";
import ConfirmationDialog from "./index";
import { theme } from "../../styled";
import labels from "../../utils/labels";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
}

const title = labels.deleteConfirmationDialog.title;

const content = (
  <p style={{ color: theme.color.black }}>
    {labels.deleteConfirmationDialog.content}
  </p>
);

const DeleteConfirmationDialog = (props: Props) => {
  return (
    <ConfirmationDialog
      {...props}
      title={title}
      children={content}
      onCancel={() => props.setOpen(false)}
    />
  );
};

export default DeleteConfirmationDialog;
