import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectCurrent } from "../../store/ducks/products/selectors";
import { useAppDispatch } from "../../store";
import Button from "../Login/styled/Button";
import { fetchCurrent, purchase } from "../../store/ducks/products";
import ProductDetails from "./ProductDetails";
import {
  faCreditCard,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../styled/Icon";
import { selectUser } from "../../store/ducks/auth/selectors";
import Link from "../../styled/Link";
import StripeCheckout, { Token } from "react-stripe-checkout";
import { FORM_ERROR } from "final-form";
import { ProductTypes, stripeData, Views } from "./constants";
import PurchaseView from "./styled/PurchaseView";

export interface PaymentData {
  email: string;
  receipt: string | undefined;
  productId: string | undefined;
}

interface Props {
  view: Views;
  productId: string;
  goToStart: () => void;
  openThanks: () => void;
}

const Payment = ({ view, productId, goToStart, openThanks }: Props) => {
  const dispatch = useAppDispatch();
  const auth = useSelector(selectUser)!;

  const product = useSelector(selectCurrent);

  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (view === Views.payment) {
      dispatch(fetchCurrent(productId));
    }
  }, [dispatch, view, productId]);

  const onToken = (token: Token) => {
    const data = {
      email: token.email,
      receipt: token.id,
      productId: productId,
    };
    return new Promise((resolve, reject) => {
      dispatch(purchase(data, { resolve, reject }));
    })
      .then(() => {
        openThanks();
      })
      .catch((error) => {
        return {
          [FORM_ERROR]: error,
        };
      });
  };

  return (
    <PurchaseView>
      {product && (
        <Grid container direction="column" justify="center" alignItems="center">
          <ProductDetails product={product} />
          <br />
          <br />
          {(productId === ProductTypes.stripe_neurofeedbackuser ||
            productId === ProductTypes.stripe_neurofeedbackprovider) && (
            <form>
              <Grid
                container
                item
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <StripeCheckout
                  stripeKey={stripeData.key}
                  name={stripeData[productId].key}
                  description={stripeData[productId].description}
                  image={stripeData.image}
                  locale={stripeData.locale}
                  currency={stripeData.currency}
                  panelLabel={stripeData.panelLabel}
                  label={stripeData.label}
                  token={onToken}
                >
                  <Grid item>
                    <Button blue="true" type="button">
                      <Icon icon={faCreditCard} size="lg" />
                      Pay with Card
                    </Button>
                  </Grid>
                </StripeCheckout>
                <Grid item>
                  <Button blue="true" type="button" onClick={goToStart}>
                    <Icon icon={faTimes} size="lg" />
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          <br />
          <br />
          <p>Step: 3/4</p>
          <h3
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon icon={faInfoCircle} size="lg" /> Billing details
          </h3>
          {collapsed && (
            <p
              style={{
                backgroundColor: "#bcbcbc",
                padding: "10px",
                borderRadius: "12px",
              }}
            >
              Payments are billed at the time of purchase, and subscriptions
              automatically renew at the end of the initial term, at the rate
              secured at the time of purchase. neuromore does not offer refunds
              on the monthly subscriptions. You may cancel your subscription at
              any time by emailing contact@neuromore.com. We use{" "}
              <Link to={{ pathname: "https://stripe.com" }} target="_blank">
                Stripe
              </Link>{" "}
              as payment provider and never get in touch with your credit card
              data! After a successful payment the neuromore account with the
              user id '<b>{auth.userId}</b>' will be adjusted. Please re-login
              to neuromore Studio and the User Portal afterwards. Any questions?
              Check out our{" "}
              <Link
                to={{ pathname: "http://www.neuromore.com/support" }}
                target="_blank"
              >
                support sites
              </Link>
            </p>
          )}
        </Grid>
      )}
    </PurchaseView>
  );
};

export default Payment;
