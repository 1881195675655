import React from "react";
import Header from "../../components/Header";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import config from "config";
import ExternalLink from "../../styled/ExternalLink";
import messages from "./messages";
import { Section } from "./styled/Section";

const Downloads = () => (
  <article>
    <Header>{messages.header}</Header>
    <p>{messages.illustration}</p>
    {!!config.downloads && (
      <>
        {config.downloads.map((section) => (
          <Section key={section.label}>
            <Header level={2}>{section.label}</Header>
            {section.items.map((item) => (
              <ExternalLink key={item.link} href={item.link} target="_blank">
                <Button blue="true">
                  <Icon icon={faDownload} size="lg" />
                  {item.label}
                </Button>
              </ExternalLink>
            ))}
          </Section>
        ))}
      </>
    )}
  </article>
);

export default Downloads;
