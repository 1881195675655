import { Rule } from "../store/ducks/rule/types";

export const required = (value: string) =>
  value ? undefined : "This is required.";

export const notNegative = (value: string) =>
  value && parseInt(value) < 0
    ? "This must be greater or equals 0."
    : undefined;

export const noSpaces = (value: string) =>
  value && value.indexOf(" ") !== -1 ? "Whitespaces not permitted." : undefined;

export const composeValidators = (
  ...validators: Array<(value: string) => string | undefined>
) => (value: string) =>
  validators.reduce<string | undefined>(
    (error, validator) => error || validator(value),
    undefined
  );

export const email = (value: string) =>
  value && value.match(/^.+@.+\..+$/)
    ? undefined
    : "Doesn't look like a valid e-mail address.";

export const password = (value: string) =>
  value && value.match(/(?=.*\d)(?=.*[a-z])/)
    ? undefined
    : "At least 1 digit and 1 letter.";

export const minLength = (value: string, length: number) =>
  value && value.length >= length ? undefined : "Minimum of 6 characters.";

export const maxLength = (value: string, length: number) =>
  value && value.length <= length ? undefined : "Maximum of 20 characters.";

export interface FieldSettingParam {
  name?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface FieldSetting {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

export const prepareFields = (
  fields: Record<string, FieldSettingParam>,
  backendRule: Rule | undefined,
  labels: Record<string, string> = {}
) => {
  let settings: Record<string, FieldSetting> = {};

  if (!backendRule || !backendRule.options) {
    // fill with defaults
    Object.keys(fields).forEach(
      (key) =>
        (settings[key] = {
          name: key,
          label: fields[key].label || labels[key],
          disabled:
            fields[key].disabled === undefined ? true : fields[key].disabled,
          required:
            fields[key].required === undefined ? false : fields[key].required,
        })
    );
  } else {
    // fill base on backend rules
    const options = JSON.parse(backendRule.options);
    Object.keys(fields).map(
      (key) =>
        (settings[key] = {
          name: key,
          label: fields[key].label || labels[key],
          disabled:
            fields[key].disabled ??
            (!options[key] ||
              (options[key].length === 1 && options[key][0] === "empty")),
          required:
            fields[key].required ??
            (options.required && options.required.indexOf(key) !== -1),
        })
    );
  }
  return settings;
};
