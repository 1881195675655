import { Pagination } from "../../../types/pagination";

export interface Color {
  a: number;
  r: number;
  b: number;
  g: number;
}

export interface FileAttribute {
  color?: Color;
  features?: string[];
}

export enum TagType {
  "DEVICE" = "DEVICE",
  "DURATION" = "DURATION",
  "LICENSE" = "LICENSE",
  "AUTHOR" = "AUTHOR",
  "CATEGORY" = "CATEGORY",
  "COUNTRY" = "COUNTRY",
  "LANGUAGE" = "LANGUAGE",
}

export interface Tag {
  type: string;
  value: string;
}

export interface ItemInfo {
  id: string;
  name: string;
  type: string;
  backgroundUrl?: string;
  attributes?: FileAttribute;
  iconUrl?: string;
  parentId?: string;
}

export interface ParentItemInfo extends ItemInfo {}

export interface ChildItemInfo extends ItemInfo {
  summary?: string;
  creud: string;
  tags: Tag[];
  credits?: number;
}

export interface ItemMeta {
  summary?: string;
  backgroundUrl?: string;
  itemId?: string;
  custom?: string;
  description?: string;
  iconUrl?: string;
  tags?: Tag[];
  credits?: string;
  releaseDateTime?: Date;
}

export enum FileType {
  "EXPERIENCE" = "EXPERIENCE",
  "STATEMACHINE" = "STATEMACHINE",
  "CLASSIFIER" = "CLASSIFIER",
  "FOLDER" = "FOLDER",
}

export interface FileInfo {
  currentRevision: number;
  name: string;
  type: FileType;
  creud: boolean[];
  content: string;
  fileId: string;
  revision: number;
}

export interface FilteringParams {
  itemTypes?: string;
  tags?: string;
  itemId?: string;
  sortKey?: string;
  sort?: string;
  name?: string;
  priceMin?: number;
  priceMax?: number;
}

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 100,
};

export const DefaultFilteringParams = {
  itemTypes: "[folder,experience,statemachine,classifier]",
  sortKey: "priority",
  sort: "asc",
  tags: undefined,
  itemId: undefined,
  name: undefined,
  priceMin: undefined,
  priceMax: undefined,
};

export interface State {
  data: {
    file: FileInfo | null;
    itemMeta: ItemMeta | null;
    parentItems: Array<ParentItemInfo> | null;
    childItems: Array<ChildItemInfo> | null;
  };
  loading: {
    file: boolean;
    itemMeta: boolean;
    items: boolean;
  };
  error: {
    file: string | null;
    itemMeta: string | null;
    items: string | null;
  };
  pagination: {
    items: Pagination;
  };
  filtering: {
    items: FilteringParams | null;
  };
}

export type ImageCategory = "icons" | "backgrounds";
