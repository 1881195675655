import { RootState } from "../..";

export const selectCurrent = (state: RootState) => state.users.data.current;

export const selectIsCurrentLoading = (state: RootState) =>
  state.users.loading.current;

export const selectList = (state: RootState) => state.users.data.list;

export const selectListPagination = (state: RootState) =>
  state.users.pagination.list;

export const selectIsListLoading = (state: RootState) =>
  state.users.loading.list;

export const selectListFiltering = (state: RootState) =>
  state.users.filtering.list;

export const selectChildren = (state: RootState) => state.users.data.children;

export const selectIsChildrenLoading = (state: RootState) =>
  state.users.loading.children;

export const selectChildrenPagination = (state: RootState) =>
  state.users.pagination.children;

export const selectChildrenFiltering = (state: RootState) =>
  state.users.filtering.children;
