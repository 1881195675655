import {
  faAlignCenter,
  faFolder,
  faRetweet,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FileType } from "../../store/ducks/files/types";
import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";

export const fileIcons: any = {
  [FileType.FOLDER]: faFolder,
  [FileType.STATEMACHINE]: faRetweet,
  [FileType.CLASSIFIER]: faAlignCenter,
  [FileType.EXPERIENCE]: faStar,
};

export const sortingKeyOptions = [
  { label: "Priority", value: "priority" },
  { label: "Release Date", value: "releasedate" },
  { label: "Name", value: "name" },
  { label: "Price", value: "price" },
];

export const typeOptions = [
  { label: "License", value: "LICENSE" },
  { label: "Country", value: "COUNTRY" },
  { label: "Author", value: "AUTHOR" },
  { label: "Language", value: "LANGUAGE" },
  { label: "Category", value: "CATEGORY" },
];

export const sortingOptions = [
  { label: "Asc", value: "asc" },
  { label: "Desc", value: "desc" },
];

export const durationSliderOptions = [
  { label: "0-2", value: 0 },
  { label: "2-5", value: 1 },
  { label: "5-10", value: 2 },
  { label: "10-30", value: 3 },
  { label: "30-60", value: 4 },
  { label: "60-1440", value: 5 },
];

export const features = ["muse", "gsr", "audio", "subpac", "hrm"];

export const fields: Record<string, FieldSetting> = prepareFields(
  {
    summary: { disabled: false },
    description: { disabled: false },
    backgroundUrl: { disabled: false },
    color: { disabled: false },
    iconUrl: { disabled: false },
    priority: { disabled: false },

    //tags
    audioUrl: { disabled: false },
    videoUrl: { disabled: false },
    price: { disabled: false },
    releaseDateTime: { disabled: false },
    duration: { disabled: false },
    type: { disabled: false },
    name: { disabled: false },
    credits: { disabled: false },
    tagType: { disabled: false },
    tagValue: { disabled: false },

    // create experience
    filename: { required: true, disabled: false },
  },
  undefined,
  labels.files
);
