import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { RootState, useAppDispatch } from "../../store";
import {
  DefaultFilteringParams,
  DefaultPaginationParams,
} from "../../types/children";
import SimpleInput from "../../styled/SimpleInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableWrapper from "../../styled/Table/TableWrapper";
import Pagination from "../../components/Pagination";
import { useDebouncedCallback } from "use-debounce";
import { MemberType } from "./types";
import { iconsMap, linksMap } from "./utils";
import Link from "../../styled/Link";
import ItemWrapper from "../../pages/Filesystem/styled/Common/ItemWrapper";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../styled/Icon";
import { Tooltip } from "@material-ui/core";
import TextLink from "../../styled/TextLink";
import UIconWrapper from "./styled/Icon";
import TooltipWrapper from "./styled/TooltipWrapper";
import labels from "../../utils/labels";

type InputEvent = ChangeEvent<HTMLInputElement>;
type ChangeHandler = (e: InputEvent) => void;

interface Props {
  title: string;
  idField: string;
  selectList: (state: RootState) => any[] | null;
  selectListPagination: (state: RootState) => any | null;
  fetchList: ActionCreatorWithPayload<{}, string>;
  appendChild: (id: string) => void;
  type: MemberType;
}

const Candidates = ({
  title,
  idField,
  selectList,
  selectListPagination,
  fetchList,
  appendChild,
  type,
}: Props) => {
  const dispatch = useAppDispatch();

  const list = useSelector(selectList);
  const pagination = useSelector(selectListPagination);

  useEffect(() => {
    dispatch(
      fetchList({
        pagination: DefaultPaginationParams,
        filtering: DefaultFilteringParams,
      })
    );
  }, [dispatch, fetchList]);

  const [search, setSearch] = useState(DefaultFilteringParams.idFilter);

  const [debouncedHandleChangeSearch] = useDebouncedCallback(
    (idFilter: string) => {
      dispatch(
        fetchList({
          pagination: {
            pageIndex: 0,
          },
          filtering: {
            idFilter: idFilter,
          },
        })
      );
    },
    1000
  );

  const onSearchChanged: ChangeHandler = (e) => {
    setSearch(e.target.value);
    debouncedHandleChangeSearch(e.target.value);
  };

  const onAppendChild = (id: string) => {
    appendChild(id);
  };

  const handleChangePage = (event: any, page: number) => {
    dispatch(
      fetchList({
        pagination: {
          pageIndex: page - 1,
        },
      })
    );
  };

  const UIcon = iconsMap.get(type);

  return (
    <>
      <TableWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{title}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>
                <SimpleInput value={search} onChange={onSearchChanged} />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.map((item) => (
                <TableRow key={item[idField]}>
                  <TableCell>
                    <ItemWrapper>
                      <Link to={linksMap.get(type)!(item[idField])}>
                        <Tooltip
                          title={
                            item.name ? `${item.name}` : `${item[idField]}`
                          }
                          placement="top"
                        >
                          <ItemWrapper withIcon="true">
                            <UIconWrapper>
                              <UIcon />
                            </UIconWrapper>
                            <TextLink table="true">{item[idField]}</TextLink>
                          </ItemWrapper>
                        </Tooltip>
                      </Link>
                    </ItemWrapper>
                  </TableCell>
                  <TableCell onClick={() => onAppendChild(item[idField])}>
                    <Tooltip title={labels.profile.addMember} placement="top">
                      <TooltipWrapper>
                        <Icon
                          arrow="true"
                          table="true"
                          icon={faPlus}
                          size="lg"
                        />
                      </TooltipWrapper>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <br />
      {pagination.totalPages > 0 && (
        <Pagination
          count={pagination.totalPages || 0}
          page={1}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default Candidates;
