import React from "react";
import Cluster from "./styled/Cluster";
import Point from "./styled/Point";

interface Props {
  lat: number;
  lng: number;
  cluster: any;
  onClusterClick: (cluster: any) => void;
}

const getClusterImg = (pointCount: number) => {
  if (pointCount < 10)
    return "https://raw.githubusercontent.com/mahnunchik/markerclustererplus/master/images/m1.png";
  if (pointCount < 100)
    return "https://raw.githubusercontent.com/mahnunchik/markerclustererplus/master/images/m2.png";
  return "https://raw.githubusercontent.com/mahnunchik/markerclustererplus/master/images/m3.png";
};

const Marker = ({ cluster, onClusterClick }: Props) => {
  const { cluster: isCluster, point_count: pointCount } = cluster.properties;

  if (isCluster) {
    return (
      <Cluster
        onClick={() => onClusterClick(cluster)}
        img={getClusterImg(pointCount)}
      >
        {pointCount}
      </Cluster>
    );
  }

  return <Point />;
};

export default Marker;
