import React from "react";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import { Field } from "react-final-form";
import Grid from "../../styled/Grid";
import Radio from "./styled/Radio";

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
  options: Option[];
}

const RadioButton = ({ name, options }: Props) => {
  return (
    <Field name={name} type={"string"}>
      {({ input }) => (
        <RadioGroup name={name} {...input}>
          <Grid container spacing={3} direction="row" alignItems="center">
            {options.map((option) => (
              <Grid item>
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      )}
    </Field>
  );
};

export default RadioButton;
