import styled, { css } from "styled-components";

interface Props {
  auth?: boolean;
  picker?: boolean;
}

const authMixin = css`
  && .MuiFormControl-root {
    .MuiIconButton-root {
      color: ${(props) => props.theme.color.white};
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid ${(props) => props.theme.color.white};
    }

    .MuiFormLabel-root {
      font-family: inherit;
      color: ${(props) => props.theme.color.white};
      &.Mui-focused {
        color: ${(props) => props.theme.color.white};
      }
    }

    .MuiInputBase-input {
      /* TODO: ts error for props.theme.color.white */
      color: ${(props) => props.theme.color.white};
    }
  }
`;

const StyledWrapper = styled.div<Props>`
  && .MuiFormControl-root {
    width: 100%;

    .MuiFormHelperText-root {
      font-family: inherit;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid ${(props) => props.theme.color.blue};
    }

    .MuiFormLabel-root {
      font-family: inherit;
      color: ${(props) => props.theme.color.darkgray};
      &.Mui-focused {
        color: ${(props) => props.theme.color.blue};
      }
    }
  }

  ${(props) => props.auth && authMixin};

  ${(props) =>
    props.picker &&
    `
    margin-left: 10px;
  `}
`;
export default StyledWrapper;
