import styled from "styled-components";

interface Props {
  // https://github.com/styled-components/styled-components/issues/1198#issuecomment-524024001
  brand?: boolean;
}

export const Wrapper = styled.div<Props>`
  margin-bottom: 20px;

  ${(props) =>
    props.brand &&
    `
    padding: 5px;
    width: 175px;
  `}

  @media (min-width: ${(props) => props.theme.breakpoint.mobile}) {
    ${(props) =>
      props.brand &&
      `
      height: 60px;
      margin-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 15px;
    `}
  }
`;

export default Wrapper;
