import React, {
  Dispatch,
  ReactFragment,
  SetStateAction,
  ReactElement,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Header from "../Header";

export interface Props {
  title: string;
  children: ReactFragment;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm?: () => void;
  onCancel?: () => void;
  renderConfirmButton?: (onAccept: () => void) => ReactElement;
}

const ConfirmationDialog = ({
  title,
  children,
  open,
  setOpen,
  onConfirm,
  onCancel,
  renderConfirmButton,
}: Props) => {
  const onAccept = () => {
    setOpen(false);
    onConfirm && onConfirm();
  };

  const onClose = () => {
    setOpen(false);
    onCancel && onCancel();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Header level={3} dialog={true}>
        {title}
      </Header>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onCancel && <Button onClick={onClose}>Cancel</Button>}
        <Button onClick={onAccept}>
          {renderConfirmButton ? renderConfirmButton(onAccept) : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
