import React, { useState } from "react";
import { useAppDispatch } from "../../store";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import { genderOptions } from "../Profile/constants";
import SelectCountry from "../../components/SelectCountry";
import Checkbox from "../../components/Checkbox";
import Button from "../Login/styled/Button";
import { Form } from "react-final-form";
import { email } from "../../utils/form";
import PasswordInput from "../../components/Form/PasswordInput";
import ReCAPTCHA from "react-google-recaptcha";
import config from "config";
import toast from "../../components/Toaster";
import { signUp } from "../../store/ducks/auth";
import { FORM_ERROR } from "final-form";
import history from "../../utils/history";
import Icon from "../../styled/Icon";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { ROUTES_PATHS } from "../../Routes";
import PurchaseView from "../Purchase/styled/PurchaseView";
import { signUpFields } from "./constants";
import Loader from "../../components/Loader";
import PurchaseLayout from "../Purchase/styled/Purchase";
import { useSelector } from "react-redux";
import { selectIsAuthLoading } from "../../store/ducks/auth/selectors";
import labels from "../../utils/labels";

export interface SignUpData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  gender: string;
  country: string;
  role?: number;
}

interface Props {
  subTitle?: string;
  description: React.ReactNode;
  role?: number;
}

const SignUp = ({ subTitle, description, role }: Props) => {
  const dispatch = useAppDispatch();

  const isAuthLoading = useSelector(selectIsAuthLoading);

  const [reCaptchaValue, setReCaptchaValue] = useState<string | null>(null);

  const [newsletter, setNewsletter] = React.useState(false);

  const onSubmit = (values: SignUpData) => {
    if (!reCaptchaValue) {
      return toast.error(
        "Are you a crawler? Let your consciousness validate that you are human :)"
      );
    }

    if (role) {
      values.role = role;
    }

    return new Promise((resolve, reject) => {
      dispatch(signUp(values, { resolve, reject }));
    })
      .then(() => {
        history.push(`/${ROUTES_PATHS.login}`);
      })
      .catch((error) => {
        return {
          [FORM_ERROR]: error,
        };
      });
  };

  return (
    <Loader isLoading={isAuthLoading} text={labels.auth.createAccount}>
      <PurchaseLayout>
        <PurchaseView>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <h2>{labels.auth.signUp}</h2>
            </Grid>
            {subTitle && (
              <Grid item>
                <h3>{subTitle}</h3>
              </Grid>
            )}
            <Grid item>{description}</Grid>
          </Grid>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Input {...signUpFields.firstname} />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Input {...signUpFields.lastname} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Input {...signUpFields.email} validation={email} />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <PasswordInput
                      {...signUpFields.password}
                      extendedValidation={true}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Select options={genderOptions} {...signUpFields.gender} />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SelectCountry {...signUpFields.country} />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item direction="column">
                    <ReCAPTCHA
                      sitekey={config.checkout!.recaptchaKey}
                      onChange={setReCaptchaValue}
                    />
                  </Grid>

                  <Grid item direction="column">
                    <Grid
                      container
                      item
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Checkbox
                        label={labels.auth.subscribeNews}
                        getValue={() => newsletter}
                        setValue={() => setNewsletter(!newsletter)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <br />
                <Grid
                  container
                  item
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Button blue="true" type="submit">
                      <Icon icon={faUserPlus} size="lg" />
                      {labels.button.signUp}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </PurchaseView>
      </PurchaseLayout>
    </Loader>
  );
};

export default SignUp;
