import styled from "styled-components";

const TableWrapper = styled.div`
  && .MuiTable-root {
    .MuiTableCell-head,
    .MuiTableCell-body {
      font-family: inherit;
    }

    .MuiTableCell-head {
      font-weight: 700;
    }

    .MuiTableBody-root {
      word-break: break-word;
    }

    .MuiTableHead-root .MuiTableCell-root {
      border-bottom: 2px solid ${(props) => props.theme.color.colorPicker};
    }

    .MuiTableCell-root {
      font-size: 14px;
      padding: 10px 10px;
    }

    .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) {
      background: ${(props) => props.theme.color.customFileInput};
    }
  }
`;

export default TableWrapper;
