import config from "config";

export enum ProductTypes {
  community = "community",
  stripe_neurofeedbackprovider = "stripe_neurofeedbackprovider",
  stripe_neurofeedbackuser = "stripe_neurofeedbackuser",
}

export enum Views {
  offer,
  signUp,
  login,
  payment,
  thanks,
  error,
}

export const startView: any = {
  start: Views.offer,
  signUp: Views.signUp,
  login: Views.error,
  payment: Views.payment,
  error: Views.error,
  thanks: Views.thanks,
};

export const stripeData: any = {
  key: config.checkout?.stripeApiKey,
  image:
    "https://s3.amazonaws.com/stripe-uploads/acct_16X2qzAPt70ZlPoymerchant-icon-1462947482244-stripe-logo.png",
  locale: "en",
  currency: "USD",
  panelLabel: "Pay Now (4/4)",
  label: "Pay with Card",
  stripe_neurofeedbackprovider: {
    name: "Neurofeedback Provider",
    description: "$1500 (USD) / year",
  },
  stripe_neurofeedbackuser: {
    name: "Neurofeedback User",
    description: "$15 (USD) / year",
  },
};
