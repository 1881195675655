export interface Rule {
  crud: boolean[];
  name: RULES;
  isRole: boolean;
  options: string;
  priority: number;
}

export interface State {
  data: {
    current: Rule | null;
  };
  loading: {
    current: boolean;
  };
  error: {
    current: string | null;
  };
}

export enum RULES {
  FRONTEND_SectionFilesystem = "FRONTEND_SectionFilesystem",
  FRONTEND_SectionShowDownload = "FRONTEND_SectionShowDownload",
  ROLE_BiofeedbackProvider = "ROLE_BiofeedbackProvider",
  FRONTEND_SectionCreateCompany = "FRONTEND_SectionCreateCompany",
  FRONTEND_SectionCreateGroup = "FRONTEND_SectionCreateGroup",
  FRONTEND_SectionCreateUser = "FRONTEND_SectionCreateUser",
  FRONTEND_SectionItemEditor = "FRONTEND_SectionItemEditor",
  FRONTEND_SectionShowCompanies = "FRONTEND_SectionShowCompanies",
  REST_deleteCompany = "REST_deleteCompany",
  REST_getCompany = "REST_getCompany",
  REST_createCompany = "REST_createCompany",
  FRONTEND_SectionSession = "FRONTEND_SectionSession",
  REST_deleteDataChunk = "REST_deleteDataChunk",
  REST_getDataChunk = "REST_getDataChunk",
  ROLE_Community = "ROLE_Community",
  ROLE_Professional = "ROLE_Professional",
  FRONTEND_SectionShowGroups = "FRONTEND_SectionShowGroups",
  REST_deleteGroup = "REST_deleteGroup",
  REST_getGroup = "REST_getGroup",
  REST_createGroup = "REST_createGroup",
  FRONTEND_SectionShowUsers = "FRONTEND_SectionShowUsers",
  REST_deleteUser = "REST_deleteUser",
  REST_getUser = "REST_getUser",
  FRONTEND_SectionEvents = "FRONTEND_SectionEvents",
  REST_findEvents = "REST_findEvents",
  FRONTEND_SectionLogs = "FRONTEND_SectionLogs",
  FRONTEND_SectionShowCompany = "FRONTEND_SectionShowCompany",
  REST_updateCompany = "REST_updateCompany",
  FRONTEND_SectionShowGroup = "FRONTEND_SectionShowGroup",
  REST_updateGroup = "REST_updateGroup",
  FRONTEND_SectionShowUser = "FRONTEND_SectionShowUser",
  REST_updateUser = "REST_updateUser",
  FRONTEND_SectionDownload = "FRONTEND_SectionDownload",
  FRONTEND_SectionCustomer = "FRONTEND_SectionCustomer",
  FRONTEND_SectionCompanies = "FRONTEND_SectionCompanies",
  FRONTEND_SectionGroups = "FRONTEND_SectionGroups",
  FRONTEND_SectionUsers = "FRONTEND_SectionUsers",
  FRONTEND_SectionMonitoring = "FRONTEND_SectionMonitoring",
  FRONTEND_SectionSwagger = "FRONTEND_SectionSwagger",
  FRONTEND_SectionDoc = "FRONTEND_SectionDoc",
  FRONTEND_SectionForum = "FRONTEND_SectionForum",
  REST_createUser = "REST_createUser",
  ROLE_Admin = "ROLE_Admin",
  FRONTEND_JoinRequest = "FRONTEND_JoinRequest",
  REST_findLogs = "REST_findLogs",
}
