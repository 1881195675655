import React, {
  createRef,
  Ref,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { Field } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "./styled/StyledWrapper";
import CustomFileInput from "../../styled/CustomFileInput";
import Dropzone from "react-dropzone";
import ImageWrapper from "../../pages/Filesystem/styled/Common/ImageWrapper";
import Icon from "../../styled/Icon";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TextLink from "../../styled/TextLink";

interface Props {
  name: string;
  required?: boolean;
  auth?: boolean;
  initialValue?: any;
  customCallback: Dispatch<SetStateAction<File | undefined>>;
}

const FileInput = ({
  name,
  required,
  auth,
  initialValue,
  customCallback,
}: Props) => {
  const inputElement: Ref<HTMLInputElement> = createRef();
  const handleClick = () => {
    inputElement!.current!.click();
  };

  const [inputValue, updateInputValue] = useState(initialValue);

  const handleFile = (files: FileList | null) => {
    if (!files) {
      return;
    }

    getBase64(files[0], (result: any) => {
      updateInputValue(result);
      customCallback && customCallback(files[0]);
    });
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      getBase64(acceptedFiles[0], (result: any) => {
        updateInputValue(result);
        customCallback && customCallback(acceptedFiles[0]);
      });
    },
    [customCallback]
  );

  const removeImage = () => {
    updateInputValue(undefined);
    customCallback && customCallback(undefined);
  };

  return (
    <Field
      name={name}
      validate={(value: string) =>
        required ? checkRequired(value) : undefined
      }
    >
      {({ input: { value, onChange, ...input }, meta }) => {
        const handleChange = ({
          target,
        }: React.ChangeEvent<HTMLInputElement>) => {
          onChange(target.files);
          handleFile(target.files);
        };
        return (
          <StyledWrapper auth={auth}>
            <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
              {({ getRootProps }) => (
                <div {...getRootProps()}>
                  <FormControl>
                    <CustomFileInput onClick={handleClick}>
                      <input
                        {...input}
                        type="file"
                        required={required}
                        style={{ display: "none" }}
                        onChange={handleChange}
                        ref={inputElement}
                      />
                    </CustomFileInput>
                    <FormHelperText error>
                      {meta.touched && meta.error ? meta.error : " "}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
            </Dropzone>

            {inputValue && (
              <ImageWrapper>
                <img src={inputValue} />
                <TextLink>
                  <Icon icon={faTrash} size="lg" onClick={removeImage} />
                </TextLink>
              </ImageWrapper>
            )}
          </StyledWrapper>
        );
      }}
    </Field>
  );
};

export default FileInput;
