import React from "react";

export const PerformStudioLabel = (
  <>
    <b>eego</b>™️ perform studio
  </>
);

export const PerformLabel = (
  <>
    <b>eego</b>™️ perform
  </>
);

export const MiniLabel = (
  <>
    <b>eego</b>™️ mini
  </>
);

export const PerformConverterLabel = (
  <>
    <b>eego</b>™️ perform EDF+ converter
  </>
);

export const WaveguardLabel = (
  <>
    <b>waveguard</b>™️ cap
  </>
);
