import React from "react";
import Icon from "../../../styled/Icon";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadReport, downloadReportAndRAWChannelsData } from "../helpers";
import labels from "../../../utils/labels";
import Button from "../../../styled/Button";
import config from "config";
import { SessionDetails } from "../../../store/ducks/sessions/types";

type Props = {
  session: SessionDetails | null;
};

export function ReportsButtons({ session }: Props) {
  return (
    <section>
      {!config.sessions?.hideSingleFileDownloadButtons && (
        <Button
          withoutOffset
          blue="true"
          type="button"
          onClick={() => downloadReport(session)}
        >
          <Icon icon={faDownload} size="lg" />
          {labels.sessions.downloadReport}
        </Button>
      )}
      <Button
        withoutOffset={!!config.sessions?.hideSingleFileDownloadButtons}
        blue="true"
        type="button"
        onClick={() => downloadReportAndRAWChannelsData(session)}
      >
        <Icon icon={faDownload} size="lg" />
        {labels.sessions.downloadReportAndRAWChannelsData}
      </Button>
    </section>
  );
}
