import styled from "styled-components";

const PurchaseLayout = styled.div`
  background-color: ${(props) => props.theme.color.purchase};
  min-height: 100vh;
  flex: 1;
  border: none;
  width: 100%;
  padding-top: 2%;
`;

export default PurchaseLayout;

export const Price = styled.p`
  font-size: 24px;
`;
