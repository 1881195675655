import styled, { css } from "styled-components";

interface Props {
  dialog?: true;
}

const dialogMixin = css`
  padding: 16px 24px;
  margin-bottom: 0;
`;

const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1,
  h2,
  h3,
  svg {
    color: ${(props) => props.theme.color.blue};
    margin: 0;
  }

  h1,
  h2,
  h3 {
    font-weight: 500;
  }

  svg {
    font-size: 40px;
    margin-right: 5px;
  }

  ${(props) => props.dialog && dialogMixin};
`;

export default Wrapper;
