import styled from "styled-components";

const EditorWrapper = styled.div`
  width: 100vh;
  padding: 6px 12px;
  font-size: 14px;
  color: ${(props) => props.theme.color.editorbackground};
  border: 1px solid ${(props) => props.theme.color.editorborder};
  border-radius: 4px;
`;

export default EditorWrapper;
