import React from "react";
import SignUp from "./SignUp";

const SignUpProvider = () => {
  return (
    <SignUp
      subTitle="as neuromore provider"
      description={
        <p>
          neuromore Pro is the gateway to incorporating neurofeedback training
          into your professional services. As a provider of neurofeedback
          training, this software allows you to control all aspects of your
          clients’ experience from start to finish. You will have the ability to
          determine the neurofeedback protocols, to conduct neurofeedback
          sessions, and to track clients’ progress. Additionally, neuromore Pro
          allows all providers to give access to and setup user training
          experiences for clients. Although the training experience is designed
          to be intuitive to users, providers are ultimately responsible for
          answering clients’ questions about the purpose or effects of the
          training, addressing any concerns, and adjusting training parameters
          based on their professional opinion.
        </p>
      }
      role={305}
    />
  );
};

export default SignUpProvider;
