import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import {
  selectList as selectCompanyList,
  selectIsListLoading as selectIsCompanyListLoading,
} from "../../store/ducks/companies/selectors";
import {
  selectList as selectGroupList,
  selectIsListLoading as selectIsGroupListLoading,
} from "../../store/ducks/groups/selectors";
import {
  selectList as selectUserList,
  selectIsListLoading as selectIsUserListLoading,
} from "../../store/ducks/users/selectors";
import { DefaultPaginationParams } from "../../types/children";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { fetchList as fetchCompanyList } from "../../store/ducks/companies";
import { fetchList as fetchGroupList } from "../../store/ducks/groups";
import { fetchList as fetchUserList } from "../../store/ducks/users";
import { useDebouncedCallback } from "use-debounce";
import { Field } from "react-final-form";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "../Form/styled/StyledWrapper";
import FormHelperText from "@material-ui/core/FormHelperText";
import labels from "../../utils/labels";

enum ParentType {
  None,
  User,
  Company,
  Group,
}

const types = [
  { label: "None", value: ParentType.None },
  { label: "User", value: ParentType.User },
  { label: "Company", value: ParentType.Company },
  { label: "Group", value: ParentType.Group },
];

interface Props {
  name: string;
  setParentId: Dispatch<SetStateAction<string | null>>;
  required?: boolean;
  disabled?: boolean;
}

const Parent = ({ name, setParentId, required, disabled }: Props) => {
  const dispatch = useAppDispatch();

  const [options, setOptions] = React.useState<string[]>([]);

  const [parentType, setParentType] = React.useState<ParentType>(
    ParentType.None
  );

  const [option, setOption] = useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  const userList = useSelector(selectUserList);
  const groupList = useSelector(selectGroupList);
  const companyList = useSelector(selectCompanyList);

  const isUserListLoading = useSelector(selectIsUserListLoading);
  const isGroupListLoading = useSelector(selectIsGroupListLoading);
  const isCompanyListLoading = useSelector(selectIsCompanyListLoading);

  const isListLoading =
    isUserListLoading || isGroupListLoading || isCompanyListLoading;

  useEffect(() => {
    if (!isListLoading) {
      if (parentType === ParentType.User) {
        setOptions(userList ? userList.map((item) => item.userId) : []);
      } else if (parentType === ParentType.Group) {
        setOptions(groupList ? groupList.map((item) => item.groupId) : []);
      } else if (parentType === ParentType.Company) {
        setOptions(
          companyList ? companyList.map((item) => item.companyId) : []
        );
      }
    }
  }, [parentType, userList, groupList, companyList, isListLoading]);

  const handleChangeParentType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setParentType(event.target.value as ParentType);
  };

  const onOptionChanged = (value: string | null) => {
    setOption(value);
    setParentId(value);
  };

  const [debouncedHandleChangeSearch] = useDebouncedCallback(
    (value: string) => {
      if (!value) {
        return;
      }

      if (parentType === ParentType.None) {
        return;
      }

      const payload = {
        pagination: DefaultPaginationParams,
        filtering: {
          idFilter: value,
        },
      };

      if (parentType === ParentType.User) {
        dispatch(fetchUserList(payload));
      } else if (parentType === ParentType.Group) {
        dispatch(fetchGroupList(payload));
      } else if (parentType === ParentType.Company) {
        dispatch(fetchCompanyList(payload));
      }
    },
    1000
  );

  const onInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
    debouncedHandleChangeSearch(value);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3} lg={2}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel>{labels.profile.type}</InputLabel>
            <Select value={parentType} onChange={handleChangeParentType}>
              {types &&
                types.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <Field
            name={name}
            validate={(value: string) =>
              required ? checkRequired(value) : undefined
            }
          >
            {({ input, meta }) => (
              <StyledWrapper>
                <FormControl>
                  <Autocomplete
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    filterOptions={(x) => x}
                    options={options || []}
                    value={option}
                    onChange={(_, v) => {
                      input.onChange(v);
                      onOptionChanged(v);
                    }}
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...input}
                        {...params}
                        label={labels.profile.parentId}
                        required={required}
                        disabled={disabled}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isListLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <FormHelperText error>
                    {meta.touched && meta.error ? meta.error : " "}
                  </FormHelperText>
                </FormControl>
              </StyledWrapper>
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};

export default Parent;
