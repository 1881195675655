import styled from "styled-components";

const ReportLayout = styled.article`
  max-width: 800px;
  margin: 50px auto;
  background-color: ${(props) => props.theme.color.white};
  padding: 50px;
  position: relative;
  min-height: 100vh;
  flex: 1 1 90%;
`;

export const NeedUpgrade = styled.p`
  font-size: 24px;
`;

export const LineGraphLabel = styled.div`
  margin: 10px;
  text-align: center;
  font-family: inherit;
`;

export default ReportLayout;
