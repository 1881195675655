import styled from "styled-components";

interface Props {
  button?: "true";
}

const HeaderWrapper = styled.main<Props>`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.button &&
    `
    justify-content: flex-end;
    
  `}
`;

export default HeaderWrapper;
