import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Company } from "store/ducks/companies/types";
import { selectCurrent as selectRule } from "store/ducks/rule/selectors";
import { email, FieldSetting, prepareFields } from "utils/form";
import labels from "utils/labels";
import Grid from "@material-ui/core/Grid";
import Input from "components/Form/Input";
import Button from "../Login/styled/Button";
import SaveIcon from "@material-ui/icons/Save";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { Form } from "react-final-form";
import Parent from "components/Parent";
import { FORM_ERROR } from "final-form";
import { useAppDispatch } from "store";
import { create } from "store/ducks/companies";
import Header from "components/Header";
import SelectCountry from "components/SelectCountry";
import PersonIcon from "@material-ui/icons/Person";

export interface CreateCompanyData extends Company {
  appendParentIds: string[];
}

const CreateCompany = () => {
  const dispatch = useAppDispatch();

  // rules contains validation conditions
  const rule = useSelector(selectRule)!; // rule must be already filled up because it is AuthenticatedRoute

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      company: {},
      street: {},
      zip: {},
      city: {},
      state: {},
      country: {},
      phone: {},
      fax: {},
      website: {},
      firstname: {},
      lastname: {},
      email: {},
      appendParentIds: {},
    },
    rule,
    labels.company
  );

  const onSubmit = (values: Company) => {
    const data = {
      ...values,
      appendParentIds: parent ? [parent] : [],
    };

    return new Promise((resolve, reject) => {
      dispatch(create(data, { resolve, reject }));
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  const [parent, setParent] = useState<string | null>(null);

  return (
    <>
      <Header level={2} icon={<BusinessCenterIcon />}>
        {labels.company.create}
      </Header>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.company} />
              </Grid>
            </Grid>

            <Parent {...fields.appendParentIds} setParentId={setParent} />

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.street} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.city} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.zip} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.state} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <SelectCountry {...fields.country} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.phone} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.fax} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.website} />
              </Grid>
            </Grid>

            <Header level={3} icon={<PersonIcon />}>
              Contact Person
            </Header>

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.firstname} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.lastname} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.email} validation={email} />
              </Grid>
            </Grid>

            <br />
            {
              <Button start="true" blue="true" type="submit">
                <SaveIcon />
                {labels.button.create}
              </Button>
            }
          </form>
        )}
      />
    </>
  );
};

export default CreateCompany;
