import config from "config";
import { Role } from "types";
import { rolesMap } from "./constants";

export function mapRoleStringRepresentationToID(role?: string) {
  if (!role || !stringIsPartOfRoles(role)) return;

  return rolesMap[role];
}

function stringIsPartOfRoles(input: string): input is Role {
  return config.userRoles.includes(input as Role);
}
