import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectCurrent } from "../../store/ducks/products/selectors";
import { useAppDispatch } from "../../store";
import Checkbox from "../../components/Checkbox";
import Button from "../Login/styled/Button";
import ProductDetails from "./ProductDetails";
import { Link } from "react-router-dom";
import Icon from "../../styled/Icon";
import { faCheckSquare, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Views } from "./constants";
import PurchaseView from "./styled/PurchaseView";
import { fetchCurrent } from "../../store/ducks/products";

interface Props {
  view: Views;
  productId: string;
  setForwardTo: Dispatch<SetStateAction<Views | undefined>>;
  openSignUp: () => void;
  openLogin: () => void;
}

const Offer = ({
  view,
  productId,
  openSignUp,
  openLogin,
  setForwardTo,
}: Props) => {
  const dispatch = useAppDispatch();

  const product = useSelector(selectCurrent);

  useEffect(() => {
    if (view === Views.offer) {
      dispatch(fetchCurrent(productId));
    }
  }, [dispatch, view, productId]);

  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const getAcceptTerms = () => acceptTerms;

  const join = () => {
    if (product!.paymentType === "none") {
      setForwardTo(Views.thanks);
    } else {
      setForwardTo(Views.login);
    }
    openSignUp();
  };

  const singIn = () => {
    openLogin();
  };

  return (
    <PurchaseView>
      {product && (
        <Grid container direction="column" justify="center" alignItems="center">
          <ProductDetails product={product} />
          <br />
          <Checkbox
            label={
              <span>
                I have read and agree to the{" "}
                <Link
                  to={{ pathname: "http://www.neuromore.com/legal/" }}
                  target="_blank"
                >
                  Terms & Conditions
                </Link>
              </span>
            }
            getValue={getAcceptTerms}
            setValue={setAcceptTerms}
          />
          <br />
          {productId === "community" && (
            <h3>Create a new neuromore account?</h3>
          )}
          {productId !== "community" && <h3>Already have an account?</h3>}
          {productId === "community" && (
            <Grid item>
              <Button
                blue="true"
                type="button"
                disabled={!acceptTerms}
                onClick={join}
              >
                <Icon icon={faCheckSquare} size="lg" />
                Yes, join the circle
              </Button>
            </Grid>
          )}
          {productId !== "community" && (
            <Grid
              container
              direction="row"
              spacing={2}
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  blue="true"
                  type="button"
                  disabled={!acceptTerms}
                  onClick={singIn}
                >
                  <Icon icon={faCheckSquare} size="lg" />
                  Yes, let me log in
                </Button>
              </Grid>
              <Grid item>
                <Button
                  blue="true"
                  type="button"
                  disabled={!acceptTerms}
                  onClick={join}
                >
                  <Icon icon={faUserPlus} size="lg" />
                  Create account first
                </Button>
              </Grid>
            </Grid>
          )}
          <br />
          <br />
          {productId !== "community" && <p>Step: 1/4</p>}
        </Grid>
      )}
    </PurchaseView>
  );
};

export default Offer;
