import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, Product } from "./types";
import { PaymentData } from "../../../pages/Purchase/Payment";
import { FormSubmissionMeta } from "../../../types";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    current: null,
  },
  loading: {
    current: false,
    purchase: false,
  },
  error: {
    current: null,
    purchase: null,
  },
};

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    fetchCurrent: (state, _: PayloadAction<string>) => {
      state.data.current = null;
      state.loading.current = true;
      state.error.current = null;
    },
    fetchCurrentSuccess: (state, action: PayloadAction<Product>) => {
      state.data.current = action.payload;
      state.loading.current = false;
    },
    fetchCurrentError: (state, action: PayloadAction<RequestError>) => {
      state.error.current = action.payload.message;
      state.loading.current = false;
    },
    purchase: {
      reducer: (state, _: PayloadAction<PaymentData>) => {
        state.loading.purchase = true;
        state.error.purchase = null;
      },
      prepare: (payload: PaymentData, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    purchaseSuccess: {
      reducer: (state, action: PayloadAction<{}>) => {
        state.loading.purchase = false;
      },
      prepare: (payload: {}, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    purchaseError: {
      reducer: (state, action: PayloadAction<RequestError>) => {
        state.error.purchase = action.payload.message;
        state.loading.purchase = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
  },
});

export const {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,

  purchase,
  purchaseSuccess,
  purchaseError,
} = slice.actions;

export default slice.reducer;
