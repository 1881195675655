import styled from "styled-components";
import { FormGroup as MatFormGroup } from "@material-ui/core";

const FormGroup = styled(MatFormGroup)`
  && {
    flex-direction: row;
    flex-wrap: initial;
  }
`;

export default FormGroup;
