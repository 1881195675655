import config from "config";
import { Tag, TagType, ImageCategory } from "../../store/ducks/files/types";
import { durationSliderOptions } from "./constants";

export const getIconUrl = (type: string, id: string) => {
  return getUrl(type, id, "icons");
};

export const getBackgroundUrl = (type: string, id: string) => {
  return getUrl(type, id, "backgrounds");
};

export const getUrl = (type: string, id: string, path: ImageCategory) => {
  if (!config.filesystem) {
    throw new Error("filesystem config is not provided.");
  }

  return `${config.filesystem.aws.endpoint}/${
    config.filesystem.aws[path]
  }/${id}.${type.split("/")[1]}`;
};

export const customTags = (tags: Tag[]) =>
  tags.filter(
    (tag) => tag.type !== TagType.DEVICE && tag.type !== TagType.DURATION
  );

export const getDurationTag = (duration: number) => {
  return { type: TagType.DURATION, value: getDurationTagValue(duration) };
};

export const getDurationTagValue = (duration: number) => {
  if (duration <= 2) {
    return "0-2";
  }
  if (duration <= 5) {
    return "2-5";
  }
  if (duration <= 10) {
    return "5-10";
  }
  if (duration <= 30) {
    return "10-30";
  }
  if (duration <= 60) {
    return "30-60";
  }
  return "60-1440";
};

export const durationValueLabel = (value: number) =>
  durationSliderOptions.find((option) => option.value === value)?.label;
