import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  gray?: "true";
  blue?: "true";
  red?: "true";

  arrow?: "true";
  section?: "true";
  table?: "true";
  default?: "true";
}

const Icon = styled(FontAwesomeIcon)<Props>`
  padding-right: 7px;
  font-weight: 400;

  ${(props) =>
    props.arrow &&
    `
    margin-left: auto;
  `}

  ${(props) =>
    props.gray &&
    `
    color: ${props.theme.color.gray}!important;
    :hover {
      color: ${props.theme.color.white}!important;
    }
  `}

  ${(props) =>
    props.blue &&
    `
    color: ${props.theme.color.blue}!important;
  `}

  ${(props) =>
    props.section &&
    `
    padding-right: 0px;
    padding-left: 7px;
  `}

  ${(props) =>
    props.table &&
    `
    padding-right: 0px;
    color: ${props.theme.color.lightgreen};
    cursor: pointer;
  `}

  ${(props) =>
    props.default &&
    `
    padding-right: 0px;
  `}

  ${(props) =>
    props.red &&
    `
    color: ${props.theme.color.red};
  `}
`;

export default Icon;
