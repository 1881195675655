import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES_PATHS } from "../../Routes";
import { selectUser } from "../../store/ducks/auth/selectors";

const NotFound = () => {
  const location = useLocation();
  const user = useSelector(selectUser);

  const redirections: any[] = [
    {
      from: `#/${ROUTES_PATHS.resetPassword}`,
      to: ROUTES_PATHS.resetPassword,
    },
    {
      from: `#/${ROUTES_PATHS.purchase}`,
      to: ROUTES_PATHS.purchase,
    },
    {
      from: `#/${ROUTES_PATHS.createprovider}`,
      to: ROUTES_PATHS.createprovider,
    },
    {
      from: `#/${ROUTES_PATHS.getaccess}`,
      to: ROUTES_PATHS.getaccess,
    },
    {
      from: `#/${ROUTES_PATHS.report}`,
      to: ROUTES_PATHS.report,
    },
  ];

  if (location.pathname === "/") {
    const address = redirections.find((address) =>
      location.hash.startsWith(address.from)
    );
    if (address) {
      localStorage.removeItem("token");
      const qs = location.hash.replace(address.from, "");
      return <Redirect to={`/${address.to}${qs}`} />;
    }
  }

  if (user) {
    return <Redirect to={`/${ROUTES_PATHS.home}`} />;
  }

  return <Redirect to={`/${ROUTES_PATHS.login}`} />;
};

export default NotFound;
