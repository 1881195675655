import styled from "styled-components";

const MainContent = styled.div`
  background-color: ${(props) => props.theme.color.white};
  flex: 1;
  padding: 30px;
  border: none;
  width: 100%;
  overflow: auto;
  border-left: 3px solid ${(props) => props.theme.color.blue};
  min-height: 100vh;
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    margin-top: 50px;
  }
`;

export default MainContent;
