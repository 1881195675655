import styled, { css } from "styled-components";
import { Button as MatButton } from "@material-ui/core/";

interface Props {
  primary?: boolean;
  default?: boolean;
  reversed?: boolean;

  // https://github.com/styled-components/styled-components/issues/1198#issuecomment-524024001
  blue?: "true";
  gray?: boolean;
  stripped?: boolean;
  section?: boolean;
  subsection?: boolean;
  title?: "true";
  small?: "true";
  end?: "true";
  start?: "true";
  active?: "true";
  withoutOffset?: boolean;
}

const Button = styled(MatButton)<Props>`
  && {
    overflow: initial;
    font-size: inherit;
    letter-spacing: inherit;
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 0;
    font-size: 18px;
    min-width: 145px;
    padding: 10px 25px;
    margin: 6px 8px;
    text-transform: none;
    color: ${(props) => props.theme.color.white};
    white-space: nowrap;
    font-family: inherit;

    :hover {
      background-color: transparent;
      color: ${(props) => props.theme.color.blue};
    }

    ${(props) =>
      props.active &&
      `
      background-color: ${props.theme.color.blue};
    `}

    ${(props) =>
      props.primary &&
      `
      color: ${props.theme.color.white};
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 3px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 1.5px;
      padding: 10px 20px;
      margin: 2px;
  
      :hover {
        color:${props.theme.color.white};
        background-color: ${props.theme.color.blue};
        border: 1px solid ${props.theme.color.blue};
      }
    `}
  
    ${(props) =>
      props.reversed &&
      `
      color: ${props.theme.color.white};
      background-color: ${props.theme.color.blue};
      border: 1px solid ${props.theme.color.blue};
      border-radius: 3px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 1.5px;
      padding: 10px 20px;
      margin: 2px;
  
      &:hover {
        color: ${props.theme.color.white};
        background-color: transparent;
        border: 1px solid ${props.theme.color.blue};
      }
  `}
    ${(props) =>
      props.default &&
      `
      color: ${props.theme.color.darkgray};
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid ${props.theme.color.darkgray};
      border-radius: 3px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 1.5px;
      padding: 10px 20px;
      margin: 2px;
  
      :hover {
        color: rgb(255, 255, 255);
        background-color: ${props.theme.color.darkgray};
      }
    `}


    ${(props) =>
      props.blue &&
      `
      background-color: ${props.theme.color.blue};
      border-top: 2px solid ${props.theme.color.blue};
      border-bottom: 2px solid ${props.theme.color.blue};

      :hover {
        background-color: transparent;
      }
      
      :disabled {
        background-color: ${props.theme.color.gray};
        border-top: 2px solid ${props.theme.color.subsectiongray};
        border-bottom: 2px solid ${props.theme.color.subsectiongray};
      }
    `}

    ${(props) =>
      props.gray &&
      `
      background-color: ${props.theme.color.gray};
      border-top: 2px solid ${props.theme.color.gray};
      border-bottom: 2px solid ${props.theme.color.gray};
      :hover, :focus, :active {
        background-color: transparent;
        color: ${props.theme.color.gray}
      }
    `}

    ${(props) =>
      props.section &&
      `
      padding: 15px;
      line-height: 20px;
      text-align: left;
      color: ${props.theme.color.lightgray};
      width: 100%;
      margin: 0px;
      border-top: 1px solid rgba(255,255,255, 0.1);
      border-bottom: 1px solid transparent;
      text-align: left;

      .MuiButton-label {
        justify-content: initial;
      }

      :hover {
        color: ${props.theme.color.white};
        background-color: ${props.theme.color.blue};
      }
    `}

    ${(props) =>
      props.subsection &&
      `
      padding-left: 36px;
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 20px;
      text-align: left;
      width: 100%;
      margin: 0;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      .MuiButton-label {
        justify-content: initial;
      }

      :hover {
        color: ${props.theme.color.white};
        background-color: ${props.theme.color.blue};
      }
    `}


    ${(props) =>
      props.stripped &&
      `
      width: 230px;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      :hover {
        color: ${props.theme.color.blue};
        padding: 15px;
        border-top: 2px solid ${props.theme.color.blue};
        border-bottom: 2px solid ${props.theme.color.blue};
        text-shadow: none;
      }

    `}

    ${(props) =>
      props.title &&
      `
      float: right;
    `}

    ${(props) =>
      props.small &&
      `
      padding: 0px 10px;
      min-width:auto;
    `}

    ${(props) =>
      props.start &&
      `
      float: left;
    `}

    ${(props) =>
      props.withoutOffset &&
      css`
        margin: 0;
      `}
    
  }
  
`;

export default Button;
