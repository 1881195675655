import styled from "styled-components";
import { Slider as MatSlider } from "@material-ui/core/";

const Slider = styled(MatSlider)`
  && {
    color: ${(props) => props.theme.color.blue};
    width: 200px;
    border-radius: 2px;
    padding-right: 18px;
    height: 55px;
    display: flex;
    align-items: center;

    .MuiSlider-thumb {
      margin-top: 2.2px;
    }
  }
`;

export default Slider;
