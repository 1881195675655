import React from "react";
import Wrapper from "./styled/Wrapper";
import config from "config";

const Logo = () => (
  <Wrapper>
    <img src={config.logo} alt={config.branding.logo.alt} />
  </Wrapper>
);

export default Logo;
