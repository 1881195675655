import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all, select } from "redux-saga/effects";
import {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,
  fetchList,
  fetchListSuccess,
  fetchListError,
  fetchChildren,
  fetchChildrenSuccess,
  fetchChildrenError,
  create,
  createSuccess,
  createError,
  update,
  updateSuccess,
  updateError,
  delete_,
  deleteSuccess,
  deleteError,
} from ".";
import { FormSubmissionMeta } from "../../../types";
import { CreateCompanyData } from "../../../pages/CreateCompany";
import { UpdateCompanyData } from "../../../pages/UpdateCompany";
import { Company, FilteringParams } from "./types";
import {
  FilteringParams as ChildrenFilteringParams,
  fromArray,
} from "../../../types/children";
import { DELETE, GET, POST, PUT, Response } from "../../../utils/request";
import { Pagination, mapPaginationFields } from "../../../types/pagination";
import {
  selectListFiltering,
  selectListPagination,
  selectChildrenFiltering,
  selectChildrenPagination,
} from "./selectors";
import toast from "../../../components/Toaster";
import { ROUTES_PATHS } from "../../../Routes";
import history from "../../../utils/history";

const path = `/api/companies`;
const api = {
  single: (companyId: string) => `${path}/${companyId}/get`,
  list: () => `${path}/list`,
  children: (companyId: string) => `${path}/${companyId}/children/get`,
  create: (companyId: string) => `${path}/${companyId}/create`,
  update: (companyId: string) => `${path}/${companyId}/update`,
  delete: (companyId: string) => `${path}/${companyId}/delete`,
};

function* fetchCurrentSaga(action: PayloadAction<string>) {
  try {
    const response: Response<{ company: Company }> = yield GET(
      api.single(action.payload)
    );

    yield put(fetchCurrentSuccess(response.data.company));
  } catch (error) {
    yield put(fetchCurrentError(error));
    toast.error(error.message);
  }
}

function* fetchListSaga(
  action: PayloadAction<{
    pagination: Pagination;
    filtering: FilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectListPagination);
    const oldFiltering = yield select(selectListFiltering);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{ companies: Company[] }> = yield GET(api.list(), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
    });

    yield put(
      fetchListSuccess({
        data: response.data.companies,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchListError(error));
    toast.error(error.message);
  }
}

function* fetchChildrenSaga(
  action: PayloadAction<{
    id: string;
    pagination: Pagination;
    filtering: ChildrenFilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectChildrenPagination);
    const oldFiltering = yield select(selectChildrenFiltering);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{
      children: Array<Array<string | null>>;
    }> = yield GET(api.children(action.payload.id), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
    });

    yield put(
      fetchChildrenSuccess({
        data: response.data.children.map((children) => fromArray(children)),
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchChildrenError(error));
    toast.error(error.message);
  }
}

function* createSaga(
  action: PayloadAction<CreateCompanyData, string, FormSubmissionMeta>
) {
  try {
    const response: Response<{ company: Company }> = yield POST(
      api.create(action.payload.companyId),
      action.payload
    );

    yield put(createSuccess(response.data.company, action.meta));
    toast.success("Company created.");

    history.push(`/${ROUTES_PATHS.company}/${response.data.company.companyId}`);
  } catch (error) {
    yield put(createError(error, action.meta));
    toast.error(error.message);
  }
}

function* updateSaga(
  action: PayloadAction<UpdateCompanyData, string, FormSubmissionMeta>
) {
  try {
    yield PUT(api.update(action.payload.companyId), action.payload);

    const response: Response<{ company: Company }> = yield GET(
      api.single(action.payload.companyId)
    );

    yield put(updateSuccess(response.data.company, action.meta));
    toast.success("Company updated.");

    yield put(fetchCurrent(action.payload.companyId));
    yield put(
      fetchChildren({
        id: action.payload.companyId,
      })
    );
  } catch (error) {
    yield put(updateError(error, action.meta));
    toast.error(error.message);
  }
}

function* deleteSaga(
  action: PayloadAction<string, string, FormSubmissionMeta>
) {
  try {
    yield DELETE(api.delete(action.payload));

    yield put(deleteSuccess());
    toast.success("Company deleted.");
    yield put(fetchList({}));
  } catch (error) {
    yield put(deleteError(error));
    toast.error(error.message);
  }
}

function* fetchCurrentWatcher() {
  yield takeLatest(fetchCurrent.type, fetchCurrentSaga);
}

function* fetchListWatcher() {
  yield takeLatest(fetchList.type, fetchListSaga);
}

function* fetchChildrenWatcher() {
  yield takeLatest(fetchChildren.type, fetchChildrenSaga);
}

function* createWatcher() {
  yield takeLatest(create.type, createSaga);
}

function* updateWatcher() {
  yield takeLatest(update.type, updateSaga);
}

function* deleteWatcher() {
  yield takeLatest(delete_.type, deleteSaga);
}

export function* rootWatcher() {
  yield all([
    fork(fetchCurrentWatcher),
    fork(fetchListWatcher),
    fork(fetchChildrenWatcher),
    fork(createWatcher),
    fork(updateWatcher),
    fork(deleteWatcher),
  ]);
}

export default rootWatcher;
