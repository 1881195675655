import styled from "styled-components";

const TableWrapper = styled.div`
  && .MuiTable-root {
    .MuiTableCell-head,
    .MuiTableCell-body {
      font-family: inherit;
    }

    .MuiTableCell-head {
      font-weight: 700;
    }

    .MuiTableCell-body {
      font-weight: 500;
    }

    .MuiTableBody-root {
      font-size: 16px
      word-break: break-word;
    }

    .MuiTableCell-root {
      border-bottom: none; 
    }
  
  }
`;

export default TableWrapper;
