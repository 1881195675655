import styled from "styled-components";

interface Props {
  black?: "true";
  table?: "true";
}

const TextLink = styled.div<Props>`
  min-height: inherit;
  line-height: normal;
  border: none;
  text-transform: none;
  text-decoration: none;
  color: ${(props) => props.theme.color.blue};

  :hover {
    text-decoration: none;
    cursor: pointer;
  }

  ${(props) =>
    props.black &&
    `
    color: ${props.theme.color.black};
  `}

  ${(props) =>
    props.table &&
    `
    color: ${props.theme.color.black};
    text-overflow: ellipsis;
    overflow: hidden;
    :hover {
      text-decoration: none;
      color: ${props.theme.color.blue};
    }
  `}
`;

export default TextLink;
