import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { store } from "./store";
import { theme } from "./styled";
import history from "./utils/history";
import Favicon from "react-favicon";
import config from "./config";

const render = () => {
  // for the sake of HMR
  const App = require("./App").default;

  ReactDOM.render(
    <>
      <Favicon url={config.favicon} />
      <Provider store={store}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </Router>
      </Provider>
    </>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
