import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/ducks/auth/selectors";
import Header from "../components/Header";
import config from "config";

const Home = () => {
  const user = useSelector(selectUser);

  return (
    <article>
      <Header>Welcome to {config.branding.companyName}</Header>
      <p>You're logged in with user Id: '{user?.userId}'</p>
      <p>Your last login: 2020-05-16 16:15:40</p>
    </article>
  );
};

export default Home;
