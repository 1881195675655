import { useReducer } from "react";

import { SessionParams } from "../../../store/ducks/sessions/types";
import { numberSamplesListDefaultElement } from "../constants";
import { NumberSamplesListElement } from "../types";

enum ActionTypes {
  changeAll,
  changeSessionId,
  changeNumberSamples,
}

type Action =
  | { type: ActionTypes.changeSessionId; payload: string }
  | {
      type: ActionTypes.changeNumberSamples;
      payload: NumberSamplesListElement;
    }
  | {
      type: ActionTypes.changeAll;
      payload: SessionParams;
    };

const reducer = (state: SessionParams, action: Action) => {
  switch (action.type) {
    case ActionTypes.changeSessionId:
      return { ...state, sessionId: action.payload };
    case ActionTypes.changeNumberSamples:
      return { ...state, numberSamples: action.payload };
    case ActionTypes.changeAll:
      return action.payload;
    default:
      throw new Error();
  }
};

const useProfileParams = () => {
  const [params, dispatch] = useReducer(reducer, {
    sessionId: "",
    numberSamples: numberSamplesListDefaultElement,
    clamping: false,
  });

  const changeSessionId = (sessionId: string) => {
    dispatch({ type: ActionTypes.changeSessionId, payload: sessionId });
  };
  const changeNumberSamples = (numberSamples: NumberSamplesListElement) => {
    dispatch({ type: ActionTypes.changeNumberSamples, payload: numberSamples });
  };
  const changeAll = (sessionParams: SessionParams) => {
    dispatch({ type: ActionTypes.changeAll, payload: sessionParams });
  };

  return {
    params,
    changeSessionId,
    changeNumberSamples,
    changeAll,
  };
};

export default useProfileParams;
