import styled from "styled-components";
import MatChip from "@material-ui/core/Chip";

interface Props {
  red?: "true";
  blue?: "true";
}

const Chip = styled(MatChip)<Props>`
  &&.MuiChip-root {
    margin: 20px 5px;
    .MuiChip-label {
      color: ${(props) => props.theme.color.white};
    }
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.color.blue};
    }
    background-color: ${(props) => props.theme.color.lightgreen};
    :hover {
      background-color: ${(props) => props.theme.color.green};
    }

    ${(props) =>
      props.red &&
      `
      background-color: ${props.theme.color.red}; 
      :hover {
        background-color: ${props.theme.color.darkred};
      }
    `}

    ${(props) =>
      props.blue &&
      `
      background-color: ${props.theme.color.blue}; 
      :hover {
        background-color: ${props.theme.color.darkblue};
      } 
    `}
  }
`;

export default Chip;
