import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  State,
  Session,
  SessionDetails,
  SessionStatistic,
  GroupedSessionStatistic,
  SortingParams,
  FilteringParams,
  DefaultPaginationParams,
  DefaultSortingParams,
  DefaultFilteringParams,
  DefaultStatisticFilteringParams,
  SessionParams,
} from "./types";
import { Pagination } from "../../../types/pagination";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    current: null,
    list: null,
    monthlyStatisticList: null,
    groupedStatisticList: null,
  },
  loading: {
    current: false,
    list: false,
    monthlyStatisticList: false,
    groupedStatisticList: false,
  },
  error: {
    current: null,
    list: null,
    monthlyStatisticList: null,
    groupedStatisticList: null,
  },
  pagination: {
    list: DefaultPaginationParams,
    groupedStatisticList: DefaultPaginationParams,
  },
  sorting: {
    groupedStatisticList: DefaultSortingParams,
  },
  filtering: {
    statisticList: DefaultStatisticFilteringParams,
    groupedStatisticList: DefaultFilteringParams,
  },
  needUpgrade: false,
};

const slice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    fetchCurrent: (state, _: PayloadAction<SessionParams>) => {
      state.data.current = null;
      state.loading.current = true;
      state.error.current = null;
      state.needUpgrade = false;
    },
    fetchCurrentSuccess: (state, action: PayloadAction<SessionDetails>) => {
      state.data.current = action.payload;
      state.loading.current = false;
    },
    fetchCurrentError: (state, action: PayloadAction<RequestError>) => {
      state.error.current = action.payload.message;
      state.loading.current = false;
    },
    fetchList: (state, action: PayloadAction<{}>) => {
      state.data.list = null;
      state.loading.list = true;
      state.error.list = null;
    },
    fetchListSuccess: (
      state,
      action: PayloadAction<{
        data: Array<Session>;
        pagination: Pagination;
      }>
    ) => {
      state.data.list = action.payload.data;
      state.loading.list = false;
      state.pagination.list = action.payload.pagination;
    },
    fetchListError: (state, action: PayloadAction<RequestError>) => {
      state.error.list = action.payload.message;
      state.loading.list = false;
    },
    fetchMonthlyStatisticList: (state, action: PayloadAction<{}>) => {
      state.data.monthlyStatisticList = null;
      state.loading.monthlyStatisticList = true;
      state.error.monthlyStatisticList = null;
    },
    fetchMonthlyStatisticListSuccess: (
      state,
      action: PayloadAction<Array<SessionStatistic>>
    ) => {
      state.data.monthlyStatisticList = action.payload;
      state.loading.monthlyStatisticList = false;
    },
    fetchMonthlyStatisticListError: (
      state,
      action: PayloadAction<RequestError>
    ) => {
      state.error.monthlyStatisticList = action.payload.message;
      state.loading.monthlyStatisticList = false;
    },
    fetchGroupedStatisticList: (state, action: PayloadAction<{}>) => {
      state.data.groupedStatisticList = null;
      state.loading.groupedStatisticList = true;
      state.error.groupedStatisticList = null;
    },
    fetchGroupedStatisticListSuccess: (
      state,
      action: PayloadAction<{
        data: Array<GroupedSessionStatistic>;
        pagination: Pagination;
        sorting: SortingParams;
        filtering: FilteringParams;
      }>
    ) => {
      state.data.groupedStatisticList = action.payload.data;
      state.loading.groupedStatisticList = false;
      state.pagination.groupedStatisticList = action.payload.pagination;
      state.sorting.groupedStatisticList = action.payload.sorting;
      state.filtering.groupedStatisticList = action.payload.filtering;
    },
    fetchGroupedStatisticListError: (
      state,
      action: PayloadAction<RequestError>
    ) => {
      state.error.groupedStatisticList = action.payload.message;
      state.loading.groupedStatisticList = false;
    },
    delete_: (state, action: PayloadAction<string>) => {
      state.error.current = null;
      state.loading.current = true;
    },
    deleteSuccess: (state) => {
      state.data.current = null;
      state.loading.current = false;
    },
    deleteError: (state, action: PayloadAction<RequestError>) => {
      state.error.current = action.payload.message;
      state.loading.current = false;
    },
    needUpgrade: (state) => {
      state.needUpgrade = true;
    },
  },
});

export const {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,

  fetchList,
  fetchListSuccess,
  fetchListError,

  fetchMonthlyStatisticList,
  fetchMonthlyStatisticListSuccess,
  fetchMonthlyStatisticListError,

  fetchGroupedStatisticList,
  fetchGroupedStatisticListSuccess,
  fetchGroupedStatisticListError,

  delete_,
  deleteSuccess,
  deleteError,

  needUpgrade,
} = slice.actions;

export default slice.reducer;
