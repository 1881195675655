import React, { ChangeEvent, useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
  Child,
  DefaultFilteringParams,
  DefaultPaginationParams,
} from "../../types/children";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import SimpleInput from "../../styled/SimpleInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableWrapper from "../../styled/Table/TableWrapper";
import { useDebouncedCallback } from "use-debounce";
import Pagination from "../Pagination";
import { iconsMap, linksMap } from "./utils";
import { MemberType } from "./types";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import ItemWrapper from "../../pages/Filesystem/styled/Common/ItemWrapper";
import TextLink from "../../styled/TextLink";
import { Tooltip } from "@material-ui/core";
import Icon from "../../styled/Icon";
import UIconWrapper from "./styled/Icon";
import Link from "../../styled/Link";
import labels from "../../utils/labels";

type InputEvent = ChangeEvent<HTMLInputElement>;
type ChangeHandler = (e: InputEvent) => void;

interface Props {
  id: string;
  selectChildren: (state: RootState) => Child[] | null;
  selectChildrenPagination: (state: RootState) => any | null;
  fetchChildren: ActionCreatorWithPayload<{}, string>;
  removeChild: (id: string) => void;
}

const CurrentMembers = ({
  id,
  selectChildren,
  selectChildrenPagination,
  fetchChildren,
  removeChild,
}: Props) => {
  const dispatch = useAppDispatch();

  const children = useSelector(selectChildren);
  const pagination = useSelector(selectChildrenPagination);

  useEffect(() => {
    dispatch(
      fetchChildren({
        id: id,
        pagination: DefaultPaginationParams,
        filtering: DefaultFilteringParams,
      })
    );
  }, [dispatch, fetchChildren, id]);

  const [search, setSearch] = useState(DefaultFilteringParams.idFilter);

  const [debouncedHandleChangeSearch] = useDebouncedCallback(
    (idFilter: string) => {
      dispatch(
        fetchChildren({
          id: id,
          pagination: {
            pageIndex: 0,
          },
          filtering: {
            idFilter: idFilter,
          },
        })
      );
    },
    1000
  );

  const onSearchChanged: ChangeHandler = (e) => {
    setSearch(e.target.value);
    debouncedHandleChangeSearch(e.target.value);
  };

  const onRemoveChild = (id: string) => removeChild(id);

  const handleChangePage = (event: any, page: number) => {
    dispatch(
      fetchChildren({
        id: id,
        pagination: {
          pageIndex: page - 1,
        },
      })
    );
  };

  return (
    <>
      <TableWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Members</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>
                <SimpleInput value={search} onChange={onSearchChanged} />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {children &&
              children.map((item) => {
                const type = (item.type as MemberType) || MemberType.Person;
                const UIcon = iconsMap.get(type);

                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <ItemWrapper>
                        <Link to={linksMap.get(type)!(item.id)}>
                          <Tooltip title={item.id} placement="top">
                            <ItemWrapper withIcon="true">
                              <UIconWrapper>
                                <UIcon />
                              </UIconWrapper>
                              <TextLink table="true">{item.id}</TextLink>
                            </ItemWrapper>
                          </Tooltip>
                        </Link>
                      </ItemWrapper>
                    </TableCell>
                    <TableCell onClick={() => onRemoveChild(item.id)}>
                      <Tooltip
                        title={labels.profile.removeMember}
                        placement="top"
                      >
                        <div>
                          <Icon
                            onClick={() => onRemoveChild(item.id)}
                            arrow="true"
                            table="true"
                            red="true"
                            icon={faMinus}
                            size="lg"
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableWrapper>
      <br />
      {pagination.totalPages > 0 && (
        <Pagination
          count={pagination.totalPages || 0}
          page={1}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default CurrentMembers;
