import React from "react";
import { Field } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "./styled/StyledWrapper";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateFormat } from "../../utils/datetime";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  auth?: boolean;
}

const DatePicker = ({ name, label, required, disabled, auth }: Props) => {
  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { 500: "#009fe3" }, //note: using custom components for overriding styles wont work on popup
    },
  });
  return (
    <Field
      name={name}
      validate={(value: string) =>
        required ? checkRequired(value) : undefined
      }
    >
      {({ input, meta }) => (
        <StyledWrapper auth={auth}>
          <FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <KeyboardDatePicker
                  {...input}
                  variant="inline"
                  format={DateFormat}
                  required={required}
                  disabled={disabled}
                  value={input.value === "" ? null : input.value}
                  label={label}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
            <FormHelperText error>
              {meta.touched && meta.error ? meta.error : " "}
            </FormHelperText>
          </FormControl>
        </StyledWrapper>
      )}
    </Field>
  );
};

export default DatePicker;
