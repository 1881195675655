import React from "react";
import { Helmet } from "react-helmet";

import config from "config";
import ErrorBoundary from "./components/ErrorBoundary";
import { GlobalStyle } from "./styled";
import Routes from "./Routes";
import { SnackbarUtilsConfigurator } from "./components/Toaster";

const App = () => (
  <ErrorBoundary>
    <Helmet>
      <title>{config.branding.helmet.title}</title>
      <meta name="description" content={config.branding.helmet.description} />
    </Helmet>
    <GlobalStyle />
    <Routes />
    <SnackbarUtilsConfigurator />
  </ErrorBoundary>
);

export default App;
