import React from "react";
import PaginationStyled from "./styled/PaginationStyled";

interface Props {
  count: number;
  page: number;
  onChange: (event: any, page: number) => void;
}

const Pagination = ({ count, page, onChange }: Props) => (
  <PaginationStyled
    count={count}
    variant="outlined"
    shape="rounded"
    defaultPage={page}
    siblingCount={0}
    onChange={onChange}
  />
);

export default Pagination;
