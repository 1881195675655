import React from "react";
import { Grid } from "@material-ui/core";
import { Price } from "./styled/Purchase";
import { Product } from "../../store/ducks/products/types";

interface Props {
  product: Product;
}

const ProductDetails = ({ product }: Props) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <h2>{product.name}</h2>
      </Grid>
      <Grid item>
        <p>{product.description}</p>
      </Grid>
      <Grid item>
        <Price>
          {product.currency === "usd"
            ? `$${product.amount / 100} (${product.currency.toUpperCase()}) / ${
                product.interval
              }`
            : product.amount}
        </Price>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
