import {
  Child,
  FilteringParams as ChildrenFilteringParams,
} from "../../../types/children";
import { Pagination } from "../../../types/pagination";

export interface ContactPerson {
  firstname: string;
  lastname: string;
  email: string;
}

export interface Company extends ContactPerson {
  companyId: string;
  company: string;
  street: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  email: string;
  phone?: string;
  fax?: string;
  website?: string;
  signupTime?: string;
  members?: number;
}

export interface FilteringParams {
  idFilter?: string;
}

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 50,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export const DefaultFilteringParams = {
  idFilter: "",
};

export interface State {
  data: {
    current: Company | null;
    list: Array<Company> | null;
    children: Array<Child> | null;
  };
  loading: {
    current: boolean;
    list: boolean;
    children: boolean;
  };
  error: {
    current: string | null;
    list: string | null;
    children: string | null;
  };
  pagination: {
    list: Pagination;
    children: Pagination;
  };
  filtering: {
    list: FilteringParams;
    children: ChildrenFilteringParams;
  };
}
