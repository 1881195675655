import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Grid from "@material-ui/core/Grid";

import { ROUTES_PATHS } from "../../Routes";
import { useAppDispatch } from "../../store";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { login } from "../../store/ducks/auth";
import AuthLayout from "../../styled/AuthLayout";
import AuthInputWrapper from "../../styled/AuthInputWrapper";
import Logo from "../../components/Logo";
import Link from "../../styled/Link";
import Icon from "../../styled/Icon";
import Button from "./styled/Button";
import ForgetPassword from "./styled/ForgetPassword";
import Input from "../../components/Form/Input";
import PasswordInput from "../../components/Form/PasswordInput";
import Loader from "../../components/Loader";
import { selectIsAuthLoading } from "../../store/ducks/auth/selectors";
import Title from "../RequestResetPassword/styled/Title";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useLocation } from "react-router-dom";
import { System } from "../../config/types";
import labels from "../../utils/labels";
import config from "config";

export interface LoginData {
  userId: string;
  password: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthLoading = useSelector(selectIsAuthLoading);

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const location = useLocation<{ requestResetPasswordEmail?: string }>();

  useEffect(() => {
    if (location.state?.requestResetPasswordEmail) {
      setIsDialogOpened(true);
    }
  }, [location]);

  const onSubmit = (values: LoginData) => {
    const data = {
      data: values,
      system: System.UserPortal,
      openDashboard: true,
    };
    return new Promise((resolve, reject) => {
      dispatch(login(data, { resolve, reject }));
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  return (
    <Loader
      absolute="true"
      isLoading={isAuthLoading}
      text={labels.loading.logging}
    >
      <AuthLayout>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <>
              <Logo />
              <Title>Log in</Title>
              <form onSubmit={handleSubmit}>
                <AuthInputWrapper>
                  <Input name="userId" label={labels.auth.id} required auth />
                </AuthInputWrapper>

                <PasswordInput
                  name="password"
                  label={labels.auth.password}
                  required
                  auth
                />

                <ForgetPassword>
                  <Link
                    white="true"
                    to={`/${ROUTES_PATHS.requestPasswordReset}`}
                  >
                    {labels.auth.forgotPassword}
                  </Link>
                </ForgetPassword>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Button blue="true" type="submit" disabled={submitting}>
                      <Icon icon={faSignInAlt} size="lg" />
                      {labels.button.login}
                    </Button>
                  </Grid>
                  {config.registrationUrl && (
                    <Grid item>
                      <Button blue="true" href={config.registrationUrl}>
                        <Icon icon={faSignInAlt} size="lg" />
                        {labels.button.register}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </>
          )}
        />
      </AuthLayout>
      <ConfirmationDialog
        title="Notification"
        open={isDialogOpened}
        setOpen={setIsDialogOpened}
        renderConfirmButton={(onClick: () => void) => (
          <Button blue="true" onClick={onClick}>
            {labels.auth.gotIt}
          </Button>
        )}
      >
        Password reset requested. You will receive an email (
        {location.state?.requestResetPasswordEmail}) with further instructions.
        Please reset your password within 24 hours.
      </ConfirmationDialog>
    </Loader>
  );
};

export default Login;
