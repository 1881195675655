import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, Rule } from "./types";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    current: null,
  },
  loading: {
    current: false,
  },
  error: {
    current: null,
  },
};

const slice = createSlice({
  name: "rule",
  initialState,
  reducers: {
    fetchCurrent: (state, _: PayloadAction<string>) => {
      state.data.current = null;
      state.loading.current = true;
      state.error.current = null;
    },
    fetchCurrentSuccess: (state, action: PayloadAction<Rule>) => {
      state.data.current = action.payload;
      state.loading.current = false;
    },
    fetchCurrentError: (state, action: PayloadAction<RequestError>) => {
      state.error.current = action.payload.message;
      state.loading.current = false;
    },
  },
});

export const {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,
} = slice.actions;

export default slice.reducer;
