import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all } from "redux-saga/effects";
import toast from "../../../components/Toaster";

import {
  signUp,
  signUpSuccess,
  signUpError,
  login,
  loginSuccess,
  loginError,
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  requestResetPassword,
  requestResetPasswordSuccess,
  requestResetPasswordError,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordError,
  logout,
} from ".";
import { LoginData } from "../../../pages/Login";
import { FormSubmissionMeta } from "../../../types";
import config from "config";
import { POST, Response, GET, PUT } from "../../../utils/request";
import {
  Auth,
  ChangePasswordData,
  RequestResetPasswordData,
  ResetPasswordPayload,
} from "./types";
import history from "../../../utils/history";
import { ROUTES_PATHS } from "../../../Routes";
import { User } from "../users/types";
import { SignUpData } from "../../../pages/SignUp/SignUp";

const api = {
  signUp: () => `/api/users/create`,
  login: (system: string) => `/api/systems/${system}/login`,
  changePassword: (userId: string) => `/api/users/${userId}/password`,
  requestResetPassword: (email: string) =>
    `/api/users/password/reset?email=${email}`,
  resetPassword: (userId: string, token: string) =>
    `/api/users/${userId}/password?token=${token}`,
};

function* signUpSaga(
  action: PayloadAction<SignUpData, string, FormSubmissionMeta>
) {
  try {
    const token = config.checkout?.token;
    if (!token) {
      throw new Error("Checkout config is not provided.");
    }

    const response: Response<{ user: User }> = yield POST(
      api.signUp(),
      action.payload,
      {},
      undefined,
      token
    );

    yield put(signUpSuccess(response.data.user, action.meta));
  } catch (error) {
    toast.error(error.message);
    yield put(signUpError(error, action.meta));
  }
}

function* loginSaga(
  action: PayloadAction<
    {
      data: LoginData;
      system: string;
      openDashboard: boolean;
    },
    string,
    FormSubmissionMeta
  >
) {
  try {
    const response: Response<Auth> = yield POST(
      api.login(action.payload.system),
      action.payload.data
    );

    yield put(loginSuccess(response.data, action.meta));
    toast.success("Login successful.");
    localStorage.setItem("token", response.data.token);
  } catch (error) {
    yield put(loginError(error, action.meta));
    toast.error("Login name or password is incorrect.");
  }
}

function* changePasswordSaga(
  action: PayloadAction<ChangePasswordData, string, FormSubmissionMeta>
) {
  try {
    yield PUT(api.changePassword(action.payload.userId), action.payload);

    yield put(changePasswordSuccess({}, action.meta));
    toast.success("Password changed.");
  } catch (error) {
    if (error.status === 403) {
      yield put(
        changePasswordError(
          {
            ...error,
            status: 400, // map code to another because it is not relates to expired token and already handled
          },
          action.meta
        )
      );
      toast.error("You don't have permission to update this user.");
    } else {
      toast.error(error.message);
      yield put(changePasswordError(error, action.meta));
    }
  }
}

function* requestResetPasswordSaga(
  action: PayloadAction<RequestResetPasswordData, string, FormSubmissionMeta>
) {
  try {
    yield GET(api.requestResetPassword(action.payload.email));
    yield put(requestResetPasswordSuccess(null, action.meta));
    history.push(`/${ROUTES_PATHS.login}`, {
      requestResetPasswordEmail: action.payload.email,
    });
  } catch (error) {
    toast.error(error.message);
    yield put(requestResetPasswordError(null, action.meta));
  }
}

function* resetPasswordSaga(
  action: PayloadAction<ResetPasswordPayload, string, FormSubmissionMeta>
) {
  try {
    yield PUT(api.resetPassword(action.payload.userId, action.payload.token), {
      password: action.payload.password,
    });
    yield put(resetPasswordSuccess(null, action.meta));
    toast.success("New password set");
    history.push(`/${ROUTES_PATHS.login}`);
  } catch (error) {
    const message =
      error.status === 403 ? "Could not set password." : error.message;
    toast.error(message);
    yield put(resetPasswordError(null, action.meta));
  }
}

function* logoutSaga() {
  localStorage.removeItem("token");
  history.push(`/${ROUTES_PATHS.login}`);
}

function* signUpWatcher() {
  yield takeLatest(signUp.type, signUpSaga);
}

function* loginWatcher() {
  yield takeLatest(login.type, loginSaga);
}

function* changePasswordWatcher() {
  yield takeLatest(changePassword.type, changePasswordSaga);
}

function* requestResetPasswordWatcher() {
  yield takeLatest(requestResetPassword.type, requestResetPasswordSaga);
}

function* resetPasswordWatcher() {
  yield takeLatest(resetPassword.type, resetPasswordSaga);
}

function* logoutWatcher() {
  yield takeLatest(logout.type, logoutSaga);
}

export function* rootWatcher() {
  yield all([
    fork(signUpWatcher),
    fork(loginWatcher),
    fork(changePasswordWatcher),
    fork(requestResetPasswordWatcher),
    fork(resetPasswordWatcher),
    fork(logoutWatcher),
  ]);
}

export default rootWatcher;
