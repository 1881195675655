export enum MemberType {
  Company = "Company",
  Person = "Person",
  Group = "Group",
}

export interface MembersI {
  appendChildIds: string[];
  removeChildIds: string[];
}

export const InitMembers = {
  appendChildIds: [] as string[],
  removeChildIds: [] as string[],
};
