import React from "react";
import { Field } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "./styled/StyledWrapper";
import Textarea from "./styled/Textarea";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  auth?: boolean;
}

const TextArea = ({ name, label, required, disabled, auth }: Props) => (
  <Field
    name={name}
    validate={(value: string) => (required ? checkRequired(value) : undefined)}
  >
    {({ input, meta }) => (
      <StyledWrapper auth={auth}>
        <FormControl>
          <InputLabel required={required} error={meta.touched && meta.error}>
            {label}
          </InputLabel>
          <Textarea {...input} required={required} disabled={disabled} />
          <FormHelperText error>
            {meta.touched && meta.error ? meta.error : " "}
          </FormHelperText>
        </FormControl>
      </StyledWrapper>
    )}
  </Field>
);

export default TextArea;
