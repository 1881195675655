import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch } from "../../store";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Button from "../Login/styled/Button";
import { Form } from "react-final-form";
import PasswordInput from "../../components/Form/PasswordInput";
import { ROUTES_PATHS } from "../../Routes";
import Link from "../../styled/Link";
import { login } from "../../store/ducks/auth";
import { FORM_ERROR } from "final-form";
import { System } from "../../config/types";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import Icon from "../../styled/Icon";
import { faSignInAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import PurchaseView from "./styled/PurchaseView";
import { Views } from "./constants";

interface Props {
  view: Views;
  email?: string | null;
  goToStart: () => void;
  goToError: () => void;
  goToPayment: () => void;
  setError: Dispatch<SetStateAction<string | null>>;
}

const Login = ({
  email,
  goToStart,
  goToError,
  goToPayment,
  setError,
  view,
}: Props) => {
  const dispatch = useAppDispatch();

  const auth = useSelector(selectUser)!;

  // This use effect will be executed after form sending
  useEffect(() => {
    if (auth && view === Views.login) {
      if (auth.rules[0].name !== "ROLE_Community") {
        setError(
          "You can not change the account to the selected role. If you want to change this account please contact our support team."
        );
        goToError();
      } else {
        goToPayment();
      }
    }
  }, [dispatch, auth, goToError, goToPayment, setError]);

  const onSubmit = (values: any) => {
    const data = {
      data: values,
      system: System.Store,
      openDashboard: false,
    };
    return new Promise((resolve, reject) => {
      dispatch(login(data, { resolve, reject }));
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  const initValues = {
    userId: email || "",
    password: undefined,
  };

  return (
    <PurchaseView>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <h2>Please login to continue.</h2>
        </Grid>
      </Grid>
      <br />
      <Form
        initialValues={initValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input name="userId" label="User ID or Email" required />
            <PasswordInput name="password" label="Password" required />

            <br />
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                container
                item
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Button blue="true" type="submit">
                    <Icon icon={faSignInAlt} size="lg" />
                    Login
                  </Button>
                </Grid>
                <Grid item>
                  <Button gray type="button" onClick={goToStart}>
                    <Icon icon={faTimes} size="lg" />
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <br />
                <br />
                <p>Step: 2/4</p>
              </Grid>
              <br />
              <br />
              <Grid item>
                <p>
                  Forgot your password? Click
                  <Link to={`/${ROUTES_PATHS.requestPasswordReset}`}>
                    {" "}
                    here{" "}
                  </Link>
                  choose a new one and come back to the store.
                </p>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </PurchaseView>
  );
};

export default Login;
