import React, { useEffect } from "react";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
  selectCurrent,
  selectIsCurrentLoading,
  selectNeedUpgrade,
} from "../../store/ducks/sessions/selectors";
import { fetchCurrent } from "../../store/ducks/sessions";
import Loader from "../../components/Loader";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "../../utils/location";
import toast from "../../components/Toaster";
import { getSessionHistoryParameters, getTimeLineData } from "./helpers";
import { Link } from "react-router-dom";
import SessionSettings from "./SessionSettings";
import ReportLayout, { NeedUpgrade } from "./styled/ReportLayout";
import ReportSessionChannelWrapper from "./styled/ReportSessionChannelWrapper";
import Header from "../../components/Header";
import { getDateTime } from "../../utils/datetime";
import { numberSamplesListDefaultElement } from "./constants";
import labels from "../../utils/labels";
import useProfileParams from "./hooks/useProfileParams";
import SessionChannel from "./components/SessionChannel";
import NumberSamplesSelect from "./components/NumberSamplesSelect";
import { ReportsButtons } from "./components/ReportsButtons";

const SessionReport = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();

  const session = useSelector(selectCurrent);
  const isSessionLoading = useSelector(selectIsCurrentLoading);
  const needUpgrade = useSelector(selectNeedUpgrade);

  const { params, changeNumberSamples, changeAll } = useProfileParams();

  useEffect(() => {
    const sessionId = query.get("dataChunkId");
    const clamping = query.get("clamping");
    const token = query.get("token");

    if (!sessionId || !clamping || !token) {
      toast.error("Bad request, URL parameter missing.");
      return;
    }

    changeAll({
      sessionId,
      clamping: clamping === "true",
      token,
      numberSamples: numberSamplesListDefaultElement,
    });
  }, []);

  useEffect(() => {
    if (!params.sessionId) return;

    dispatch(fetchCurrent(params));
  }, [params]);

  const sessionHistoryParameters = getSessionHistoryParameters(session);

  const timeLineData = getTimeLineData(session);

  return (
    <ReportLayout>
      <Loader
        absolute="true"
        isLoading={isSessionLoading}
        text={labels.loading.common}
      >
        <br />
        {!session && needUpgrade && (
          <>
            <br />
            <br />
            <br />
            <NeedUpgrade>
              {labels.sessions.onlineReprtingProhibitionWarning}
              <Link
                to={{ pathname: "http://www.neuromore.de/neuromore-studio" }}
                target="_blank"
              >
                subscription plans
              </Link>
              .
            </NeedUpgrade>
          </>
        )}
        {session && !needUpgrade && (
          <>
            <Header level={2}>{labels.sessions.session}</Header>
            <NumberSamplesSelect
              value={params.numberSamples}
              onChange={changeNumberSamples}
            />
            <br />
            <Grid container direction="column">
              {session.userId && (
                <label>
                  <b>
                    {labels.sessions.userId}: {session.userId}
                  </b>
                </label>
              )}
              {session.startDateTime && (
                <label>
                  {labels.sessions.start}: {getDateTime(session.startDateTime)}
                </label>
              )}
              {session.stopDateTime && (
                <label>
                  {labels.sessions.stop}: {getDateTime(session.stopDateTime)}
                </label>
              )}
              {session.classifierName && (
                <label>
                  {labels.sessions.classifier}: {session.classifierName}
                </label>
              )}
              {session.stateMachineName && (
                <label>
                  {labels.sessions.stateMachine}: {session.stateMachineName}
                </label>
              )}
              {session.experienceName && (
                <label>
                  {labels.sessions.experience}: {session.experienceName}
                </label>
              )}
            </Grid>
            <SessionSettings
              session={session}
              sessionHistoryParameters={sessionHistoryParameters}
            />
            <br />
            <ReportsButtons session={session} />
            <br />
            <br />
            {timeLineData &&
              timeLineData.length > 0 &&
              timeLineData.map((data, i) => (
                <ReportSessionChannelWrapper key={i}>
                  <Header level={3}>{data.name}</Header>
                  <SessionChannel data={data} graphHeight={100} />
                </ReportSessionChannelWrapper>
              ))}
          </>
        )}
      </Loader>
    </ReportLayout>
  );
};

export default SessionReport;
