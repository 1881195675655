import styled from "styled-components";

const ReportSessionChannelWrapper = styled.section`
  padding: 50px 0;
  border-bottom: 1px solid ${(props) => props.theme.color.blue};

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export default ReportSessionChannelWrapper;
