import queryString from "query-string";
import config from "config";
import { Pagination } from "../types/pagination";

export interface ResponseMeta {
  code: number;
  type: string;
  message: string;
  pagination?: Pagination;
}

export interface Response<T> {
  data: T;
  meta: ResponseMeta;
}

export interface RequestError {
  code: number;
  message: string;
  response: any;
}

export async function GET(
  uri: string,
  params: any = {},
  options?: RequestInit,
  token?: string | null
) {
  options = { ...options, method: "GET" };
  return request(uri, options, params, token);
}

export async function POST(
  url: string,
  body: any,
  params: any = {},
  options?: RequestInit,
  token?: string | null
) {
  options = {
    ...options,
    body: JSON.stringify(body),
    method: "POST",
  };
  return request(url, options, params, token);
}

export async function PUT(url: string, body: any, options?: RequestInit) {
  options = {
    ...options,
    body: JSON.stringify(body),
    method: "PUT",
  };
  return request(url, options);
}

export async function DELETE(uri: string, options?: RequestInit) {
  options = { ...options, method: "DELETE" };
  return request(uri, options);
}

async function request(
  uri: string,
  options?: RequestInit,
  params: any = {},
  tempToken?: string | null
) {
  // append headers
  options = {
    ...options,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = tempToken || localStorage.getItem("token");
  if (token) params.token = token;

  const query = queryString.stringify(params);

  if (query.length > 0) {
    uri = `${uri}?${query}`;
  }

  const response = await fetch(`${config.serverUrl}${uri}`, options);

  let responseJSON: Response<any> | null = null;
  try {
    responseJSON = await response.json();
  } catch (e) {}

  if (response.ok) {
    return responseJSON;
  }

  throw {
    message: responseJSON ? responseJSON.meta.message : response.statusText,
    status: response.status,
    response: responseJSON,
  };
}

export default request;
