export interface FormSubmissionMeta {
  resolve: any;
  reject: any;
}

export type ElementType<
  T extends ReadonlyArray<unknown>
> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export enum Role {
  Admin = "ROLE_Admin",
  AppSubscription = "ROLE_AppSubscription",
  BiofeedbackProvider = "ROLE_BiofeedbackProvider",
  BiofeedbackUser = "ROLE_BiofeedbackUser",
  ClinicAdmin = "ROLE_ClinicAdmin",
  ClinicClinician = "ROLE_ClinicClinician",
  ClinicOperator = "ROLE_ClinicOperator",
  ClinicPatient = "ROLE_ClinicPatient",
  Community = "ROLE_Community",
  Professional = "ROLE_Professional",
  ResellerAdmin = "ROLE_ResellerAdmin",
  Ultimate = "ROLE_Ultimate",
}
