import React from "react";
import Tabs from "../../../components/Tabs";
import { getTimeLineData } from "../helpers";
import { SessionDetails } from "../../../store/ducks/sessions/types";
import SessionChannel from "../components/SessionChannel";

type Props = {
  session?: SessionDetails;
};

const TimeLineData = ({ session }: Props) => {
  if (!session || !session.channels) {
    return null;
  }

  const timeLineData = getTimeLineData(session);
  if (!timeLineData) {
    return null;
  }

  const tabs = timeLineData.map((data) => ({
    label: data?.name ?? "",
    content: data ? <SessionChannel data={data} /> : null,
  }));

  if (!tabs.length) {
    return null;
  }

  return <Tabs tabs={tabs} />;
};

export default TimeLineData;
