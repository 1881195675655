import { RootState } from "../..";

export const selectCurrent = (state: RootState) => state.sessions.data.current;

export const selectIsCurrentLoading = (state: RootState) =>
  state.sessions.loading.current;

export const selectList = (state: RootState) => state.sessions.data.list;

export const selectListPagination = (state: RootState) =>
  state.sessions.pagination.list;

export const selectIsListLoading = (state: RootState) =>
  state.sessions.loading.list;

export const selectMonthlyStatisticList = (state: RootState) =>
  state.sessions.data.monthlyStatisticList;

export const selectIsMonthlyStatisticLoading = (state: RootState) =>
  state.sessions.loading.monthlyStatisticList;

export const selectGroupedStatisticList = (state: RootState) =>
  state.sessions.data.groupedStatisticList;

export const selectGroupedStatisticPagination = (state: RootState) =>
  state.sessions.pagination.groupedStatisticList;

export const selectIsGroupedStatisticLoading = (state: RootState) =>
  state.sessions.loading.groupedStatisticList;

export const selectNeedUpgrade = (state: RootState) =>
  state.sessions.needUpgrade;
