import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";

export const signUpFields: Record<string, FieldSetting> = prepareFields(
  {
    firstname: { disabled: false, required: true },
    lastname: { disabled: false, required: true },
    email: { disabled: false, required: true },
    password: { disabled: false, required: true },
    gender: { disabled: false, required: true },
    country: { disabled: false, required: true },
  },
  undefined,
  labels.profile
);
