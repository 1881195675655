import styled from "styled-components";
import { LoadingProps } from "..";

const Wrapper = styled.div<LoadingProps>`
  height: 100%;
  min-height: ${(props) => (props.isLoading ? "300px" : "auto")};
  width: 100%;
  position: relative;
`;

export default Wrapper;
