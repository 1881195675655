import React, { ReactElement } from "react";
import Wrapper from "./styled/Wrapper";

type HeaderLevel = 1 | 2 | 3;

interface Props {
  level?: HeaderLevel;
  icon?: ReactElement;
  children: string | string[];
  dialog?: true;
}

const headersMap = new Map<
  HeaderLevel,
  (text: string | string[]) => ReactElement
>([
  [1, (text: string | string[]) => <h1>{text}</h1>],
  [2, (text: string | string[]) => <h2>{text}</h2>],
  [3, (text: string | string[]) => <h3>{text}</h3>],
]);

const Header = ({ level, icon, children, dialog }: Props) => (
  <Wrapper dialog={dialog}>
    {icon}
    {headersMap.get(level || 1)!(children)}
  </Wrapper>
);

export default Header;
