import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectFile, selectItemMeta } from "../../store/ducks/files/selectors";
import {
  cleanFile,
  cleanItemMeta,
  createFiles,
  fetchFile,
  fetchItemMeta,
  updateItemMeta,
  updateFile,
} from "../../store/ducks/files";
import { useAppDispatch } from "../../store";
import { SelectedItem } from "./index";
import { FileType, Tag, TagType } from "../../store/ducks/files/types";
import {
  faBars,
  faFileImage,
  faFileWord,
  faFolderOpen,
  faMap,
  faPlus,
  faSave,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../styled/Icon";
import { Form } from "react-final-form";
import { noSpaces, notNegative } from "../../utils/form";
import Panel from "./styled/Common/Panel";
import FileInput from "../../components/Form/FileInput";
import StyledWrapper from "../../components/Form/styled/StyledWrapper";
import Input from "../../components/Form/Input";
import Editor from "../../components/Form/Editor";
import EditorWrapper from "./styled/Editor/EditorWrapper";
import Button from "../../styled/Button";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import labels from "../../utils/labels";
import ColorPickerW from "../../components/Form/ColorPicker/ColorPicker";
import Attributes from "./styled/Editor/Attributes";
import DatePicker from "../../components/Form/DatePicker";
import { FormControl, TextField } from "@material-ui/core";
import ItemWrapper from "./styled/Common/ItemWrapper";
import ImageWrapper from "./styled/Common/ImageWrapper";
import muse from "../../images/muse.png";
import gsr from "../../images/gsr.png";
import audio from "../../images/audio.png";
import subpac from "../../images/subpac.png";
import hrm from "../../images/hrm.png";
import FormGroup from "./styled/Common/Section";
import { features, fields, typeOptions } from "./constants";
import TextArea from "../../components/Form/TextArea";
import { arrayContains } from "../../utils/helpers";
import { licenseOptions } from "../../components/Monitoring/constants";
import Chip from "../../components/Members/styled/Chip";
import toast from "../../components/Toaster";
import { startOfDay } from "../../utils/datetime";
import { FORM_ERROR } from "final-form";
import {
  customTags,
  getBackgroundUrl,
  getDurationTag,
  getIconUrl,
} from "./helpers";
import Checkbox from "../../components/Form/Checkbox";
import Header from "../../components/Header";
import Select from "../../components/Form/Select";
import SelectCountry from "../../components/SelectCountry";
import SelectLanguage from "../../components/SelectLanguage";
import Grid from "../../styled/Grid";

interface Props {
  selectedItem: SelectedItem | null;
}

const FileFolderEditor = ({ selectedItem }: Props) => {
  const dispatch = useAppDispatch();
  const itemMeta = useSelector(selectItemMeta);
  const file = useSelector(selectFile);

  const [showSyncButton, setShowSyncButton] = useState<boolean>(false);

  const [
    createExperienceConfirmOpen,
    setCreateExperienceConfirmOpen,
  ] = useState<boolean>(false);
  const [filename, setFilename] = useState<string | null>("");

  const [tags, setTags] = useState<Tag[]>([]);

  const [background, setBackground] = useState<File | undefined>(undefined);
  const [icon, setIcon] = useState<File | undefined>(undefined);

  const [initValues, setInitValues] = React.useState<any>(undefined);

  const loadItemData = () => {
    setShowSyncButton(false);

    if (!selectedItem) {
      dispatch(cleanItemMeta());
      dispatch(cleanFile());
      return;
    }

    dispatch(fetchItemMeta(selectedItem.id));

    if (selectedItem.type === FileType.EXPERIENCE) {
      dispatch(fetchFile(selectedItem.id));
      setShowSyncButton(true);
    }
  };

  useEffect(() => {
    loadItemData();
  }, [selectedItem]);

  const onSubmit = (values: any) => {
    const data = prepareItemData(values);

    return new Promise((resolve, reject) => {
      dispatch(
        updateItemMeta(
          {
            itemId: selectedItem?.id,
            data: data,
            iconFile: icon,
            backgroundFile: background,
          },
          { resolve, reject }
        )
      );
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  const prepareItemData = (values: any) => {
    let itemFeatures: string[] = [];
    let itemTags: Tag[] = customTags(tags);

    features.forEach((feature) => {
      if (values[feature]) {
        itemFeatures.push(feature);
        itemTags.push({ type: TagType.DEVICE, value: feature });
      }
    });

    let custom: any = {};

    console.log(values.color);
    custom.color = values.color
      ? {
          ...values.color,
          a: Math.ceil(values.color.a * 255).toString(),
        }
      : undefined;

    if (values.duration) {
      custom.duration = Math.round(values.duration / 60).toFixed(0);
      custom.durationInSeconds = values.duration;
      itemTags.push(getDurationTag(custom.duration));
    }

    if (itemFeatures.length) {
      custom.features = itemFeatures;
    }

    if (values.videoUrl) {
      custom.videoUrl = values.videoUrl;
    }

    if (values.audioUrl) {
      custom.audioUrl = values.audioUrl;
    }

    let iconUrl = undefined;
    if (icon) {
      iconUrl = getIconUrl(icon.type, selectedItem!.id);
    } else if (values.iconUrl && values.iconUrl.slice(0, 4) === "http") {
      iconUrl = values.iconUrl;
    }

    let backgroundUrl = undefined;
    if (background) {
      backgroundUrl = getBackgroundUrl(background.type, selectedItem!.id);
    } else if (
      values.backgroundUrl &&
      values.backgroundUrl.slice(0, 4) === "http"
    ) {
      backgroundUrl = values.previewIconUrl;
    }

    return {
      summary: values.summary,
      description: values.description,
      credits: values.credits,
      custom: Base64.encode(JSON.stringify(custom)),
      priority: values.priority ? values.priority : undefined,
      releaseDateTime: values.releaseDateTime
        ? startOfDay(values.releaseDateTime)
        : undefined,
      iconUrl: iconUrl,
      backgroundUrl: backgroundUrl,
      tags: itemTags,
    };
  };

  const createExperience = () => {
    if (!filename) {
      return toast.error("Please enter file name.");
    }

    createFiles({
      filename: filename,
      folderId: selectedItem?.id,
    });
  };

  const appendToTags = (values: any) => {
    if (!values.tagValue) {
      return toast.error("Your tag has no value.");
    }
    const newTag = { type: values.tagType, value: values.tagValue };

    if (tags.find((tag) => tag === newTag)) {
      return toast.error("Tag already in list.");
    }

    setTags([...tags, newTag]);
  };

  const removeFromTags = (tag: Tag) => {
    setTags(tags.filter((targetTag) => targetTag !== tag));
  };

  const prepareFormInitValues = () => {
    if (!itemMeta) {
      return;
    }

    let custom: any = {};
    if (itemMeta.custom) {
      const data = JSON.parse(Base64.decode(itemMeta.custom));
      console.log(data.color);
      custom = {
        color: data.color,
        muse: data.features && arrayContains(data.features, "muse"),
        gsr: data.features && arrayContains(data.features, "gsr"),
        audio: data.features && arrayContains(data.features, "audio"),
        subpac: data.features && arrayContains(data.features, "subpac"),
        hrm: data.features && arrayContains(data.features, "hrm"),
        audioUrl: data.audioUrl,
        videoUrl: data.videoUrl,
        duration: data.durationInSeconds,
      };
    }

    if (itemMeta.tags) {
      setTags(customTags(itemMeta.tags));
    }

    return {
      ...itemMeta,
      priority: 0,
      tagType: TagType.LICENSE,
      tagValue: undefined,
      ...custom,
    };
  };

  useMemo(() => {
    setInitValues(prepareFormInitValues());
  }, [itemMeta]);

  const syncTemplate = (values: any) => {
    if (!file) {
      return;
    }

    if (!values.videoUrl && !values.audioUrl) {
      return toast.error("Missing Audio/Video URL in attributes.");
    }

    if (!values.duration) {
      return toast.error('Missing "Duration" in attributes.');
    }

    return new Promise((resolve, reject) => {
      dispatch(
        updateFile(
          {
            file: file!,
            data: {
              videoUrl: values.videoUrl,
              audioUrl: values.audioUrl,
              duration: values.duration,
            },
          },
          { resolve, reject }
        )
      );
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  return (
    <article>
      <Panel>
        {!itemMeta && <Header>{labels.files.selectItem}</Header>}

        {itemMeta && (
          <Grid>
            <Grid container direction="row" alignItems="center">
              <Grid container item direction="row" alignItems="center">
                <h1>
                  <Icon icon={faFolderOpen} size="lg" />
                  {selectedItem ? selectedItem.name : labels.files.selectItem}
                </h1>
              </Grid>
              {selectedItem?.type === FileType.FOLDER && (
                <Grid>
                  <Button
                    title="true"
                    blue="true"
                    type="button"
                    onClick={() => setCreateExperienceConfirmOpen(true)}
                  >
                    <Icon icon={faPlus} size="lg" />
                    Audio XP
                  </Button>
                  <ConfirmationDialog
                    title={labels.files.createExperience}
                    children={
                      <TextField
                        autoFocus
                        fullWidth
                        {...fields.filename}
                        onChange={(event) =>
                          setFilename(event.target.value as string)
                        }
                      />
                    }
                    open={createExperienceConfirmOpen}
                    setOpen={setCreateExperienceConfirmOpen}
                    onConfirm={() => createExperience()}
                    onCancel={() => setCreateExperienceConfirmOpen(false)}
                  />
                </Grid>
              )}
            </Grid>
            <Form
              onSubmit={onSubmit}
              initialValues={initValues}
              render={({ handleSubmit, submitting, values, form }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={4}>
                      <h3>
                        <Icon icon={faBars} size="lg" />
                        Summary
                      </h3>
                      <TextArea {...fields.summary} />
                    </Grid>
                    <Grid item xs={4}>
                      <h3>
                        <Icon icon={faFileWord} size="lg" />
                        Description
                      </h3>
                      <EditorWrapper>
                        <Editor
                          {...fields.description}
                          initValue={initValues.description}
                        />
                      </EditorWrapper>
                    </Grid>
                    {selectedItem?.type === FileType.FOLDER && (
                      <Grid container item xs={4}>
                        <Grid item>
                          <h3>
                            <Icon icon={faFileImage} size="lg" />
                            Background
                          </h3>
                          <FileInput
                            {...fields.backgroundUrl}
                            initialValue={initValues.backgroundUrl}
                            customCallback={setBackground}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <h3>
                            <Icon icon={faFileImage} size="lg" />
                            Icon
                          </h3>
                          <FileInput
                            {...fields.iconUrl}
                            initialValue={initValues.iconUrl}
                            customCallback={setIcon}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {(selectedItem?.type === FileType.FOLDER ||
                      selectedItem?.type === FileType.EXPERIENCE) && (
                      <>
                        <Grid container item xs={4}>
                          <Grid item xs={4}>
                            <h3>
                              <Icon icon={faMap} size="lg" />
                              Attributes
                            </h3>
                            <Attributes>
                              {selectedItem.type === FileType.EXPERIENCE && (
                                <>
                                  <Grid container item>
                                    <Grid item xs={6}>
                                      <StyledWrapper>
                                        <Input
                                          {...fields.audioUrl}
                                          validation={noSpaces}
                                        />
                                      </StyledWrapper>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <StyledWrapper>
                                        <Input
                                          {...fields.videoUrl}
                                          validation={noSpaces}
                                        />
                                      </StyledWrapper>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <StyledWrapper>
                                        <Input
                                          {...fields.duration}
                                          type={"number"}
                                          validation={notNegative}
                                        />
                                      </StyledWrapper>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              <Grid container item>
                                {selectedItem.type === FileType.EXPERIENCE && (
                                  <Grid item xs={6}>
                                    <StyledWrapper>
                                      <Input
                                        {...fields.credits}
                                        type={"number"}
                                        validation={notNegative}
                                      />
                                    </StyledWrapper>
                                  </Grid>
                                )}
                                <Grid item xs={6}>
                                  <StyledWrapper>
                                    <Input
                                      {...fields.priority}
                                      type={"number"}
                                      validation={notNegative}
                                    />
                                  </StyledWrapper>
                                </Grid>
                                {selectedItem.type === FileType.FOLDER && (
                                  <Grid item xs={6}>
                                    <ColorPickerW
                                      {...fields.color}
                                      defaultColor={initValues.color}
                                    />
                                  </Grid>
                                )}
                                {selectedItem.type === FileType.EXPERIENCE && (
                                  <Grid item xs={6}>
                                    <DatePicker {...fields.releaseDateTime} />
                                  </Grid>
                                )}
                              </Grid>
                            </Attributes>
                          </Grid>
                        </Grid>
                        {selectedItem.type === FileType.EXPERIENCE && (
                          <Grid container item xs={4}>
                            <Attributes>
                              <h3>
                                <Icon icon={faFileWord} size="lg" />
                                Devices
                              </h3>

                              <Grid container item xs={4}>
                                <FormControl>
                                  <FormGroup>
                                    <Grid item>
                                      <ItemWrapper>
                                        <Checkbox name="muse" />
                                        <ImageWrapper small="true">
                                          <img src={muse} />
                                        </ImageWrapper>
                                      </ItemWrapper>
                                    </Grid>
                                    <Grid item>
                                      <ItemWrapper>
                                        <Checkbox name="gsr" />
                                        <ImageWrapper small="true">
                                          <img src={gsr} />
                                        </ImageWrapper>
                                      </ItemWrapper>
                                    </Grid>
                                    <Grid item>
                                      <ItemWrapper>
                                        <Checkbox name="audio" />
                                        <ImageWrapper small="true">
                                          <img src={audio} />
                                        </ImageWrapper>
                                      </ItemWrapper>
                                    </Grid>
                                    <Grid item>
                                      <ItemWrapper>
                                        <Checkbox name="subpac" />
                                        <ImageWrapper small="true">
                                          <img src={subpac} />
                                        </ImageWrapper>
                                      </ItemWrapper>
                                    </Grid>
                                    <Grid item>
                                      <ItemWrapper>
                                        <Checkbox name="hrm" />
                                        <ImageWrapper small="true">
                                          <img src={hrm} />
                                        </ImageWrapper>
                                      </ItemWrapper>
                                    </Grid>
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            </Attributes>
                          </Grid>
                        )}
                        {selectedItem.type === FileType.EXPERIENCE && (
                          <>
                            <Grid container item>
                              <Attributes>
                                <h3>
                                  <Icon icon={faFileWord} size="lg"></Icon>
                                  Tags
                                </h3>
                                <Grid container item xs={6}>
                                  <Grid item xs={6}>
                                    <Select
                                      {...fields.tagType}
                                      options={typeOptions}
                                      customOnChangeCallback={() => {
                                        form.change("tagValue", undefined);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <StyledWrapper>
                                      {values.tagType === "AUTHOR" && (
                                        <Input {...fields.tagValue} />
                                      )}
                                      {values.tagType === "CATEGORY" && (
                                        <Input {...fields.tagValue} />
                                      )}
                                      {values.tagType === "COUNTRY" && (
                                        <SelectCountry {...fields.tagValue} />
                                      )}
                                      {values.tagType === "LANGUAGE" && (
                                        <SelectLanguage {...fields.tagValue} />
                                      )}
                                      {values.tagType === "LICENSE" && (
                                        <Select
                                          {...fields.tagValue}
                                          options={licenseOptions}
                                        />
                                      )}
                                    </StyledWrapper>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      small="true"
                                      gray
                                      onClick={() => {
                                        appendToTags(values);
                                        form.change("tagValue", undefined);
                                      }}
                                    >
                                      <Icon icon={faPlus} size="lg" />
                                      Add
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Attributes>
                            </Grid>
                            <Grid container item>
                              {tags.map((tag) => (
                                <Chip
                                  label={`${tag.type} : ${tag.value}`}
                                  blue="true"
                                  onDelete={() => removeFromTags(tag)}
                                  color="secondary"
                                />
                              ))}
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                    <ItemWrapper>
                      <Button blue="true" type="submit" disabled={submitting}>
                        <Icon icon={faSave} size="lg" />
                        Save
                      </Button>

                      {showSyncButton && (
                        <Button
                          blue="true"
                          type="button"
                          disabled={submitting}
                          onClick={() => syncTemplate(values)}
                        >
                          <Icon icon={faSync} size="lg" />
                          Sync
                        </Button>
                      )}
                    </ItemWrapper>
                  </form>
                </>
              )}
            />
          </Grid>
        )}
      </Panel>
    </article>
  );
};

export default FileFolderEditor;
