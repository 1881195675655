import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, Language } from "./types";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    list: null,
  },
  loading: {
    list: false,
  },
  error: {
    list: null,
  },
};

const slice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    fetchList: (state, action: PayloadAction<{}>) => {
      state.data.list = null;
      state.loading.list = true;
      state.error.list = null;
    },
    fetchListSuccess: (state, action: PayloadAction<Array<Language>>) => {
      state.data.list = action.payload;
      state.loading.list = false;
    },
    fetchListError: (state, action: PayloadAction<RequestError>) => {
      state.error.list = action.payload.message;
      state.loading.list = false;
    },
  },
});

export const { fetchList, fetchListSuccess, fetchListError } = slice.actions;

export default slice.reducer;
