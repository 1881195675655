import styled from "styled-components";
import { LoadingProps } from "..";

const SpinnerWrapper = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isLoading ? "1" : "0")};
  visibility: ${(props) => (props.isLoading ? "visible" : "hidden")};
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 200;
  position: absolute;
  margin: -30px;
  width: calc(100% + 60px);
  height: calc(100% + 60px);

  ${(props) =>
    props.absolute &&
    `
    left: 0;
    right: 0;
    margin: initial;
    width: 100% !important;
  `}
`;

export default SpinnerWrapper;
