import styled from "styled-components";
interface Props {
  left?: "true";
}

const Panel = styled.div<Props>`
  @media (max-width: ${(props) =>
      props.theme.breakpoint.large - 1}) and (min-width: ${(props) =>
      props.theme.breakpoint.medium}) {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
  }
  ${(props) =>
    props.left &&
    `
    padding-bottom: 20px;
  `}
`;

export default Panel;
