import React from "react";
import MatCheckbox from "./styled/Checkbox";
import StyledWrapper from "./styled/StyledWrapper";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "react-final-form";

interface Props {
  name: string;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
}

const Checkbox = ({ name, icon, checkedIcon }: Props) => {
  return (
    <Field name={name} type={"checkbox"}>
      {({ input }) => (
        <StyledWrapper>
          <FormControl>
            <MatCheckbox icon={icon} checkedIcon={checkedIcon} {...input} />
          </FormControl>
        </StyledWrapper>
      )}
    </Field>
  );
};

export default Checkbox;
