import { combineReducers } from "@reduxjs/toolkit";
import auth from "./ducks/auth";
import countries from "./ducks/countries";
import companies from "./ducks/companies";
import groups from "./ducks/groups";
import users from "./ducks/users";
import rule from "./ducks/rule";
import events from "./ducks/events";
import logs from "./ducks/logs";
import sessions from "./ducks/sessions";
import files from "./ducks/files";
import languages from "./ducks/languages";
import products from "./ducks/products";

const rootReducer = combineReducers({
  auth,
  countries,
  companies,
  groups,
  users,
  rule,
  events,
  logs,
  sessions,
  files,
  languages,
  products,
});

export default rootReducer;
