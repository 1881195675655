import styled from "styled-components";

const PurchaseView = styled.article`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    flex: 1 1 80%;
    max-width: 80%;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    flex: 1 1 50%;
    max-width: 50%;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.large}) {
    flex: 1 1 25%;
    max-width: 25%;
  }
`;

export default PurchaseView;
