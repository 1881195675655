import styled from "styled-components";
import { CSVLink } from "react-csv";

const CLink = styled(CSVLink)`
  && {
    text-decoration: none;
  }
`;

export default CLink;
