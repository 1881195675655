import React, { Dispatch, SetStateAction } from "react";
import { RootState } from "../../store";
import {
  selectList as selectCompanyList,
  selectListPagination as selectCompanyListPagination,
} from "../../store/ducks/companies/selectors";
import {
  selectList as selectGroupList,
  selectListPagination as selectGroupListPagination,
} from "../../store/ducks/groups/selectors";
import {
  selectList as selectUserList,
  selectListPagination as selectUserListPagination,
} from "../../store/ducks/users/selectors";
import { fetchList as fetchCompanyList } from "../../store/ducks/companies";
import { fetchList as fetchGroupList } from "../../store/ducks/groups";
import { fetchList as fetchUserList } from "../../store/ducks/users";
import { Child } from "../../types/children";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import Candidates from "./Candidates";
import Current from "./Current";
import Grid from "@material-ui/core/Grid";
import Chip from "./styled/Chip";
import { MembersI, MemberType } from "./types";
import labels from "../../utils/labels";

interface Props {
  id: string;
  selectChildren: (state: RootState) => Child[] | null;
  selectChildrenPagination: (state: RootState) => any;
  fetchChildren: ActionCreatorWithPayload<{}, string>;
  setMembers: Dispatch<SetStateAction<MembersI>>;
  getMembers: () => MembersI;
}

const Members = ({
  id,
  selectChildren,
  selectChildrenPagination,
  fetchChildren,
  setMembers,
  getMembers,
}: Props) => {
  const appendChild = (id: string) => {
    const members = getMembers();

    if (members.removeChildIds.includes(id)) {
      setMembers({
        ...members,
        removeChildIds: members.removeChildIds.filter((item) => item !== id),
      });
      return;
    }

    if (!members.appendChildIds.includes(id)) {
      setMembers({
        ...members,
        appendChildIds: [...members.appendChildIds, id],
      });
    }

    return;
  };

  const removeChild = (id: string) => {
    const members = getMembers();

    if (members.appendChildIds.includes(id)) {
      setMembers({
        ...members,
        appendChildIds: members.appendChildIds.filter((item) => item !== id),
      });
      return;
    }

    if (!members.removeChildIds.includes(id)) {
      setMembers({
        ...members,
        removeChildIds: [...members.removeChildIds, id],
      });
    }

    return;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Current
            id={id}
            selectChildren={selectChildren}
            selectChildrenPagination={selectChildrenPagination}
            fetchChildren={fetchChildren}
            removeChild={removeChild}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Candidates
            title={labels.profile.addUser}
            idField="userId"
            type={MemberType.Person}
            selectList={selectUserList}
            fetchList={fetchUserList}
            selectListPagination={selectUserListPagination}
            appendChild={appendChild}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Candidates
            title={labels.profile.addCompany}
            idField="companyId"
            type={MemberType.Company}
            selectList={selectCompanyList}
            fetchList={fetchCompanyList}
            selectListPagination={selectCompanyListPagination}
            appendChild={appendChild}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Candidates
            title={labels.profile.addGroup}
            idField="groupId"
            type={MemberType.Group}
            selectList={selectGroupList}
            fetchList={fetchGroupList}
            selectListPagination={selectGroupListPagination}
            appendChild={appendChild}
          />
        </Grid>
      </Grid>
      {getMembers().appendChildIds.map((member) => (
        <Chip
          label={member}
          onDelete={() => removeChild(member)}
          color="primary"
        />
      ))}
      {getMembers().removeChildIds.map((member) => (
        <Chip red="true" label={member} onDelete={() => appendChild(member)} />
      ))}
    </>
  );
};

export default Members;
