import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import {
  selectGroupedStatisticList,
  selectGroupedStatisticPagination,
} from "../../store/ducks/sessions/selectors";
import {
  DefaultFilteringParams,
  DefaultPaginationParams,
  DefaultSortingParams,
  FilteringParams,
  Provider,
  SessionHistoryParams,
  SortingParams,
} from "../../store/ducks/sessions/types";
import { fetchGroupedStatisticList } from "../../store/ducks/sessions";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import { selectUser } from "../../store/ducks/auth/selectors";
import {
  convertMilliSecondsToTimeString,
  getDateTime,
} from "../../utils/datetime";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableWrapper from "../../styled/Table/TableWrapper";
import SpeedDials from "../../components/SpeedDial";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { Views } from "./index";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import SearchIcon from "@material-ui/icons/Search";
import { Form } from "react-final-form";
import { TableSortLabel, Tooltip } from "@material-ui/core";
import {
  usersOverviewSearchOptions,
  usersOverviewTableHeaders,
} from "./constants";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import HeaderWrapper from "../../components/MainLayout/styled/HeaderWrapper";
import labels from "../../utils/labels";

interface Props {
  openSessionHistory: () => void;
  setSessionHistoryParams: Dispatch<SetStateAction<SessionHistoryParams>>;
  view: Views;
}

const UsersOverview = ({
  openSessionHistory,
  setSessionHistoryParams,
  view,
}: Props) => {
  const dispatch = useAppDispatch();

  const auth = useSelector(selectUser)!;
  const sessions = useSelector(selectGroupedStatisticList);

  const showShowButton = hasRule(auth, RULES.REST_getDataChunk);

  const pagination = useSelector(selectGroupedStatisticPagination);
  const [search, setSearch] = useState<FilteringParams>({
    filterKey: "firstname",
  });
  const [sorting, setSorting] = useState<SortingParams>(DefaultSortingParams);

  const getSearch = () => (search.filterValue ? search : {});

  useEffect(() => {
    if (view === Views.usersOverview) {
      dispatch(
        fetchGroupedStatisticList({
          pagination: DefaultPaginationParams,
          filtering: DefaultFilteringParams,
          sorting: DefaultSortingParams,
        })
      );
    }
  }, [view, dispatch]);

  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      fetchGroupedStatisticList({
        pagination: {
          pageIndex: newPage,
        },
        filtering: getSearch(),
        sorting: sorting,
      })
    );
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const pageSize = parseInt((event.target as HTMLSelectElement).value, 10);
    dispatch(
      fetchGroupedStatisticList({
        pagination: {
          pageSize,
          pageIndex: 0,
        },
        filtering: getSearch(),
        sorting: sorting,
      })
    );
  };

  const showUserSessionHistory = (user?: Provider) => {
    setSessionHistoryParams({
      userId: user ? user.userId : undefined,
      supervisorId: hasRule(auth, RULES.ROLE_BiofeedbackProvider)
        ? auth.userId
        : undefined,
    });
    openSessionHistory();
  };

  const actions = (user: Provider | undefined) => {
    let result = [];
    if (showShowButton) {
      result.push({
        icon: <Icon icon={faHistory} default="true" size="lg" />,
        name: labels.sessions.showHistory,
        handler: () => showUserSessionHistory(user),
      });
    }
    return result;
  };

  const onSubmitSearch = (filtering: FilteringParams) => {
    setSearch(filtering);
    dispatch(
      fetchGroupedStatisticList({
        pagination: {
          pageIndex: 0,
        },
        filtering: filtering.filterValue ? filtering : DefaultFilteringParams,
        sorting: sorting,
      })
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const newValue: SortingParams = {
      sortKey: property,
      sortDir:
        sorting.sortKey === property && sorting.sortDir === "asc"
          ? "desc"
          : "asc",
    };

    setSorting(newValue);
    dispatch(
      fetchGroupedStatisticList({
        pagination: pagination,
        filtering: getSearch(),
        sorting: newValue,
      })
    );
  };

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  return (
    <AuthWrapper>
      <HeaderWrapper button="true">
        <Tooltip title={labels.sessions.showCompleteHistory} placement="top">
          <div>
            <Button blue="true" onClick={() => showUserSessionHistory()}>
              <Icon icon={faHistory} size="lg" />
            </Button>
          </div>
        </Tooltip>
      </HeaderWrapper>

      <Form
        initialValues={search}
        onSubmit={onSubmitSearch}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input name="filterValue" label="Search..." />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Select
                  options={usersOverviewSearchOptions}
                  name="filterKey"
                  label=""
                />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Button blue="true" type="submit">
                  <SearchIcon />
                  {labels.button.search}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {usersOverviewTableHeaders.map((header) => (
                <TableCell
                  key={header.field}
                  sortDirection={
                    sorting.sortKey === header.field ? sorting.sortDir : false
                  }
                >
                  <TableSortLabel
                    active={sorting.sortKey === header.field}
                    direction={sorting.sortDir}
                    onClick={createSortHandler(header.field)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions &&
              sessions.map((session, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {session.user ? session.user.firstname : ""}
                  </TableCell>
                  <TableCell>
                    {session.user ? session.user.lastname : ""}
                  </TableCell>
                  <TableCell>
                    {session.user ? session.user.email : ""}
                  </TableCell>
                  <TableCell>{session.sessions}</TableCell>
                  <TableCell>
                    {convertMilliSecondsToTimeString(session.duration)}
                  </TableCell>
                  <TableCell>
                    {convertMilliSecondsToTimeString(session.avgDuration)}
                  </TableCell>
                  <TableCell>{session.sessionName}</TableCell>
                  <TableCell>{getDateTime(session.sessionTime)}</TableCell>
                  <TableCell align="right">
                    <SpeedDials actions={actions(session.user)} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={pagination.totalElements || 0}
                rowsPerPage={pagination.pageSize || 0}
                page={pagination.pageIndex || 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableWrapper>
    </AuthWrapper>
  );
};

export default UsersOverview;
