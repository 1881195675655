import React, { useEffect } from "react";
import { RootState, useAppDispatch } from "../../store";
import { CircularProgress, FormControl, TextField } from "@material-ui/core";
import { Autocomplete as MatAutocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { Field } from "react-final-form";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "../Form/styled/StyledWrapper";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  selectList: (state: RootState) => any[] | null;
  selectIsListLoading: (state: RootState) => boolean | null;
  fetchList: ActionCreatorWithPayload<{}>;
  labelField: string;
  valueField: string;
  filterField: string;
}

const Autocomplete = ({
  name,
  label,
  required,
  disabled,
  selectList,
  selectIsListLoading,
  fetchList,
  valueField,
  filterField,
}: Props) => {
  const dispatch = useAppDispatch();

  const [options, setOptions] = React.useState<string[]>([]);

  const list = useSelector(selectList);
  const isListLoading = useSelector(selectIsListLoading);

  useEffect(() => {
    if (!isListLoading) {
      setOptions(list ? list.map((item) => item[valueField]) : []);
    }
  }, [list, isListLoading, valueField]);

  const [debouncedHandleChangeSearch] = useDebouncedCallback(
    (value: string) => {
      if (!value) {
        return;
      }

      dispatch(
        fetchList({
          filtering: {
            [filterField]: value,
          },
        })
      );
    },
    1000
  );

  return (
    <>
      <Field
        name={name}
        validate={(value: string) =>
          required ? checkRequired(value) : undefined
        }
      >
        {({ input, meta }) => (
          <StyledWrapper>
            <FormControl>
              <MatAutocomplete
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                filterOptions={(x: any) => x}
                options={options || []}
                value={input.value}
                onChange={(event, value) => {
                  input.onChange(value);
                }}
                inputValue={input.value}
                onInputChange={(event, value) => {
                  input.onChange(value);
                  debouncedHandleChangeSearch(value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={label}
                    required={required}
                    disabled={disabled}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isListLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {meta.touched && meta.error ? meta.error : " "}
              </FormHelperText>
            </FormControl>
          </StyledWrapper>
        )}
      </Field>
    </>
  );
};

export default Autocomplete;
