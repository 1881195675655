import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Group } from "../../store/ducks/groups/types";
import { selectCurrent as selectRule } from "../../store/ducks/rule/selectors";
import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Button from "../Login/styled/Button";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import Parent from "../../components/Parent";
import { useAppDispatch } from "../../store";
import { create } from "../../store/ducks/groups";
import { FORM_ERROR } from "final-form";
import Header from "../../components/Header";
import GroupIcon from "@material-ui/icons/Group";

export interface CreateGroupData extends Group {
  appendParentIds: string[];
}

const CreateGroup = () => {
  const dispatch = useAppDispatch();

  const rule = useSelector(selectRule)!;

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      groupId: {},
      name: {},
      appendParentIds: {},
    },
    rule,
    labels.group
  );

  const onSubmit = (values: Group) => {
    const data = {
      ...values,
      appendParentIds: parent ? [parent] : [],
    };
    return new Promise((resolve, reject) => {
      dispatch(create(data, { resolve, reject }));
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  const [parent, setParent] = useState<string | null>(null);

  return (
    <>
      <Header level={2} icon={<GroupIcon />}>
        {labels.group.create}
      </Header>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <Input {...fields.groupId} />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Input {...fields.name} />
              </Grid>
            </Grid>

            <Parent {...fields.appendParentIds} setParentId={setParent} />

            <br />
            {
              <Button start="true" blue="true" type="submit">
                <SaveIcon />
                {labels.button.create}
              </Button>
            }
          </form>
        )}
      />
    </>
  );
};

export default CreateGroup;
