import { AnyAction } from "@reduxjs/toolkit";
import { Middleware } from "redux";
import { AppDispatch } from "..";

const createFormSubmissionMiddleware = () => {
  const formSubmissionMiddleware: Middleware = () => (next: AppDispatch) => (
    action: AnyAction
  ) => {
    if (action.type.endsWith("Success") && action.meta?.resolve) {
      action.meta.resolve();
    }

    if (action.type.endsWith("Error") && action.meta?.reject) {
      action.meta.reject(action.payload);
    }

    return next(action);
  };

  return formSubmissionMiddleware;
};

export default createFormSubmissionMiddleware;
