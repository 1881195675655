import React from "react";
import { Grid } from "@material-ui/core";
import config from "config";
import { Link } from "react-router-dom";
import PurchaseView from "./styled/PurchaseView";

const Thanks = () => {
  return (
    <PurchaseView>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <h2>Thank you.</h2>
        </Grid>
        <Grid item>
          <p>
            Congratulation! You now own a neuromore account, Let's get started
            by logging into the
            <Link to={{ pathname: config.url }} target="_blank">
              {" "}
              User Portal{" "}
            </Link>
            and download the neuromore Studio and/or connect to your provider if
            you are subscribed to the biofeeback user plan. If you subscribed to
            a plan, you received all relevant information via email.
            <br />
            <br />
            Any questions?{" "}
            <Link
              to={{ pathname: "http://www.neuromore.com/contact/" }}
              target="_blank"
            >
              Contact our team
            </Link>
            .
          </p>
        </Grid>
      </Grid>
    </PurchaseView>
  );
};

export default Thanks;
