import React from "react";
import { useAppDispatch } from "../../store";
import { fetchList, delete_ } from "../../store/ducks/companies";
import {
  selectList,
  selectListPagination,
  selectIsListLoading,
} from "../../store/ducks/companies/selectors";
import { selectUser } from "../../store/ducks/auth/selectors";
import {
  DefaultFilteringParams,
  DefaultPaginationParams,
} from "../../store/ducks/companies/types";
import { useHistory } from "react-router-dom";
import { ROUTES_PATHS } from "../../Routes";
import CRMTable from "../../components/CRM";
import { useSelector } from "react-redux";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import labels from "../../utils/labels";

const columns = [
  {
    header: labels.company.companyId,
    field: "companyId",
    filterField: "idFilter",
  },
  { header: labels.company.name, field: "name" },
  { header: labels.company.firstname, field: "firstname" },
  { header: labels.company.lastname, field: "lastname" },
  { header: labels.company.country, field: "country" },
  { header: labels.company.city, field: "city" },
  { header: labels.company.members, field: "members", defaultValue: 0 },
];

const ListCompanies = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useSelector(selectUser)!;

  const showCreateButton = hasRule(user, RULES.REST_createCompany);
  const showShowButton = hasRule(user, RULES.REST_getCompany);
  const showDeleteButton = hasRule(user, RULES.REST_deleteCompany);

  const createCompany = () => {
    return history.push(`/${ROUTES_PATHS.createCompany}`);
  };

  const showCompany = (id: string) => {
    return history.push(`/${ROUTES_PATHS.company}/${id}`);
  };

  const deleteCompany = (id: string) => {
    dispatch(delete_(id));
  };

  return (
    <CRMTable
      columns={columns}
      idField="companyId"
      selectList={selectList}
      selectListPagination={selectListPagination}
      selectIsListLoading={selectIsListLoading}
      fetchList={fetchList}
      defaultPaginationParams={DefaultPaginationParams}
      defaultFilteringParams={DefaultFilteringParams}
      createButtonLabel={labels.company.create}
      showCreateButton={showCreateButton}
      onCreate={createCompany}
      showShowButton={showShowButton}
      onShow={showCompany}
      showDeleteButton={showDeleteButton}
      onDelete={deleteCompany}
    />
  );
};

export default ListCompanies;
