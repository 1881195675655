import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { User } from "../../store/ducks/users/types";
import { selectCurrent as selectRule } from "../../store/ducks/rule/selectors";
import { email, FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import { genderOptions, roleOptions } from "../Profile/constants";
import DatePicker from "../../components/Form/DatePicker";
import Button from "../Login/styled/Button";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import PasswordInput from "../../components/Form/PasswordInput";
import Checkbox from "../../components/Checkbox";
import Parent from "../../components/Parent";
import { create } from "../../store/ducks/users";
import { FORM_ERROR } from "final-form";
import { removeTimezoneOffset } from "../../utils/datetime";
import PersonIcon from "@material-ui/icons/Person";
import Header from "../../components/Header";
import SelectCountry from "../../components/SelectCountry";
import { mapRoleStringRepresentationToID } from "../Profile/utils";

export type CreateUserData = Omit<User, "role"> & {
  appendParentIds: string[];
  role?: number;
};

const CreateUser = () => {
  const dispatch = useAppDispatch();

  // rules contains validation conditions
  const rule = useSelector(selectRule)!; // rule must be already filled up because it is AuthenticatedRoute

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      firstname: {},
      lastname: {},
      middlename: {},
      email: {},
      password: {},
      confirmed: {},
      gender: {},
      birthday: {},
      street: {},
      zip: {},
      city: {},
      state: {},
      country: {},
      phone: {},
      fax: {},
      website: {},
      role: {
        required: true,
        disabled: false,
      },
      appendParentIds: {},
    },
    rule,
    labels.profile
  );

  const onSubmit = (values: User) => {
    const data = {
      ...values,
      appendParentIds: parent ? [parent] : [],
      birthday: values.birthday
        ? removeTimezoneOffset(values.birthday)
        : undefined,
      role: mapRoleStringRepresentationToID(values.role),
    };

    return new Promise((resolve, reject) => {
      dispatch(create(data, { resolve, reject }));
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  const [parent, setParent] = useState<string | null>(null);

  const [autoPasswordState, setAutoPasswordState] = React.useState(true);

  const getAutoPasswordState = () => autoPasswordState;

  return (
    <>
      <Header level={2} icon={<PersonIcon />}>
        {labels.profile.create}
      </Header>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.firstname} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.middlename} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.lastname} />
              </Grid>
            </Grid>

            <Parent {...fields.appendParentIds} setParentId={setParent} />

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input
                  {...fields.email}
                  required={fields.email.required || autoPasswordState}
                  validation={email}
                />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Select options={genderOptions} {...fields.gender} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <DatePicker {...fields.birthday} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.street} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.city} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.zip} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.state} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <SelectCountry {...fields.country} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.phone} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.fax} />
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                <Input {...fields.website} />
              </Grid>
            </Grid>

            <Grid item xs={6} md={3} lg={2}>
              <Select options={roleOptions} {...fields.role} />
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={2}>
                <Checkbox
                  label="User has to set password itself? (Requires Email)"
                  getValue={getAutoPasswordState}
                  setValue={setAutoPasswordState}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <PasswordInput
                  {...fields.password}
                  required={!autoPasswordState}
                  disabled={fields.password.disabled || autoPasswordState}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <PasswordInput
                  {...fields.confirmed}
                  required={!autoPasswordState}
                  disabled={fields.password.disabled || autoPasswordState}
                />
              </Grid>
            </Grid>

            <br />
            <Button start="true" blue="true" type="submit">
              <SaveIcon />
              {labels.button.create}
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default CreateUser;
