import { PayloadAction } from "@reduxjs/toolkit";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";

import {
  createFiles,
  createFilesSuccess,
  createFilesError,
  updateFile,
  updateFileSuccess,
  updateFileError,
  fetchFile,
  fetchFileError,
  fetchFileSuccess,
  fetchItemMeta,
  fetchItemMetaError,
  fetchItemMetaSuccess,
  fetchItems,
  fetchItemsError,
  fetchItemsSuccess,
  updateItemMeta,
  updateItemMetaError,
  updateItemMetaSuccess,
} from ".";
import { FormSubmissionMeta } from "../../../types";
import {
  ChildItemInfo,
  FileInfo,
  FilteringParams,
  ItemMeta,
  ParentItemInfo,
  ImageCategory,
} from "./types";
import { mapPaginationFields, Pagination } from "../../../types/pagination";
import { GET, POST, PUT, Response } from "../../../utils/request";
import toast from "../../../components/Toaster";
import { selectListFiltering, selectListPagination } from "./selectors";
import { selectUser } from "../auth/selectors";
import { Base64 } from "js-base64";
import {
  classifierDefaultBody,
  classifierDefaultDependency,
  defaultMode,
  experienceDefaultBody,
  stateMachineDefaultBody,
  stateMachineDefaultDependency,
} from "./constants";
import config from "config";
import { ExperienceArtifacts } from "../../../config/types";

const filePath = `/api/files`;
const filesystemPath = `/api/filesystem`;
const api = {
  single: (item: string) => `${filePath}/${item}/get`,
  create: () => `${filePath}/create`,
  update: (item: string) => `${filePath}/${item}/update`,
  getMeta: (item: string) => `${filesystemPath}/${item}/meta/get`,
  updateMeta: (item: string) => `${filesystemPath}/${item}/meta/set`,
  getItems: () => `${filesystemPath}/get`,
};

function* fetchFileSaga(action: PayloadAction<string>) {
  try {
    const response: Response<{ file: FileInfo }> = yield GET(
      api.single(action.payload)
    );

    yield put(fetchFileSuccess(response.data.file));
  } catch (error) {
    yield put(fetchFileError(error));
  }
}

function* createFilesSaga(
  action: PayloadAction<{
    filename: string;
    folderId: string;
  }>
) {
  try {
    const stateMachineBody: any = {
      ...stateMachineDefaultBody,
      name: action.payload.filename,
      folderId: action.payload.folderId,
    };

    const createStateMachineResponse: Response<{ fileId: string }> = yield POST(
      api.create(),
      stateMachineBody
    );

    const classifierBody: any = {
      ...classifierDefaultBody,
      name: action.payload.filename,
      folderId: action.payload.folderId,
    };

    const createClassifierResponse: Response<{ fileId: string }> = yield POST(
      api.create(),
      classifierBody
    );

    const stateMachineDependency = {
      ...stateMachineDefaultDependency,
      value: createStateMachineResponse.data.fileId,
    };

    const classifierDependency = {
      ...classifierDefaultDependency,
      value: createClassifierResponse.data.fileId,
    };

    const content = {
      attributes: [stateMachineDependency, classifierDependency],
    };

    const experienceBody = {
      ...experienceDefaultBody,
      content: Base64.encode(JSON.stringify(content)),
      name: action.payload.filename,
      folderId: action.payload.folderId,
    };

    const createExperienceResponse: Response<{ fileId: string }> = yield POST(
      api.create(),
      experienceBody
    );

    yield put(fetchItems({}));
    yield put(fetchItemMeta(createExperienceResponse.data.fileId));
    yield put(fetchFile(createExperienceResponse.data.fileId));

    yield put(createFilesSuccess());
    toast.success("Experience created.");
  } catch (error) {
    yield put(createFilesError(error));
    toast.error(error.message);
  }
}

function* updateFileSaga(
  action: PayloadAction<
    {
      file: FileInfo;
      data: {
        audioUrl: string | undefined;
        videoUrl: string | undefined;
        duration: number;
      };
    },
    string,
    FormSubmissionMeta
  >
) {
  try {
    const dependencies = JSON.parse(Base64.decode(action.payload.file.content));

    let stateMachineID;
    let classifierID;
    for (let attribute of dependencies.attributes) {
      if (attribute.name === "Classifier UUID") {
        classifierID = attribute.value;
      } else if (attribute.name === "State Machine UUID") {
        stateMachineID = attribute.value;
      }
    }

    if (!stateMachineID) {
      throw new Error("No state machine in experience.");
    }
    if (!classifierID) {
      throw new Error("No classifier in experience.");
    }

    const templates = config.filesystem?.files.templates;
    if (!templates) {
      throw new Error("Filesystem config is not provided.");
    }

    const classifierTemplate = templates.classifier;

    let stateMachineTemplate;
    // audio + video
    if (action.payload.data.videoUrl && action.payload.data.audioUrl) {
      stateMachineTemplate =
        templates.stateMachine[ExperienceArtifacts.AudioVideo];
    }
    // audio only
    else if (!action.payload.data.videoUrl && action.payload.data.audioUrl) {
      stateMachineTemplate = templates.stateMachine[ExperienceArtifacts.Audio];
    }
    // video only
    else if (action.payload.data.videoUrl && !action.payload.data.audioUrl) {
      stateMachineTemplate = templates.stateMachine[ExperienceArtifacts.Video];
    }

    if (!stateMachineTemplate) {
      throw new Error("No state machine template");
    }

    // update state machine
    const getStateMachineResponse: Response<{ file: FileInfo }> = yield GET(
      api.single(stateMachineTemplate)
    );

    let replaceStateMachineContent = Base64.decode(
      getStateMachineResponse.data.file.content
    );
    if (action.payload.data.audioUrl) {
      replaceStateMachineContent = replaceStateMachineContent
        .split("${AUDIO_URL}")
        .join(action.payload.data.audioUrl);
    }
    if (action.payload.data.videoUrl) {
      replaceStateMachineContent = replaceStateMachineContent
        .split("${VIDEO_URL}")
        .join(action.payload.data.videoUrl);
    }
    if (action.payload.data.duration) {
      replaceStateMachineContent = replaceStateMachineContent
        .split("-1234567890.0")
        .join(action.payload.data.videoUrl);
    }

    yield PUT(api.update(stateMachineID), {
      content: Base64.encode(replaceStateMachineContent),
    });
    toast.success("State machine update successful.");

    // update classifier
    const getClassifierResponse: Response<{ file: FileInfo }> = yield GET(
      api.single(classifierTemplate)
    );

    let replaceClassifierContent = Base64.decode(
      getClassifierResponse.data.file.content
    );

    yield PUT(api.update(classifierID), {
      content: Base64.encode(replaceClassifierContent),
    });

    toast.success("Classifier update successful.");

    yield put(updateFileSuccess({}, action.meta));
  } catch (error) {
    yield put(updateFileError(error, action.meta));
    toast.error(error.message);
  }
}

function* fetchItemMetaSaga(action: PayloadAction<string>) {
  try {
    const response: Response<{ itemMeta: ItemMeta }> = yield GET(
      api.getMeta(action.payload)
    );

    yield put(fetchItemMetaSuccess(response.data.itemMeta));
  } catch (error) {
    yield put(fetchItemMetaError(error));
    toast.error(error.message);
  }
}

function* updateItemMetaSaga(
  action: PayloadAction<
    {
      itemId: string;
      data: any;
      iconFile: File | undefined;
      backgroundFile: File | undefined;
    },
    string,
    FormSubmissionMeta
  >
) {
  try {
    yield PUT(api.updateMeta(action.payload.itemId), action.payload.data);

    if (action.payload.iconFile) {
      yield uploadImage(
        action.payload.iconFile,
        "icons",
        action.payload.itemId
      );
    }

    if (action.payload.backgroundFile) {
      yield uploadImage(
        action.payload.backgroundFile,
        "backgrounds",
        action.payload.itemId
      );
    }

    yield put(updateItemMetaSuccess({}, action.meta));
    toast.success("Item updated.");
  } catch (error) {
    yield put(updateItemMetaError(error, action.meta));
    toast.error(error.message);
  }
}

function* fetchItemsSaga(
  action: PayloadAction<{
    pagination: Pagination;
    filtering: FilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectListPagination);
    const oldFiltering = yield select(selectListFiltering);
    const auth = yield select(selectUser);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{
      parentItems: ParentItemInfo[];
      items: ChildItemInfo[];
    }> = yield GET(api.getItems(), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
      mode: defaultMode,
      userId: auth.userId,
    });

    yield put(
      fetchItemsSuccess({
        childItems: response.data.items,
        parentItems: response.data.parentItems,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchItemsError(error));
    toast.error(error.message);
  }
}

function* uploadImage(file: File, category: ImageCategory, itemId: string) {
  try {
    const fileExt = file.type.split("/")[1];

    if (!config.filesystem) {
      return new Error("filesystem config is not provided");
    }

    let fd = new FormData();
    fd.append("key", `${config.filesystem.aws[category]}/${itemId}.${fileExt}`);
    fd.append("AWSAccessKeyId", config.filesystem.aws.AWSAccessKeyId);
    fd.append("acl", config.filesystem.aws.acl);
    fd.append("success_action_redirect", config.filesystem.aws.redirect);
    fd.append("policy", config.filesystem.aws.policy);
    fd.append("signature", config.filesystem.aws.signature);
    fd.append("Content-Type", file.type);
    fd.append("file", file);

    const response = yield fetch(config.filesystem.aws.endpoint, {
      body: fd,
      method: "POST",
    });

    if (!response.ok) {
      throw {
        code: response.status,
        message: "Upload file failed. Please try again.",
        uploadError: true,
      };
    }
  } catch (error) {
    if (error.uploadError) {
      toast.error(error.message);
    }
  }
}

function* fetchFileWatcher() {
  yield takeLatest(fetchFile.type, fetchFileSaga);
}

function* createFileWatcher() {
  yield takeLatest(createFiles.type, createFilesSaga);
}

function* updateFileWatcher() {
  yield takeLatest(updateFile.type, updateFileSaga);
}

function* fetchItemMetaWatcher() {
  yield takeLatest(fetchItemMeta.type, fetchItemMetaSaga);
}

function* updateItemMetaWatcher() {
  yield takeLatest(updateItemMeta.type, updateItemMetaSaga);
}

function* fetchItemsWatcher() {
  yield takeLatest(fetchItems.type, fetchItemsSaga);
}

export function* rootWatcher() {
  yield all([
    fork(fetchFileWatcher),
    fork(createFileWatcher),
    fork(updateFileWatcher),
    fork(fetchItemMetaWatcher),
    fork(updateItemMetaWatcher),
    fork(fetchItemsWatcher),
  ]);
}

export default rootWatcher;
