import styled from "styled-components";
interface Props {
  withIcon?: "true";
}

const ItemWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.withIcon &&
    `
    display: flex;
    color: ${props.theme.color.black};
    :hover {
      color: ${props.theme.color.blue};
    }
  `}
`;

export default ItemWrapper;
