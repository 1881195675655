export enum ChildType {
  Person = "Person",
  Group = "Group",
  Company = "Company",
}

export interface Child {
  id: string;
  type: string;
  name: string | null;
  email: string | null;
}

export interface FilteringParams {
  idFilter?: string;
}

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 5,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export const DefaultFilteringParams = {
  idFilter: "",
};

export function fromArray(array: Array<string | null>) {
  return {
    id: array[0] || "",
    type: array[1] || "",
    email: array[2],
    name: array[3],
  };
}
