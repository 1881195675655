import React, { useState } from "react";
import { ChromePicker } from "react-color";
import {
  FormControl,
  Button,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Field } from "react-final-form";
import ColorPickerWidget from "./styled/ColorPickerWidget";
import OptionsWrapper from "./styled/OptionsWrapper";
import StyledWrapper from "../styled/StyledWrapper";
import ColorPickerWrapper from "./styled/ColorPickerWrapper";
import Icon from "../../../styled/Icon";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Wrapper from "./styled/Wrapper";
import TextLink from "../../../styled/TextLink";
import { toRGBAString } from "./utils";

interface Props {
  defaultColor?: any;
  name: string;
  required?: boolean;
  label: string;
}

const ColorPickerW = ({ defaultColor, name, required, label }: Props) => {
  const [displayColorPicker, setColorPicker] = useState(false);
  const [color, setColor] = useState(toRGBAString(defaultColor));

  const handleChangeComplete = () => {
    setColorPicker(false);
  };

  const handleClick = () => {
    setColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setColorPicker(false);
  };

  return (
    <Wrapper>
      <Field name={name}>
        {({ input, meta }) => (
          <FormControl>
            {displayColorPicker ? (
              <ColorPickerWrapper>
                <div onClick={handleClose} />
                <ChromePicker
                  color={color}
                  onChange={(e) => {
                    setColor(toRGBAString(e.rgb));
                    input.onChange(e.rgb);
                  }}
                />
                <OptionsWrapper>
                  <Button type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="button" onClick={handleChangeComplete}>
                    Select
                  </Button>
                </OptionsWrapper>
              </ColorPickerWrapper>
            ) : null}

            <Wrapper>
              <ColorPickerWidget
                style={{ backgroundColor: color }}
                onClick={handleClick}
              ></ColorPickerWidget>

              <StyledWrapper picker>
                <TextField
                  {...input}
                  required={required}
                  value={color}
                  label={label}
                  InputProps={{
                    endAdornment: color && (
                      <InputAdornment position="end">
                        <TextLink
                          black="true"
                          onClick={() => {
                            setColor("");
                            input.onChange(undefined);
                          }}
                        >
                          <Icon icon={faWindowClose} size="lg"></Icon>
                        </TextLink>
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledWrapper>
            </Wrapper>
          </FormControl>
        )}
      </Field>
    </Wrapper>
  );
};

export default ColorPickerW;
