import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import { MemberType } from "./types";
import { ROUTES_PATHS } from "../../Routes";

export const iconsMap = new Map<MemberType, any>([
  [MemberType.Person, PersonIcon],
  [MemberType.Company, BusinessCenterIcon],
  [MemberType.Group, GroupIcon],
]);

export const linksMap = new Map<MemberType, (id: string) => string>([
  [MemberType.Person, (id) => `/${ROUTES_PATHS.user}/${id}`],
  [MemberType.Company, (id) => `/${ROUTES_PATHS.company}/${id}`],
  [MemberType.Group, (id) => `/${ROUTES_PATHS.group}/${id}`],
]);
