import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { useParams } from "react-router-dom";
import { Company } from "../../store/ducks/companies/types";
import {
  selectChildren,
  selectCurrent,
  selectChildrenPagination,
  selectIsListLoading as selectIsCompaniesListLoading,
} from "../../store/ducks/companies/selectors";
import {
  fetchCurrent,
  fetchChildren,
  update,
} from "../../store/ducks/companies";
import {
  selectCurrent as selectRule,
  selectIsCurrentLoading as selectRuleIsLoading,
} from "../../store/ducks/rule/selectors";
import Members from "../../components/Members";
import { selectUser } from "../../store/ducks/auth/selectors";
import { email, FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";
import {
  selectIsChildrenLoading as selectIsCompanyChildrenLoading,
  selectIsCurrentLoading as selectIsCompanyLoading,
  selectIsListLoading as selectIsCompanyListLoading,
} from "../../store/ducks/companies/selectors";
import { selectIsListLoading as selectIsGroupListLoading } from "../../store/ducks/groups/selectors";
import Loader from "../../components/Loader";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import { Form } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Button from "../Login/styled/Button";
import SaveIcon from "@material-ui/icons/Save";
import PersonIcon from "@material-ui/icons/Person";
import { FORM_ERROR } from "final-form";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import Header from "../../components/Header";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SelectCountry from "../../components/SelectCountry";
import { InitMembers } from "../../components/Members/types";

export interface UpdateCompanyData extends Company {
  appendChildIds: string[];
  removeChildIds: string[];
}

const UpdateCompany = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const company = useSelector(selectCurrent);
  const auth = useSelector(selectUser)!; // auth must be already filled up because it is AuthenticatedRoute
  const rule = useSelector(selectRule)!; // rule must be already filled up because it is AuthenticatedRoute

  const showUpdateButton = hasRule(auth, RULES.REST_updateCompany);

  useEffect(() => {
    dispatch(fetchCurrent(id));
  }, [dispatch, id]);

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      companyId: {},
      company: {},
      street: {},
      zip: {},
      city: {},
      state: {},
      country: {},
      phone: {},
      fax: {},
      website: {},
      firstname: {},
      lastname: {},
      email: {},
    },
    rule,
    labels.company
  );

  const isCompanyLoading = useSelector(selectIsCompanyLoading);
  const isCompanyChildrenLoading = useSelector(selectIsCompanyChildrenLoading);
  const isRuleLoading = useSelector(selectRuleIsLoading);
  const isCompanyListLoading = useSelector(selectIsCompanyListLoading);
  const isGroupListLoading = useSelector(selectIsGroupListLoading);
  const isCompaniesListLoading = useSelector(selectIsCompaniesListLoading);
  const isCompanyViewLoading =
    isCompanyLoading ||
    isCompanyChildrenLoading ||
    isRuleLoading ||
    isCompanyListLoading ||
    isGroupListLoading ||
    isCompaniesListLoading;

  const onSubmit = (values: Company) => {
    const data = {
      ...values,
      ...members,
    };

    return new Promise((resolve, reject) => {
      dispatch(update(data, { resolve, reject }));
    })
      .then(() => {
        setMembers(InitMembers);
      })
      .catch((error) => {
        return {
          [FORM_ERROR]: error,
        };
      });
  };

  const [members, setMembers] = useState(InitMembers);

  const getMembers = () => members;

  return (
    <Loader isLoading={isCompanyViewLoading} text={labels.loading.company}>
      <AuthWrapper>
        {company && (
          <Header level={2} icon={<BusinessCenterIcon />}>
            {company.company}
          </Header>
        )}
        <Form
          initialValues={company!}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.companyId} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.company} />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.street} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.city} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.zip} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.state} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <SelectCountry {...fields.country} />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.phone} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.fax} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.website} />
                </Grid>
              </Grid>

              <Header level={3} icon={<PersonIcon />}>
                Contact Person
              </Header>

              <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.firstname} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.lastname} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.email} validation={email} />
                </Grid>
              </Grid>

              <Members
                id={id}
                selectChildren={selectChildren}
                selectChildrenPagination={selectChildrenPagination}
                fetchChildren={fetchChildren}
                getMembers={getMembers}
                setMembers={setMembers}
              />

              <br />
              <br />
              {showUpdateButton && (
                <Button start="true" blue="true" type="submit">
                  <SaveIcon />
                  {labels.button.save}
                </Button>
              )}
            </form>
          )}
        />
      </AuthWrapper>
    </Loader>
  );
};

export default UpdateCompany;
