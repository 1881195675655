import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all, select } from "redux-saga/effects";

import {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,
  fetchList,
  fetchListSuccess,
  fetchListError,
  fetchChildren,
  fetchChildrenSuccess,
  fetchChildrenError,
  create,
  createSuccess,
  createError,
  update,
  updateSuccess,
  updateError,
  delete_,
  deleteSuccess,
  deleteError,
} from ".";
import { FormSubmissionMeta } from "../../../types";
import { CreateUserData } from "../../../pages/CreateUser";
import { UpdateUserData } from "../../../pages/Profile";
import { FilteringParams, User } from "./types";
import {
  FilteringParams as ChildrenFilteringParams,
  fromArray,
} from "../../../types/children";
import { DELETE, GET, POST, PUT, Response } from "../../../utils/request";
import { mapPaginationFields, Pagination } from "../../../types/pagination";
import {
  selectChildrenFiltering,
  selectChildrenPagination,
  selectListFiltering,
  selectListPagination,
} from "./selectors";
import toast from "../../../components/Toaster";
import history from "../../../utils/history";
import { ROUTES_PATHS } from "../../../Routes";
import { addTimezoneOffset } from "../../../utils/datetime";

const path = `/api/users`;
const api = {
  single: (userId: string) => `${path}/${userId}/get`,
  list: () => `${path}/get`,
  children: (userId: string) => `${path}/${userId}/children/get`,
  create: () => `${path}/create`,
  update: (userId: string) => `${path}/${userId}/update`,
  delete: (userId: string) => `${path}/${userId}/delete`,
};

function* fetchCurrentSaga(action: PayloadAction<string>) {
  try {
    const response: Response<{ user: User }> = yield GET(
      api.single(action.payload)
    );

    let user = response.data.user;
    user.birthday = user.birthday
      ? addTimezoneOffset(user.birthday)
      : undefined;

    yield put(fetchCurrentSuccess(response.data.user));
  } catch (error) {
    yield put(fetchCurrentError(error));
    toast.error(error.message);
  }
}

function* fetchListSaga(
  action: PayloadAction<{
    pagination: Pagination;
    filtering: FilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectListPagination);
    const oldFiltering = yield select(selectListFiltering);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{ users: User[] }> = yield GET(api.list(), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
    });

    yield put(
      fetchListSuccess({
        data: response.data.users,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchListError(error));
    toast.error(error.message);
  }
}

function* fetchChildrenSaga(
  action: PayloadAction<{
    id: string;
    pagination: Pagination;
    filtering: ChildrenFilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectChildrenPagination);
    const oldFiltering = yield select(selectChildrenFiltering);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{
      children: Array<Array<string | null>>;
    }> = yield GET(api.children(action.payload.id), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
    });

    yield put(
      fetchChildrenSuccess({
        data: response.data.children.map((children) => fromArray(children)),
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchChildrenError(error));
    toast.error(error.message);
  }
}

function* createSaga(
  action: PayloadAction<CreateUserData, string, FormSubmissionMeta>
) {
  try {
    const response: Response<{ user: User }> = yield POST(
      api.create(),
      action.payload
    );

    yield put(createSuccess(response.data.user, action.meta));
    toast.success("User created.");
    history.push(`/${ROUTES_PATHS.user}/${response.data.user.userId}`);
  } catch (error) {
    yield put(createError(error, action.meta));
    toast.error(error.message);
  }
}

function* updateSaga(
  action: PayloadAction<UpdateUserData, string, FormSubmissionMeta>
) {
  try {
    yield PUT(api.update(action.payload.userId), action.payload);

    const response: Response<{ user: User }> = yield GET(
      api.single(action.payload.userId)
    );

    yield put(updateSuccess(response.data.user, action.meta));
    toast.success("User updated.");

    yield put(fetchCurrent(action.payload.userId));
    yield put(
      fetchChildren({
        id: action.payload.userId,
      })
    );
  } catch (error) {
    if (error.status === 403) {
      yield put(
        updateError(
          {
            ...error,
            status: 400, // map code to another because it is not relates to expired token and already handled
          },
          action.meta
        )
      );
      toast.error("You don't have permission to update this user.");
    } else {
      yield put(updateError(error, action.meta));
      toast.error(error.message);
    }
  }
}

function* deleteSaga(
  action: PayloadAction<string, string, FormSubmissionMeta>
) {
  try {
    yield DELETE(api.delete(action.payload));

    yield put(deleteSuccess());
    toast.success("User deleted.");
    yield put(fetchList({}));
  } catch (error) {
    if (error.status === 403) {
      yield put(
        deleteError({
          ...error,
          status: 400, // map code to another because it is not relates to expired token and already handled
        })
      );
    } else {
      yield put(deleteError(error));
    }
    toast.error(error.message);
  }
}

function* fetchCurrentWatcher() {
  yield takeLatest(fetchCurrent.type, fetchCurrentSaga);
}

function* fetchListWatcher() {
  yield takeLatest(fetchList.type, fetchListSaga);
}

function* fetchChildrenWatcher() {
  yield takeLatest(fetchChildren.type, fetchChildrenSaga);
}

function* createWatcher() {
  yield takeLatest(create.type, createSaga);
}

function* updateWatcher() {
  yield takeLatest(update.type, updateSaga);
}

function* deleteWatcher() {
  yield takeLatest(delete_.type, deleteSaga);
}

export function* rootWatcher() {
  yield all([
    fork(fetchCurrentWatcher),
    fork(fetchListWatcher),
    fork(fetchChildrenWatcher),
    fork(createWatcher),
    fork(updateWatcher),
    fork(deleteWatcher),
  ]);
}

export default rootWatcher;
