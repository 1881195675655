import styled from "styled-components";

const CustomFileInput = styled.div`
  color: transparent;
  width: fit-content;
  outline:none;
}
  ::-webkit-file-upload-button {
    visibility: hidden;
  }

  ::before {
    content: "Drop or Select File";
    color: ${(props) => props.theme.color.darkgray};
    display: inline-block;
    border: 5px dotted ${(props) => props.theme.color.gray};
    border-radius: 12px;
    width: 100%;
    height: 100%;
    outline: 0;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    background-color: ${(props) => props.theme.color.lightgray};
    text-align: center;
    line-height: 90px;
  }

  :hover::before {
    border-color: ${(props) => props.theme.color.blue};
  }

  :active {
    outline: 0;
  }

  :active::before {
    background: -webkit-linear-gradient(
      top,
      ${(props) => props.theme.color.lightgray},
      ${(props) => props.theme.color.customFileInput},
    );
  }
`;

export default CustomFileInput;
