import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES_PATHS } from "../Routes";
import {
  selectIsOpenDashboard,
  selectUser,
} from "../store/ducks/auth/selectors";

const NotAuthenticatedRoute = ({ children, ...rest }: any) => {
  const user = useSelector(selectUser);
  const openDashboard = useSelector(selectIsOpenDashboard);

  if (!user) localStorage.removeItem("token");

  return (
    <Route
      {...rest}
      render={() =>
        user && openDashboard ? (
          <Redirect to={`/${ROUTES_PATHS.home}`} />
        ) : (
          children
        )
      }
    />
  );
};

export default NotAuthenticatedRoute;
