import { FORM_ERROR } from "final-form";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";

import { ROUTES_PATHS } from "../../Routes";
import { faRetweet, faTimes } from "@fortawesome/free-solid-svg-icons";
import AuthLayout from "../../styled/AuthLayout";
import Loader from "../../components/Loader";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import Link from "../../styled/Link";
import Logo from "../../components/Logo";
import Options from "../RequestResetPassword/styled/Options";
import Title from "../RequestResetPassword/styled/Title";
import { resetPassword } from "../../store/ducks/auth";
import { useAppDispatch } from "../../store";
import { ResetPasswordFormData } from "../../store/ducks/auth/types";
import { selectIsAuthLoading } from "../../store/ducks/auth/selectors";
import PasswordInput from "../../components/Form/PasswordInput";
import toast from "../../components/Toaster";
import labels from "../../utils/labels";

const RequestResetPassword = () => {
  const dispatch = useAppDispatch();
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const location = useLocation();
  const history = useHistory();

  const [userData, setUserData] = useState<{ userId: string; token: string }>({
    userId: "",
    token: "",
  });

  useEffect(() => {
    const search = queryString.parse(location.search);
    if (!search.token || !search.userId) {
      history.replace(`/${ROUTES_PATHS.login}`);
      return;
    }

    setUserData({
      userId: search.userId as string,
      token: search.token as string,
    });
  }, []);

  const onSubmit = (values: ResetPasswordFormData) => {
    if (values.password !== values.confirmPassword) {
      toast.error("Passwords are not equal.");
      return;
    }

    return new Promise((resolve, reject) => {
      dispatch(
        resetPassword(
          {
            password: values.password,
            ...userData,
          },
          { resolve, reject }
        )
      );
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  return (
    <Loader
      absolute="true"
      isLoading={isAuthLoading}
      text={labels.loading.sendingEmail}
    >
      <AuthLayout>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <>
              <Logo />
              <Title>{labels.auth.setNewPassword}</Title>
              <form onSubmit={handleSubmit}>
                <PasswordInput
                  name="password"
                  label={labels.auth.password}
                  required
                  extendedValidation={true}
                  auth
                />
                <br />
                <br />
                <PasswordInput
                  name="confirmPassword"
                  label={labels.auth.comfirm}
                  required
                  auth
                />
                <Options>
                  <Button blue="true" type="submit" disabled={submitting}>
                    <Icon icon={faRetweet} size="lg" />
                    {labels.button.setPassword}
                  </Button>
                  <Link to={`/${ROUTES_PATHS.login}`}>
                    <Button gray type="button" disabled={submitting}>
                      <Icon icon={faTimes} size="lg" />
                      {labels.button.cancel}
                    </Button>
                  </Link>
                </Options>
              </form>
            </>
          )}
        />
      </AuthLayout>
    </Loader>
  );
};

export default RequestResetPassword;
