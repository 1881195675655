import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginData } from "../../../pages/Login";
import {
  State,
  Auth,
  RequestResetPasswordData,
  ChangePasswordData,
  ResetPasswordPayload,
} from "./types";
import { FormSubmissionMeta } from "../../../types";
import { User } from "../users/types";
import { SignUpData } from "../../../pages/SignUp/SignUp";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: null,
  isLoading: false,
  error: null,
  openDashboard: true,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signUp: {
      reducer: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      prepare: (payload: SignUpData, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    signUpSuccess: {
      reducer: (state, action: PayloadAction<User>) => {
        state.isLoading = false;
      },
      prepare: (payload: User, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    signUpError: {
      reducer: (state, action: PayloadAction<RequestError>) => {
        state.error = action.payload.message;
        state.isLoading = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    login: {
      reducer: (
        state,
        action: PayloadAction<{
          data: LoginData;
          system: string;
          openDashboard: boolean;
        }>
      ) => {
        state.data = null;
        state.isLoading = true;
        state.openDashboard = action.payload.openDashboard;
      },
      prepare: (payload: any, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    loginSuccess: {
      reducer: (state, action: PayloadAction<Auth>) => {
        state.data = action.payload;
        state.isLoading = false;
      },
      prepare: (payload: Auth, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    loginError: {
      reducer: (state) => {
        state.isLoading = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    changePassword: {
      reducer: (state) => {
        state.isLoading = true;
      },
      prepare: (payload: ChangePasswordData, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    changePasswordSuccess: {
      reducer: (state, action: PayloadAction<{}>) => {
        state.isLoading = false;
      },
      prepare: (payload: {}, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    changePasswordError: {
      reducer: (state) => {
        state.isLoading = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    requestResetPassword: {
      reducer: (state) => {
        state.isLoading = true;
      },
      prepare: (
        payload: RequestResetPasswordData,
        meta: FormSubmissionMeta
      ) => ({
        payload,
        meta,
      }),
    },
    requestResetPasswordSuccess: {
      reducer: (state) => {
        state.isLoading = false;
      },
      prepare: (payload: null, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    requestResetPasswordError: {
      reducer: (state) => {
        state.isLoading = false;
      },
      prepare: (payload: null, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    resetPassword: {
      reducer: (state) => {
        state.isLoading = true;
      },
      prepare: (payload: ResetPasswordPayload, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    resetPasswordSuccess: {
      reducer: (state) => {
        state.isLoading = false;
      },
      prepare: (payload: null, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    resetPasswordError: {
      reducer: (state) => {
        state.isLoading = false;
      },
      prepare: (payload: null, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    logout: (state) => {
      state.data = null;
    },
  },
});

export const {
  signUp,
  signUpSuccess,
  signUpError,

  login,
  loginSuccess,
  loginError,

  changePassword,
  changePasswordSuccess,
  changePasswordError,

  requestResetPassword,
  requestResetPasswordSuccess,
  requestResetPasswordError,

  resetPassword,
  resetPasswordSuccess,
  resetPasswordError,

  logout,
} = slice.actions;

export default slice.reducer;
