import HeaderWrapper from "../../../components/MainLayout/styled/HeaderWrapper";
import Icon from "../../../styled/Icon";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface Props {
  onClick: () => void;
}

const CloseButton = ({ onClick }: Props) => {
  return (
    <HeaderWrapper button="true">
      <Icon
        blue="true"
        icon={faTimesCircle}
        size="3x"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
    </HeaderWrapper>
  );
};

export default CloseButton;
