import styled from "styled-components";
import Button from "../../../styled/Button";

export const Section = styled.section`
  margin: 20px 0;

  ${Button} {
    margin-left: 0;
  }
`;
