import styled from "styled-components";

const OptionsWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.black};
  width: 225px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
  box-sizing: initial;
  justify-content: center;
`;

export default OptionsWrapper;
