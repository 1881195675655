import styled from "styled-components";

interface Props {
  img: string;
}

const Cluster = styled.div<Props>`
  cursor: pointer;
  width: 56px;
  height: 56px;
  position: relative;
  left: -28px;
  top: -28px;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
`;

export default Cluster;
