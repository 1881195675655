import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES_PATHS } from "../Routes";
import { selectUser } from "../store/ducks/auth/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import { fetchCurrent as fetchBackendRule } from "../store/ducks/rule";
import { selectError as selectRuleError } from "../store/ducks/rule/selectors";
import { hasRule } from "../store/ducks/auth/utils";
import toast from "./Toaster";
import labels from "../utils/labels";

const AuthenticatedRoute = ({
  children,
  frontendRule,
  backendRule = { rule: null, optional: false },
  ...rest
}: any) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const backendError = useSelector(selectRuleError);

  // send request to check if has backendRule permission.
  useEffect(() => {
    if (backendRule.rule) {
      dispatch(fetchBackendRule(backendRule.rule));
    }
  }, [backendRule.rule, dispatch]);

  return (
    <Route
      {...rest}
      render={() => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: `/${ROUTES_PATHS.login}`,
              }}
            />
          );
        }

        // check if we have cached frontend rule
        if (frontendRule && !hasRule(user, frontendRule)) {
          // show error toast
          toast.error(labels.errors.access);
          return <Redirect to={`/${ROUTES_PATHS.home}`} />;
        }

        // if backend rule is specified and we got error on requesting in
        if (backendRule.rule && !backendRule.optional && backendError) {
          // show error toast
          toast.error(labels.errors.access);
          return <Redirect to={`/${ROUTES_PATHS.home}`} />;
        }

        return children;
      }}
    />
  );
};

export default AuthenticatedRoute;
