import React, { useCallback, useMemo, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Offer from "./Offer";
import SignUp from "./SignUp";
import PurchaseLayout from "./styled/Purchase";
import Thanks from "./Thanks";
import Login from "./Login";
import Error from "./Error";
import Payment from "./Payment";
import { startView, Views } from "./constants";
import { useQuery } from "../../utils/location";
import { useSelector } from "react-redux";
import { selectIsAuthLoading } from "../../store/ducks/auth/selectors";
import {
  selectIsCurrentLoading,
  selectIsPurchaseLoading,
} from "../../store/ducks/products/selectors";
import Loader from "../../components/Loader";

export const getStartView = (state: string | null) =>
  (state && startView[state]) || Views.offer;

const Purchase = () => {
  const query = useQuery();

  const isCurrentLoading = useSelector(selectIsCurrentLoading);
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const isPurchaseLoading = useSelector(selectIsPurchaseLoading);
  const isLoading = isCurrentLoading || isAuthLoading || isPurchaseLoading;

  const [view, setView] = useState<Views>(getStartView(query.get("state")));
  const [email, setEmail] = useState<string | null>(query.get("email"));
  const [error, setError] = useState<string | null>(null);
  const [forwardTo, setForwardTo] = useState<Views | undefined>(undefined);
  const [productId, setProductId] = useState<string | undefined>(undefined);

  const getProductId = () => {
    const productId = query.get("productId");
    if (!productId) {
      setView(Views.error);
      return;
    }
    return productId;
  };

  useMemo(() => {
    setProductId(getProductId());
  }, []);

  const goToStart = useCallback(() => {
    setEmail(null);
    setError(null);
    setView(Views.offer);
  }, [setEmail, setError, setView, Views]);

  const goToError = useCallback(() => {
    setView(Views.error);
  }, [setView, Views]);

  const goToPayment = useCallback(() => {
    setView(Views.payment);
  }, [setView, Views]);

  const goToThanks = useCallback(() => {
    setView(Views.thanks);
  }, [setView, Views]);

  const goToLogin = useCallback(() => {
    setView(Views.login);
  }, [setView, Views]);

  const goToSignUp = useCallback(() => {
    setView(Views.signUp);
  }, [setView, Views]);

  return (
    <Loader isLoading={isLoading} text="loading.">
      <PurchaseLayout>
        <SwipeableViews axis="x" index={view}>
          <Offer
            view={view}
            productId={productId!}
            setForwardTo={setForwardTo}
            openSignUp={goToSignUp}
            openLogin={goToLogin}
          />
          <SignUp
            view={view}
            goToStart={goToStart}
            forwardTo={forwardTo}
            setEmail={setEmail}
            openThanks={goToThanks}
            openLogin={goToLogin}
          />
          <Login
            view={view}
            email={email}
            goToStart={goToStart}
            goToError={goToError}
            goToPayment={goToPayment}
            setError={setError}
          />
          <Payment
            view={view}
            goToStart={goToStart}
            productId={productId!}
            openThanks={goToThanks}
          />
          <Thanks />
          <Error error={error} />
        </SwipeableViews>
      </PurchaseLayout>
    </Loader>
  );
};

export default Purchase;
