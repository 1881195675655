import React from "react";
import Autocomplete from "./Form/Autocomplete";
import {
  selectIsListLoading,
  selectList,
} from "../store/ducks/languages/selectors";
import { fetchList } from "../store/ducks/languages";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const SelectLanguage = (params: Props) => {
  return (
    <Autocomplete
      {...params}
      selectList={selectList}
      selectIsListLoading={selectIsListLoading}
      fetchList={fetchList}
      labelField="languageName"
      valueField="languageName"
      filterField="name"
    />
  );
};

export default SelectLanguage;
