import labels from "../../utils/labels";
import { theme } from "../../styled";

export const usersOverviewSearchOptions = [
  { label: "...in Firstname", value: "firstname" },
  { label: "...in Lastname", value: "lastname" },
  { label: "...in Email", value: "email" },
];

export const usersOverviewTableHeaders = [
  { field: "firstname", label: labels.sessions.firstname },
  { field: "lastname", label: labels.sessions.lastname },
  { field: "email", label: labels.sessions.email },
  { field: "sessions", label: labels.sessions.sessions },
  { field: "sessionLength", label: labels.sessions.sessionLength },
  { field: "avgTime", label: labels.sessions.avgTime },
  { field: "sessionName", label: labels.sessions.sessionName },
  { field: "sessionTime", label: labels.sessions.sessionTime },
];

export const datasetOptions = {
  fillColor: theme.color.blue,
  borderColor: "none",
  backgroundColor: theme.color.blue,
};

export const chartOptions = {
  tooltips: { enabled: false },
  hover: { mode: null },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

export const sessionHistoryOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
        },
      },
    ],
  },
};

export const numberSamplesList = [1000, 2000, 5000, 10000] as const;
export const numberSamplesListDefaultElement = numberSamplesList[1];
