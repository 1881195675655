import React, { useState } from "react";
import Icon from "../../styled/Icon";
import {
  faFilter,
  faChevronRight,
  faChevronDown,
  faGlobe,
  faThLarge,
  faFolderOpen,
  faStar,
  faAlignCenter,
  faRetweet,
  faClock,
  faMoneyBill,
  faSortAmountUp,
  faSearch,
  faLevelUpAlt,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Collapse, FormControl } from "@material-ui/core";
import StyledWrapper from "../../components/Form/styled/StyledWrapper";
import Input from "../../components/Form/Input";
import { Form } from "react-final-form";
import Checkbox from "../../components/Form/Checkbox";
import ItemWrapper from "./styled/Common/ItemWrapper";
import Slider from "../../styled/Slider";
import Select from "../../components/Form/Select";
import { FieldSetting, prepareFields } from "../../utils/form";
import { useSelector } from "react-redux";
import Button from "../../styled/Button";
import Panel from "./styled/Common/Panel";
import muse from "../../images/muse.png";
import gsr from "../../images/gsr.png";
import audio from "../../images/audio.png";
import subpac from "../../images/subpac.png";
import hrm from "../../images/hrm.png";
import ImageWrapper from "./styled/Common/ImageWrapper";
import SelectWrapper from "./styled/Common/SelectWrapper";
import { fetchItems } from "../../store/ducks/files";
import { DefaultFilteringParams } from "../../store/ducks/files/types";
import { useAppDispatch } from "../../store";
import {
  selectListPagination,
  selectIsListLoading,
  selectIsFileLoading,
  selectIsItemMetaLoading,
} from "../../store/ducks/files/selectors";
import FilesystemWrapper from "./styled/Common/Filesystem";
import Loader from "../../components/Loader";
import { sortingKeyOptions, sortingOptions } from "./constants";
import FileFolderEditor from "./FileFolderEditor";
import RadioButton from "../../components/Form/RadioButton";
import { durationValueLabel } from "./helpers";
import labels from "../../utils/labels";
import TreeTable from "./FileTree";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import SelectCountry from "../../components/SelectCountry";
import SelectLanguage from "../../components/SelectLanguage";
import Grid from "../../styled/Grid";

export interface SelectedItem {
  id: string;
  type: string;
  name: string;
}

const Filesystem = () => {
  const dispatch = useAppDispatch();
  const pagination = useSelector(selectListPagination);
  const isListLoading = useSelector(selectIsListLoading);
  const isFileLoading = useSelector(selectIsFileLoading);
  const isItemMetaLoading = useSelector(selectIsItemMetaLoading);
  const isLoading = isListLoading || isFileLoading || isItemMetaLoading;

  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);

  const [search, setSearch] = useState({
    folder: true,
    experience: true,
    classifier: true,
    statemachine: true,
    priceValue: [0, 50],
    durationValue: 0,
    price: false,
    duration: false,
    ...DefaultFilteringParams,
  });

  const getSearch = () => prepareFilters(search);

  const onSubmitSearch = (values: any) => {
    setSearch(values);
    dispatch(
      fetchItems({
        pagination: pagination,
        filtering: prepareFilters(values),
      })
    );
  };

  const prepareFilters = (values: any) => {
    let filters: any = {
      name: values.name,
      sort: values.sort,
      sortKey: values.sortKey,
    };

    if (values.price) {
      filters.priceMin = priceValue[0];
      filters.priceMax = priceValue[1];
    }

    let itemTypes = [];
    if (values.folder) {
      itemTypes.push("folder");
    }
    if (values.experience) {
      itemTypes.push("experience");
    }
    if (values.statemachine) {
      itemTypes.push("statemachine");
    }
    if (values.classifier) {
      itemTypes.push("classifier");
    }

    filters.itemTypes = itemTypes.length ? `[${itemTypes.join()}]` : undefined;

    let tags = [];
    if (values.muse) {
      tags.push("DEVICE:muse");
    }
    if (values.gsr) {
      tags.push("DEVICE:gsr");
    }
    if (values.audio) {
      tags.push("DEVICE:audio");
    }
    if (values.subpac) {
      tags.push("DEVICE:subpac");
    }
    if (values.hrm) {
      tags.push("DEVICE:hrm");
    }

    if (values.duration) {
      tags.push(`DURATION:${durationValueLabel(durationValue)}`);
    }

    if (values.country) {
      tags.push(`COUNTRY:${values.country}`);
    }
    if (values.language) {
      tags.push(`LANGUAGE:${values.language}`);
    }

    filters.tags = tags.length ? `[${tags.join()}]` : undefined;

    return filters;
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [durationValue, setDuration] = useState(0);
  const [priceValue, setPrice] = useState([0, 50]);

  const handleDurationChange = (event: any, newValue: any) => {
    setDuration(newValue);
  };

  const handlePriceChange = (event: any, newValue: any) => {
    setPrice(newValue);
  };

  const getFileSystem = (itemId: string | null) => {
    setSelectedItem(null);
    dispatch(
      fetchItems({
        pagination: {
          pageIndex: 0,
        },
        filtering: {
          ...getSearch(),
          itemId: itemId,
        },
      })
    );
  };

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      name: { disabled: false },
      country: { disabled: false },
      language: { disabled: false },
      sort: { disabled: false },
      sortKey: { disabled: false },
      itemTypes: { disabled: false },
    },
    undefined,
    labels.files
  );

  return (
    <Loader isLoading={isLoading} text="loading data.">
      <AuthWrapper>
        <FilesystemWrapper>
          <Grid container direction="row" justify="center">
            <Grid
              left="true"
              border="true"
              container
              direction="column"
              justify="center"
            >
              <h3>
                <Icon icon={faFilter} size="lg" />
                Filter
                <Tooltip title={isOpen ? "Collapse" : "Expand"} placement="top">
                  <Icon
                    section="true"
                    onClick={toggle}
                    blue="true"
                    icon={isOpen ? faChevronRight : faChevronDown}
                    size="lg"
                  />
                </Tooltip>
              </h3>
              <Grid
                collapse="true"
                container
                item
                spacing={2}
                direction="column"
              >
                <Panel left="true">
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Form
                      initialValues={search}
                      onSubmit={onSubmitSearch}
                      render={({ handleSubmit, values }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <Grid item xs={4}>
                              <StyledWrapper>
                                <Input {...fields.name} />
                              </StyledWrapper>
                            </Grid>
                            <ItemWrapper>
                              <Icon icon={faGlobe} size="lg" />
                              Locale
                            </ItemWrapper>
                            <Grid container item xs={12} spacing={3}>
                              <Grid item xs={4}>
                                <SelectCountry {...fields.country} />{" "}
                              </Grid>
                              <Grid item xs={4}>
                                <SelectLanguage {...fields.language} />
                              </Grid>
                            </Grid>

                            <ItemWrapper>
                              <Icon icon={faThLarge} size="lg" />
                              Items
                            </ItemWrapper>
                            <Grid container item xs={12} spacing={3}>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="folder" />
                                  <Icon icon={faFolderOpen} size="lg" />
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="experience" />
                                  <Icon icon={faStar} size="lg" />
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="classifier" />
                                  <Icon icon={faAlignCenter} size="lg" />
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="statemachine" />
                                  <Icon icon={faRetweet} size="lg" />
                                </ItemWrapper>
                              </Grid>
                            </Grid>

                            <ItemWrapper>
                              <Icon icon={faHeadphones} size="lg" />
                              {labels.files.devices}
                            </ItemWrapper>

                            <Grid container item xs={12} spacing={3}>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="muse" />
                                  <ImageWrapper small="true">
                                    <img src={muse} />
                                  </ImageWrapper>
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="gsr" />
                                  <ImageWrapper small="true">
                                    <img src={gsr} />
                                  </ImageWrapper>
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="audio" />
                                  <ImageWrapper small="true">
                                    <img src={audio} />
                                  </ImageWrapper>
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="subpac" />
                                  <ImageWrapper small="true">
                                    <img src={subpac} />
                                  </ImageWrapper>
                                </ItemWrapper>
                              </Grid>
                              <Grid item>
                                <ItemWrapper>
                                  <Checkbox name="hrm" />
                                  <ImageWrapper small="true">
                                    <img src={hrm} />
                                  </ImageWrapper>
                                </ItemWrapper>
                              </Grid>
                            </Grid>

                            <ItemWrapper>
                              <Icon icon={faClock} size="lg" />
                              {labels.files.durationMin}
                            </ItemWrapper>
                            <Grid container item xs={12} spacing={3}>
                              <FormControl>
                                <Grid item>
                                  <ItemWrapper>
                                    <Checkbox name="duration" />
                                    <Slider
                                      value={durationValue}
                                      step={1}
                                      min={0}
                                      max={5}
                                      valueLabelFormat={durationValueLabel}
                                      onChange={handleDurationChange}
                                      valueLabelDisplay="auto"
                                      disabled={!values.duration}
                                    />
                                  </ItemWrapper>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <ItemWrapper>
                              <Icon icon={faMoneyBill} size="lg" />
                              {labels.files.priceNeuronium}
                            </ItemWrapper>
                            <Grid container item xs={12} spacing={3}>
                              <FormControl>
                                <Grid item>
                                  <ItemWrapper>
                                    <Checkbox name="price" />
                                    <Slider
                                      value={priceValue}
                                      step={1}
                                      min={0}
                                      max={50}
                                      onChange={handlePriceChange}
                                      valueLabelDisplay="auto"
                                      disabled={!values.price}
                                    />
                                  </ItemWrapper>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              spacing={3}
                              direction="row"
                              alignItems="center"
                            >
                              <Grid item>
                                <ItemWrapper>
                                  <Icon icon={faSortAmountUp} size="lg" />
                                  Sorting
                                </ItemWrapper>
                                <Grid item>
                                  <SelectWrapper>
                                    <Select
                                      {...fields.sortKey}
                                      options={sortingKeyOptions}
                                    />
                                  </SelectWrapper>
                                  <RadioButton
                                    name="sort"
                                    options={sortingOptions}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                              <Grid item xs={4}>
                                <ItemWrapper>
                                  <Button blue="true" type="submit">
                                    <Icon icon={faSearch} size="lg" />
                                    Search
                                  </Button>

                                  <Button
                                    blue="true"
                                    onClick={() => getFileSystem(null)}
                                  >
                                    <Icon icon={faLevelUpAlt} size="lg" />
                                    Root
                                  </Button>
                                </ItemWrapper>
                              </Grid>
                            </Grid>
                          </form>
                        </>
                      )}
                    />
                  </Collapse>
                </Panel>
              </Grid>
              <Panel left="true">
                <TreeTable
                  getSearch={getSearch}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </Panel>
            </Grid>
            <Grid
              right="true"
              container
              item
              xs={12}
              spacing={2}
              direction="row"
            >
              <FileFolderEditor selectedItem={selectedItem} />
            </Grid>
          </Grid>
        </FilesystemWrapper>
      </AuthWrapper>
    </Loader>
  );
};

export default Filesystem;
