import styled from "styled-components";

const AuthLayout = styled.article`
  display: flex;
  flex-direction: column;

  padding-top: 7%;
  min-height: 100vh;

  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    flex: 1 1 60%;
    max-width: 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    flex: 1 1 40%;
    max-width: 40%;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.large}) {
    flex: 1 1 20%;
    max-width: 20%;
  }
`;

export default AuthLayout;
