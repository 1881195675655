import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LogRecord,
  State,
  DefaultPaginationParams,
  DefaultFilteringParams,
  FilteringParams,
} from "./types";
import { Pagination } from "../../../types/pagination";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    list: null,
  },
  loading: {
    list: false,
  },
  error: {
    list: null,
  },
  pagination: {
    list: DefaultPaginationParams,
  },
  filtering: {
    list: DefaultFilteringParams,
  },
};

const slice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    fetchList: (state, action: PayloadAction<{}>) => {
      state.data.list = null;
      state.loading.list = true;
      state.error.list = null;
    },
    fetchListSuccess: (
      state,
      action: PayloadAction<{
        data: Array<LogRecord>;
        pagination: Pagination;
        filtering: FilteringParams;
      }>
    ) => {
      state.data.list = action.payload.data;
      state.loading.list = false;
      state.pagination.list = action.payload.pagination;
      state.filtering.list = action.payload.filtering;
    },
    fetchListError: (state, action: PayloadAction<RequestError>) => {
      state.error.list = action.payload.message;
      state.loading.list = false;
    },
  },
});

export const { fetchList, fetchListSuccess, fetchListError } = slice.actions;

export default slice.reducer;
