import styled from "styled-components";
import Iframe from "react-iframe";

const Frame = styled(Iframe)`
  && {
    width: calc(100% + 60px);
    min-height: 100vh;
    margin: -30px;
  }
`;

export default Frame;
