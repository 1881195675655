import styled from "styled-components";
import { Radio as MatRadio } from "@material-ui/core/";

const Radio = styled(MatRadio)`
  && {
    .MuiIconButton-label {
      color: ${(props) => props.theme.color.yellow};
    }
  }
`;

export default Radio;
