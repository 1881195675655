import { RootState } from "../..";

export const selectFile = (state: RootState) => state.files.data.file;

export const selectIsFileLoading = (state: RootState) =>
  state.files.loading.file;

export const selectItemMeta = (state: RootState) => state.files.data.itemMeta;

export const selectIsItemMetaLoading = (state: RootState) =>
  state.files.loading.itemMeta;

export const selectParentItems = (state: RootState) =>
  state.files.data.parentItems;

export const selectChildItems = (state: RootState) =>
  state.files.data.childItems;

export const selectIsListLoading = (state: RootState) =>
  state.files.loading.items;

export const selectListPagination = (state: RootState) =>
  state.files.pagination.items;

export const selectListFiltering = (state: RootState) =>
  state.files.filtering.items;
