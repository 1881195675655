import { Base64 } from "js-base64";
import { FileType } from "./types";

export const classifierDefaultBody = {
  content: Base64.encode("{}"),
  type: FileType.CLASSIFIER,
};

export const classifierDefaultDependency = {
  name: "Classifier UUID",
  internalName: "classifierUuid",
  description: "The UUID of the classifier that the experience uses.",
  interfaceType: 11,
  isEnabled: true,
  isVisible: false,
  default: "",
};

export const stateMachineDefaultBody = {
  content: Base64.encode("{}"),
  type: FileType.STATEMACHINE,
};

export const stateMachineDefaultDependency = {
  name: "State Machine UUID",
  internalName: "stateMachineUuid",
  description: "The UUID of the state machine that the experience uses.",
  interfaceType: 11,
  isEnabled: true,
  isVisible: false,
  default: "",
};

export const experienceDefaultBody = {
  type: FileType.EXPERIENCE,
};

export const defaultMode = "store";
