import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

import { ROUTES_PATHS } from "../../Routes";
import { faRetweet, faTimes } from "@fortawesome/free-solid-svg-icons";
import AuthLayout from "../../styled/AuthLayout";
import Loader from "../../components/Loader";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import Link from "../../styled/Link";
import Logo from "../../components/Logo";
import Options from "./styled/Options";
import Input from "../../components/Form/Input";
import Title from "./styled/Title";
import Paragraph from "./styled/Paragraph";
import { requestResetPassword } from "../../store/ducks/auth";
import { useAppDispatch } from "../../store";
import { RequestResetPasswordData } from "../../store/ducks/auth/types";
import { selectIsAuthLoading } from "../../store/ducks/auth/selectors";
import labels from "../../utils/labels";
import { email } from "../../utils/form";

const RequestResetPassword = () => {
  const dispatch = useAppDispatch();
  const isAuthLoading = useSelector(selectIsAuthLoading);

  const onSubmit = (values: RequestResetPasswordData) =>
    new Promise((resolve, reject) => {
      dispatch(requestResetPassword(values, { resolve, reject }));
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });

  return (
    <Loader
      absolute="true"
      isLoading={isAuthLoading}
      text={labels.loading.sendingEmail}
    >
      <AuthLayout>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <>
              <Logo />
              <Title>{labels.auth.resetPassword}</Title>
              <Paragraph>{labels.auth.resetPasswordDescription}</Paragraph>
              <br />
              <form onSubmit={handleSubmit}>
                <Input
                  name="email"
                  label={labels.auth.email}
                  type="email"
                  required
                  validation={email}
                  auth
                />
                <br />
                <br />
                <Options>
                  <Button blue="true" type="submit" disabled={submitting}>
                    <Icon icon={faRetweet} size="lg" />
                    {labels.button.emailMe}
                  </Button>
                  <Link to={`/${ROUTES_PATHS.login}`}>
                    <Button gray type="button" disabled={submitting}>
                      <Icon icon={faTimes} size="lg" />
                      {labels.button.cancel}
                    </Button>
                  </Link>
                </Options>
              </form>
            </>
          )}
        />
      </AuthLayout>
    </Loader>
  );
};

export default RequestResetPassword;
