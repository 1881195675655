import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectList,
  selectListPagination,
  selectIsListLoading,
} from "../../store/ducks/logs/selectors";
import {
  FilteringParams,
  DefaultPaginationParams,
  DefaultFilteringParams,
} from "../../store/ducks/logs/types";
import { useAppDispatch } from "../../store";
import { fetchList } from "../../store/ducks/logs";
import { selectCurrent as selectRule } from "../../store/ducks/rule/selectors";
import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";
import { Form } from "react-final-form";
import Input from "../../components/Form/Input";
import Button from "../Login/styled/Button";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import { endOfDay, getDate, getTime, startOfDay } from "../../utils/datetime";
import Loader from "../../components/Loader";
import { selectUser } from "../../store/ducks/auth/selectors";
import Select from "../../components/Form/Select";
import DatePicker from "../../components/Form/DatePicker";
import MonitoringTable from "../../components/Monitoring";
import {
  emitterNameOptions,
  logLevelOptions,
  systemNameOptions,
} from "../../components/Monitoring/constants";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import CLink from "../../styled/CSVLink";
import Grid from "../../styled/Grid";

const columns = [
  { header: labels.logs.date, field: "logTime", mapper: getDate },
  { header: labels.logs.time, field: "logTime", mapper: getTime },
  { header: labels.logs.user, field: "userId" },
  { header: labels.logs.systemName, field: "systemName" },
  { header: labels.logs.emitterName, field: "emitterName" },
  { header: labels.logs.message, field: "message" },
  { header: labels.logs.level, field: "logLevel" },
  { header: labels.logs.address, field: "ipAddress" },
];

const Logs = () => {
  const dispatch = useAppDispatch();

  const auth = useSelector(selectUser)!;
  const rule = useSelector(selectRule)!;
  const logs = useSelector(selectList);
  const pagination = useSelector(selectListPagination);
  const isListLoading = useSelector(selectIsListLoading);

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      userId: {},
      logLevel: {},
      startDateTime: {},
      endDateTime: {},
      emitterName: {},
      systemName: {},
    },
    rule,
    labels.logs
  );

  const [search, setSearch] = useState<any>(DefaultFilteringParams);

  const getSearch = () => search;

  const onSubmitSearch = (filtering: FilteringParams) => {
    const data = {
      userId:
        filtering.userId || (fields.userId.required ? auth.userId : undefined),
      startDateTime: filtering.startDateTime
        ? startOfDay(filtering.startDateTime)
        : undefined,
      endDateTime: filtering.endDateTime
        ? endOfDay(filtering.endDateTime)
        : undefined,
      logLevel: filtering.logLevel || undefined,
      systemName: filtering.systemName || undefined,
      emitterName: filtering.emitterName || undefined,
    };
    setSearch(data);
    dispatch(
      fetchList({
        pagination: {
          pageIndex: 0,
        },
        filtering: data,
      })
    );
  };

  return (
    <Loader isLoading={isListLoading} text={labels.loading.common}>
      <AuthWrapper>
        <Form
          initialValues={{}}
          onSubmit={onSubmitSearch}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={2}>
                  <Input {...fields.userId} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <Select options={logLevelOptions} {...fields.logLevel} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <Select options={systemNameOptions} {...fields.systemName} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <Select
                    options={emitterNameOptions}
                    {...fields.emitterName}
                  />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <DatePicker {...fields.startDateTime} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <DatePicker {...fields.endDateTime} />
                </Grid>
              </Grid>

              <br />
              <Grid container spacing={3}>
                <Grid item>
                  <Button blue="true" type="submit">
                    <SearchIcon />
                    {labels.button.search}
                  </Button>
                </Grid>
                <Grid container item end="true">
                  {logs && (
                    <CLink data={logs}>
                      <Button blue="true">
                        <GetAppIcon /> {labels.button.csv}
                      </Button>
                    </CLink>
                  )}
                </Grid>
              </Grid>
              <br />
            </form>
          )}
        />
        <MonitoringTable
          columns={columns}
          idField="logTime"
          selectList={selectList}
          selectListPagination={selectListPagination}
          fetchList={fetchList}
          defaultPaginationParams={DefaultPaginationParams}
          defaultFilteringParams={DefaultFilteringParams}
          getSearch={getSearch}
        />
      </AuthWrapper>
    </Loader>
  );
};

export default Logs;
