import styled from "styled-components";
import { Link as linkDom } from "react-router-dom";

interface Props {
  // https://github.com/styled-components/styled-components/issues/1198#issuecomment-524024001
  white?: "true";
  arrow?: "true";
}

const Link = styled(linkDom)<Props>`
  color: ${(props) => props.theme.color.blue};
  text-decoration: none;
  cursor: pointer;

  ${(props) =>
    props.arrow &&
    `
    margin-left: auto;
  `}

  ${(props) =>
    props.white &&
    `
    color: ${props.theme.color.white};
    :hover {
      color: ${props.theme.color.blue};
    }
  `}
`;

export default Link;
