import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { Pagination } from "../../types/pagination";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableWrapper from "../../styled/Table/TableWrapper";

interface TableColumn {
  header: string;
  field: string;
  defaultValue?: any;
  mapper?: (value: any) => any;
}

interface Props {
  columns: TableColumn[];
  idField: string;
  selectList: (state: RootState) => any[] | null;
  selectListPagination: (state: RootState) => any | null;
  fetchList: ActionCreatorWithPayload<{}, string>;
  defaultPaginationParams: Pagination;
  defaultFilteringParams: any;
  getSearch: () => any;
}

const MonitoringTable = ({
  columns,
  idField,
  selectList,
  selectListPagination,
  fetchList,
  defaultPaginationParams,
  defaultFilteringParams,
  getSearch,
}: Props) => {
  const dispatch = useAppDispatch();

  const list = useSelector(selectList);
  const pagination = useSelector(selectListPagination);

  useEffect(() => {
    dispatch(
      fetchList({
        pagination: defaultPaginationParams,
        filtering: defaultFilteringParams,
      })
    );
  }, [dispatch, fetchList, defaultFilteringParams, defaultPaginationParams]);

  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      fetchList({
        pagination: {
          pageIndex: newPage,
        },
        filtering: getSearch(),
      })
    );
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const pageSize = parseInt((event.target as HTMLSelectElement).value, 10);
    dispatch(
      fetchList({
        pagination: {
          pageSize,
          pageIndex: 0,
        },
        filtering: getSearch(),
      })
    );
  };

  return (
    <>
      <TableWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((item) => (
                <TableCell>{item.header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.map((item) => (
                <TableRow key={item[idField]}>
                  {columns.map((column) => (
                    <TableCell>
                      {(column.mapper
                        ? column.mapper(item[column.field])
                        : item[column.field]) || column.defaultValue}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={pagination.totalElements || 0}
                rowsPerPage={pagination.pageSize || 0}
                page={pagination.pageIndex || 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableWrapper>
    </>
  );
};

export default MonitoringTable;
