export interface Pagination {
  pageIndex?: number;
  pageSize?: number;
  pageElements?: number;
  totalPages?: number;
  totalElements?: number;
}

export function mapPaginationFields(pagination: Pagination) {
  return {
    page: pagination.pageIndex || 0,
    size: pagination.pageSize,
  };
}
