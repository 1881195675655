import React from "react";
import { Grid } from "@material-ui/core";

interface Props {
  error: string | null;
}

const Error = ({ error }: Props) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <h2>Sorry we can not proceed your request.</h2>
      </Grid>
      <Grid item>
        <p>{error || ""}</p>
      </Grid>
      <br />
      <br />
      <Grid item>
        <p>
          Nothing is charged from your credit card due to this error. If this
          error occurs multiple times please contact our support team.
        </p>
      </Grid>
    </Grid>
  );
};

export default Error;
