import React, { useState } from "react";
import { Field } from "react-final-form";

import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import MatInput from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import StyledWrapper from "./styled/StyledWrapper";
import {
  maxLength,
  minLength,
  password,
  required as checkRequired,
} from "../../utils/form";

interface Props {
  label: string;
  required?: boolean;
  disabled?: boolean;
  auth?: boolean;
  name: string;
  extendedValidation?: boolean;
}

const Input = ({
  label,
  required,
  disabled,
  auth,
  name,
  extendedValidation,
}: Props) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <Field
      name={name}
      validate={(value: string) =>
        (required ? checkRequired(value) : undefined) ||
        (extendedValidation
          ? password(value) || minLength(value, 6) || maxLength(value, 20)
          : undefined)
      }
    >
      {({ input, meta }) => (
        <StyledWrapper auth={auth}>
          <FormControl>
            <InputLabel required={required} error={meta.touched && meta.error}>
              {label}
            </InputLabel>
            <MatInput
              type={passwordShown ? "text" : "password"}
              required={required}
              disabled={disabled}
              {...input}
              endAdornment={
                !disabled && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <FormHelperText error>
              {meta.touched && meta.error ? meta.error : " "}
            </FormHelperText>
          </FormControl>
        </StyledWrapper>
      )}
    </Field>
  );
};

export default Input;
