import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all } from "redux-saga/effects";

import {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,
  purchase,
  purchaseSuccess,
  purchaseError,
} from ".";
import { Product } from "./types";
import { GET, POST, Response } from "../../../utils/request";
import toast from "../../../components/Toaster";
import { PaymentData } from "../../../pages/Purchase/Payment";
import { FormSubmissionMeta } from "../../../types";

const path = `/api/store/products`;
const api = {
  single: (product: string) => `${path}/${product}/get`,
  purchase: () => `/api/store/stripe/purchase`,
};

function* fetchCurrentSaga(action: PayloadAction<string>) {
  try {
    const response: Response<{ products: Product[] }> = yield GET(
      api.single(action.payload)
    );

    if (response.data.products.length !== 1) {
      throw Error("Product not found.");
    }

    yield put(fetchCurrentSuccess(response.data.products[0]));
  } catch (error) {
    yield put(fetchCurrentError(error));
    toast.error(error.message);
  }
}

function* purchaseSaga(
  action: PayloadAction<PaymentData, string, FormSubmissionMeta>
) {
  try {
    yield POST(api.purchase(), action.payload);

    yield put(purchaseSuccess({}, action.meta));
  } catch (error) {
    yield put(purchaseError(error.message, action.meta));
    toast.error(error.message);
  }
}

function* fetchCurrentWatcher() {
  yield takeLatest(fetchCurrent.type, fetchCurrentSaga);
}

function* purchaseWatcher() {
  yield takeLatest(purchase.type, purchaseSaga);
}

export function* rootWatcher() {
  yield all([fork(fetchCurrentWatcher), fork(purchaseWatcher)]);
}

export default rootWatcher;
