import styled from "styled-components";
import { CircularProgress as MatProgress } from "@material-ui/core/";

const CircularProgress = styled(MatProgress)`
  && {
    color: ${(props) => props.theme.color.blue};
    @media (min-width: ${(props) => props.theme.breakpoint.mobile}) {
      .loading-circle {
        margin-left: -112px;
      }
    }
  }
`;

export default CircularProgress;
