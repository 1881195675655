import React, { useState } from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import MenuIcon from "@material-ui/icons/Menu";
import Wrapper from "./styled/Wrapper";
import { Tooltip } from "@material-ui/core";

export interface Action {
  name: string;
  icon: React.ReactNode;
  handler: () => void;
}

interface Props {
  actions: Action[];
}

const SpeedDials = ({ actions }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Wrapper>
      <Tooltip title="Options" placement="top-end">
        <SpeedDial
          ariaLabel="SpeedDial"
          icon={<MenuIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="left"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.handler}
            />
          ))}
        </SpeedDial>
      </Tooltip>
    </Wrapper>
  );
};

export default SpeedDials;
