import { Role } from "../types";

export const defaultUserRoles = [
  Role.Admin,
  Role.AppSubscription,
  Role.BiofeedbackProvider,
  Role.BiofeedbackUser,
  Role.ClinicAdmin,
  Role.ClinicClinician,
  Role.ClinicOperator,
  Role.ClinicPatient,
  Role.Community,
  Role.Professional,
  Role.ResellerAdmin,
  Role.Ultimate,
];
