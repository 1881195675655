import React from "react";
import { numberSamplesList } from "../constants";
import labels from "../../../utils/labels";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { NumberSamplesListElement } from "../types";
import config from "config";

type Props = {
  onChange: (value: NumberSamplesListElement) => void;
  value: NumberSamplesListElement;
};

const NumberSamplesSelect = ({ onChange, value }: Props) => {
  if (config.sessions?.hideMaxRenderSamplesSelection) return null;

  return (
    <section>
      <InputLabel shrink>{labels.sessions.maxRenderSamples}</InputLabel>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value as NumberSamplesListElement)}
      >
        {numberSamplesList.map((numberSamples) => (
          <MenuItem key={numberSamples} value={numberSamples}>
            {numberSamples}
          </MenuItem>
        ))}
      </Select>
    </section>
  );
};

export default NumberSamplesSelect;
