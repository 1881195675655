import React from "react";
import { useAppDispatch } from "../../store";
import { fetchList, delete_ } from "../../store/ducks/groups";
import {
  selectList,
  selectListPagination,
  selectIsListLoading,
} from "../../store/ducks/groups/selectors";
import {
  DefaultFilteringParams,
  DefaultPaginationParams,
} from "../../store/ducks/groups/types";
import { useHistory } from "react-router-dom";
import { ROUTES_PATHS } from "../../Routes";
import CRMTable from "../../components/CRM";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import labels from "../../utils/labels";

const columns = [
  { header: labels.group.groupId, field: "groupId", filterField: "idFilter" },
  { header: labels.group.groupName, field: "name" },
  { header: labels.group.members, field: "members", defaultValue: 0 },
];

const ListGroups = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useSelector(selectUser)!;

  const showCreateButton = hasRule(user, RULES.REST_createGroup);
  const showShowButton = hasRule(user, RULES.REST_getGroup);
  const showDeleteButton = hasRule(user, RULES.REST_deleteGroup);

  const createGroup = () => {
    return history.push(`/${ROUTES_PATHS.createGroup}`);
  };

  const showGroup = (id: string) => {
    return history.push(`/${ROUTES_PATHS.group}/${id}`);
  };

  const deleteGroup = (id: string) => {
    dispatch(delete_(id));
  };

  return (
    <CRMTable
      columns={columns}
      idField="groupId"
      selectList={selectList}
      selectListPagination={selectListPagination}
      selectIsListLoading={selectIsListLoading}
      fetchList={fetchList}
      defaultPaginationParams={DefaultPaginationParams}
      defaultFilteringParams={DefaultFilteringParams}
      createButtonLabel={labels.group.create}
      showCreateButton={showCreateButton}
      onCreate={createGroup}
      showShowButton={showShowButton}
      onShow={showGroup}
      showDeleteButton={showDeleteButton}
      onDelete={deleteGroup}
    />
  );
};

export default ListGroups;
