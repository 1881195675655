import styled from "styled-components";
import { Grid as MatGrid } from "@material-ui/core";

interface Props {
  left?: "true";
  right?: "true";
  border?: "true";
  collapse?: "true";
  group?: "true";
  end?: "true";
}
const Grid = styled(MatGrid)<Props>`
  && {
    
    flex-wrap: initial;
    height: 100%;

    ${(props) =>
      props.left &&
      `
      padding-right: 20px;
      justify-content: flex-start;
    `}

    ${(props) =>
      props.right &&
      `
      padding-left: 20px;
    `}

    ${(props) =>
      props.border &&
      `
      border-right: 1px solid ${props.theme.color.gray};
    `}

    ${(props) =>
      props.collapse &&
      `
      height: initial;
    `}

    
    ${(props) =>
      props.end &&
      `
      justify-content: flex-end;
    `}
  }
`;

export default Grid;
