import React from "react";
import SwipeableViews from "react-swipeable-views";
import TabPanel from "./TabPanel";
import Wrapper from "./styled/Wrapper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

interface Props {
  tabs: Array<{
    label: string;
    content: any;
  }>;
}

const TabsComponent = ({ tabs }: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: any) => {
    setValue(index);
  };

  return (
    <Wrapper>
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <SwipeableViews axis="x" index={value} onChangeIndex={handleChangeIndex}>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Wrapper>
  );
};

export default TabsComponent;
