import React, { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { Event } from "../../../store/ducks/events/types";
import MapWrapper from "./styled/MapWrapper";
import useSupercluster from "use-supercluster";

const defaultCenter = {
  lat: 30,
  lng: -30,
};
const defaultZoom = 1;

interface Props {
  events: Array<Event> | null;
}

const Map = ({ events }: Props) => {
  const mapRef = useRef<any>();
  const [bounds, setBounds] = useState<any>(null);
  const [zoom, setZoom] = useState(1);

  const points = events
    ? events
        .filter((event) => event.latitude && event.longitude)
        .map((event) => ({
          type: "Feature",
          properties: { cluster: false, id: event.timestamp },
          geometry: {
            type: "Point",
            coordinates: [event.longitude, event.latitude],
          },
        }))
    : [];

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const onClusterClick = (cluster: any) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    );

    const [longitude, latitude] = cluster.geometry.coordinates;
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({ lat: latitude, lng: longitude });
  };

  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCJeaIfjwMYZLZVJcQYZUczwtn6-YjAKZA" }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          return (
            <Marker
              key={cluster.id}
              lat={latitude}
              lng={longitude}
              cluster={cluster}
              onClusterClick={onClusterClick}
            />
          );
        })}
      </GoogleMapReact>
    </MapWrapper>
  );
};

export default React.memo(Map);
