import React from "react";
import { Switch, Route } from "react-router-dom";
import { RULES } from "./store/ducks/rule/types";
import config from "config";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import NotAuthenticatedRoute from "./components/NotAuthenticatedRoute";
import Navigation from "./components/MainLayout/Navigation";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import CreateCompany from "./pages/CreateCompany";
import UpdateCompany from "./pages/UpdateCompany";
import CreateGroup from "./pages/CreateGroup";
import UpdateGroup from "./pages/UpdateGroup";
import CreateUser from "./pages/CreateUser";
import Events from "./pages/Events";
import Logs from "./pages/Logs";
import Sessions from "./pages/Sessions";
import ListCompanies from "./pages/ListCompanies";
import ListUsers from "./pages/ListUsers";
import ListGroups from "./pages/ListGroups";
import RequestResetPassword from "./pages/RequestResetPassword";
import ResetPassword from "./pages/ResetPassword";
import Filesystem from "./pages/Filesystem";
import Main from "./components/MainLayout/styled/Main";
import MainContent from "./components/MainLayout/styled/MainContent";
import Purchase from "./pages/Purchase";
import Downloads from "./pages/Downloads";
import Documentation from "./pages/Documentation/Documentation";
import APIDocumentation from "./pages/APIDocumentation";
import SessionReport from "./pages/Sessions/SessionReport";
import NotFound from "./pages/NotFound";
import SignUpUser from "./pages/SignUp/SignUser";
import SignUpProvider from "./pages/SignUp/SignProvider";

export enum ROUTES_PATHS {
  login = "login",
  home = "home",
  profile = "profile",
  downloads = "download",
  filesystem = "filesystem",
  documentation = "documentation",
  apidocs = "swagger",
  requestPasswordReset = "resetrequest",
  companies = "companies",
  company = "company",
  createCompany = "createcompany",
  createGroup = "creategroup",
  groups = "groupoverview",
  group = "group",
  users = "users",
  user = "user",
  createUser = "createuser",
  events = "events",
  logs = "logs",
  sessions = "sessions",
  resetPassword = "resetpassword",
  purchase = "purchase",
  report = "report",
  getaccess = "getaccess",
  createprovider = "createprovider",
}

const Routes = () => (
  <Switch>
    <NotAuthenticatedRoute path={`/${ROUTES_PATHS.login}`}>
      <Login />
    </NotAuthenticatedRoute>

    <NotAuthenticatedRoute path={`/${ROUTES_PATHS.requestPasswordReset}`}>
      <RequestResetPassword />
    </NotAuthenticatedRoute>

    <NotAuthenticatedRoute path={`/${ROUTES_PATHS.resetPassword}`}>
      <ResetPassword />
    </NotAuthenticatedRoute>

    {Boolean(config.checkout) && (
      <NotAuthenticatedRoute path={`/${ROUTES_PATHS.purchase}`}>
        <Purchase />
      </NotAuthenticatedRoute>
    )}

    {Boolean(config.checkout) && (
      <NotAuthenticatedRoute path={`/${ROUTES_PATHS.getaccess}`}>
        <SignUpUser />
      </NotAuthenticatedRoute>
    )}

    {Boolean(config.checkout) && (
      <NotAuthenticatedRoute path={`/${ROUTES_PATHS.createprovider}`}>
        <SignUpProvider />
      </NotAuthenticatedRoute>
    )}

    <NotAuthenticatedRoute path={`/${ROUTES_PATHS.report}`}>
      <SessionReport />
    </NotAuthenticatedRoute>

    <Route path="/">
      <Main>
        <Navigation />
        <MainContent>
          <Switch>
            <AuthenticatedRoute path={`/${ROUTES_PATHS.home}`}>
              <Home />
            </AuthenticatedRoute>

            <AuthenticatedRoute path={`/${ROUTES_PATHS.documentation}`}>
              <Documentation />
            </AuthenticatedRoute>

            {Boolean(config.downloads) && (
              <AuthenticatedRoute path={`/${ROUTES_PATHS.downloads}`}>
                <Downloads />
              </AuthenticatedRoute>
            )}

            <AuthenticatedRoute path={`/${ROUTES_PATHS.apidocs}`}>
              <APIDocumentation />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.profile}`}
              frontendRule={RULES.FRONTEND_SectionShowUser}
              backendRule={{ rule: RULES.REST_updateUser, optional: true }}
            >
              <Profile />
            </AuthenticatedRoute>

            {Boolean(config.filesystem) && (
              <AuthenticatedRoute
                path={`/${ROUTES_PATHS.filesystem}`}
                frontendRule={RULES.FRONTEND_SectionItemEditor}
              >
                <Filesystem />
              </AuthenticatedRoute>
            )}

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.companies}`}
              frontendRule={RULES.FRONTEND_SectionShowCompanies}
            >
              <ListCompanies />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.createCompany}`}
              frontendRule={RULES.FRONTEND_SectionCreateCompany}
              backendRule={{ rule: RULES.REST_createCompany }}
            >
              <CreateCompany />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.company}/:id`}
              frontendRule={RULES.FRONTEND_SectionShowCompany}
              backendRule={{ rule: RULES.REST_updateCompany, optional: true }}
            >
              <UpdateCompany />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.groups}`}
              frontendRule={RULES.FRONTEND_SectionShowGroups}
            >
              <ListGroups />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.createGroup}`}
              frontendRule={RULES.FRONTEND_SectionCreateGroup}
              backendRule={{ rule: RULES.REST_createGroup }}
            >
              <CreateGroup />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.group}/:id`}
              frontendRule={RULES.FRONTEND_SectionShowGroup}
              backendRule={{ rule: RULES.REST_updateGroup, optional: true }}
            >
              <UpdateGroup />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.users}`}
              frontendRule={RULES.FRONTEND_SectionShowUsers}
            >
              <ListUsers />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.createUser}`}
              frontendRule={RULES.FRONTEND_SectionCreateUser}
              backendRule={{ rule: RULES.REST_createUser }}
            >
              <CreateUser />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.user}/:id`}
              frontendRule={RULES.FRONTEND_SectionShowUser}
              backendRule={{ rule: RULES.REST_updateUser, optional: true }}
            >
              <Profile />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.events}`}
              frontendRule={RULES.FRONTEND_SectionEvents}
              backendRule={{ rule: RULES.REST_findEvents, optional: true }}
            >
              <Events />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.logs}`}
              frontendRule={RULES.FRONTEND_SectionLogs}
              backendRule={{ rule: RULES.REST_findLogs, optional: true }}
            >
              <Logs />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path={`/${ROUTES_PATHS.sessions}`}
              frontendRule={RULES.FRONTEND_SectionSession}
            >
              <Sessions />
            </AuthenticatedRoute>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </MainContent>
      </Main>
    </Route>
  </Switch>
);

export default Routes;
