import { Pagination } from "../../../types/pagination";

export interface Event {
  systemName: string;
  emitterName: string;
  timestamp: string;
  userId: string;
  ipAddress: string;
  country: string;
  city: string;
  type: string;
  latitude: number;
  longitude: number;
}

export interface FilteringParams {
  systemName?: string;
  emitterName?: string;
  userId?: string;
  type?: string;
  country?: string;
  startDateTime?: Date;
  endDateTime?: Date;
}

export const DefaultFilteringParams = {};

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 50,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export interface State {
  data: {
    list: Array<Event> | null;
  };
  loading: {
    list: boolean;
  };
  error: {
    list: string | null;
  };
  pagination: {
    list: Pagination;
  };
  filtering: {
    list: FilteringParams;
  };
}
