import styled from "styled-components";

const Wrapper = styled.div`
  && .MuiSpeedDial-root {
    .MuiButtonBase-root {
      width: 36px;
      height: 36px;
      margin: 0 8px;
    }

    .MuiSpeedDialAction-fab,
    .MuiSpeedDial-fab {
      background: ${(props) => props.theme.color.gray};
      color: ${(props) => props.theme.color.white};
    }

    .MuiSpeedDial-actions {
      padding-right: 32px;
    }
  }
`;

export default Wrapper;
