import styled from "styled-components";
import Button from "../../../styled/Button";

const Options = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  ${Button} {
    font-family: inherit;
  }
`;

export default Options;
