import { RootState } from "../..";

export const selectCurrent = (state: RootState) => state.groups.data.current;

export const selectIsCurrentLoading = (state: RootState) =>
  state.groups.loading.current;

export const selectList = (state: RootState) => state.groups.data.list;

export const selectIsListLoading = (state: RootState) =>
  state.groups.loading.list;

export const selectListPagination = (state: RootState) =>
  state.groups.pagination.list;

export const selectListFiltering = (state: RootState) =>
  state.groups.filtering.list;

export const selectChildren = (state: RootState) => state.groups.data.children;

export const selectIsChildrenLoading = (state: RootState) =>
  state.groups.loading.children;

export const selectChildrenPagination = (state: RootState) =>
  state.groups.pagination.children;

export const selectChildrenFiltering = (state: RootState) =>
  state.groups.filtering.children;
