import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { useDebouncedCallback } from "use-debounce";
import TableWrapper from "../../styled/Table/TableWrapper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SimpleInput from "../../styled/SimpleInput";
import TableBody from "@material-ui/core/TableBody";
import SpeedDials from "../../components/SpeedDial";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Pagination } from "../../types/pagination";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import Loader from "../Loader";
import Wrapper from "../Tabs/styled/Wrapper";
import DeleteConfirmationDialog from "../ConfirmationDialog/DeleteConfirmationDialog";
import AuthWrapper from "../MainLayout/styled/AuthWrapper";
import {
  faSignInAlt,
  faExternalLinkAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import labels from "../../utils/labels";

export interface TableColumn {
  header: string;
  field: string;
  filterField?: string;
  defaultValue?: any;
}

interface Props {
  columns: TableColumn[];
  idField: string;
  selectList: (state: RootState) => any[] | null;
  selectListPagination: (state: RootState) => any | null;
  selectIsListLoading: (state: RootState) => any | null;
  fetchList: ActionCreatorWithPayload<{}, string>;
  defaultPaginationParams: Pagination;
  defaultFilteringParams: any;
  createButtonLabel: string;
  showCreateButton?: boolean;
  onCreate: () => void;
  showShowButton?: boolean;
  onShow: (id: string) => void;
  showDeleteButton?: boolean;
  onDelete: (id: string) => void;
}

const CRMTable = ({
  columns,
  idField,
  selectList,
  selectListPagination,
  selectIsListLoading,
  fetchList,
  defaultPaginationParams,
  defaultFilteringParams,
  createButtonLabel,
  showCreateButton,
  onCreate,
  showShowButton,
  onShow,
  showDeleteButton,
  onDelete,
}: Props) => {
  const dispatch = useAppDispatch();

  const list = useSelector(selectList);
  const pagination = useSelector(selectListPagination);
  const isListLoading = useSelector(selectIsListLoading);

  useEffect(() => {
    dispatch(
      fetchList({
        pagination: defaultPaginationParams,
        filtering: defaultFilteringParams,
      })
    );
  }, [dispatch, defaultFilteringParams, defaultPaginationParams, fetchList]);

  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      fetchList({
        pagination: {
          pageIndex: newPage,
        },
      })
    );
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const pageSize = parseInt((event.target as HTMLSelectElement).value, 10);
    dispatch(
      fetchList({
        pagination: {
          pageSize,
          pageIndex: 0,
        },
      })
    );
  };

  const [debouncedHandleChangeSearch] = useDebouncedCallback(
    (filtering: any) => {
      dispatch(
        fetchList({
          pagination: {
            pageIndex: 0,
          },
          filtering,
        })
      );
    },
    1000
  );

  const [search, setSearch] = useState<any>(defaultFilteringParams);

  const handleChangeSearch = (filed: string, value: string) => {
    const newValue = {
      ...search,
      [filed]: value,
    };
    setSearch(newValue);
    debouncedHandleChangeSearch(newValue);
  };

  const actions = (id: string) => {
    let result = [];
    if (showShowButton) {
      result.push({
        icon: <Icon icon={faExternalLinkAlt} default="true" size="lg" />,
        name: labels.button.show,
        handler: () => onShow(id),
      });
    }
    if (showDeleteButton) {
      result.push({
        icon: <Icon icon={faTrashAlt} red="true" default="true" size="lg" />,
        name: labels.button.delete,
        handler: () => setDeleteConfirmOpen(true),
      });
    }
    return result;
  };

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  return (
    <Loader isLoading={isListLoading} text={labels.loading.data}>
      <AuthWrapper>
        <Wrapper>
          {showCreateButton && (
            <Button blue="true" onClick={onCreate}>
              <Icon icon={faSignInAlt} size="lg" />
              {createButtonLabel}
            </Button>
          )}

          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((item) => (
                    <TableCell>{item.header}</TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((item) =>
                    item.filterField ? (
                      <TableCell>
                        <SimpleInput
                          value={search[item.filterField]}
                          onChange={(e) =>
                            handleChangeSearch(
                              item.filterField!,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )
                  )}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((item) => (
                    <TableRow key={item[idField]}>
                      {columns.map((column) => (
                        <TableCell>
                          {item[column.field] || column.defaultValue}
                        </TableCell>
                      ))}
                      <TableCell align="right">
                        <SpeedDials actions={actions(item[idField])} />
                        <DeleteConfirmationDialog
                          open={deleteConfirmOpen}
                          setOpen={setDeleteConfirmOpen}
                          onConfirm={() => onDelete(item[idField])}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={pagination.totalElements || 0}
                    rowsPerPage={pagination.pageSize || 0}
                    page={pagination.pageIndex || 0}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableWrapper>
        </Wrapper>
      </AuthWrapper>
    </Loader>
  );
};

export default CRMTable;
