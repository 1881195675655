import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all } from "redux-saga/effects";

import { fetchCurrent, fetchCurrentSuccess, fetchCurrentError } from ".";
import { Rule, RULES } from "./types";
import { GET, Response } from "../../../utils/request";
import toast from "../../../components/Toaster";

const path = `/api/rules`;
const api = {
  single: (ruleName: string) => `${path}/${ruleName}/get`,
};

function* fetchCurrentSaga(action: PayloadAction<RULES>) {
  try {
    const response: Response<{ rule: Rule }> = yield GET(
      api.single(action.payload)
    );

    yield put(fetchCurrentSuccess(response.data.rule));
  } catch (error) {
    yield put(fetchCurrentError(error));
    toast.error(error.message);
  }
}

function* fetchCurrentWatcher() {
  yield takeLatest(fetchCurrent.type, fetchCurrentSaga);
}

export function* rootWatcher() {
  yield all([fork(fetchCurrentWatcher)]);
}

export default rootWatcher;
