import styled from "styled-components";

export const NavBar = styled.div`
  max-height: 340px;
  overflow-y: auto;

  @media (min-width: ${(props) => props.theme.breakpoint.mobile}) {
    overflow-y: visible;
    max-height: auto;
  }
`;

export default NavBar;
