import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../store";
import {
  selectChildren,
  selectCurrent,
  selectIsChildrenLoading as selectIsGroupChildrenLoading,
  selectIsCurrentLoading as selectIsGroupLoading,
  selectChildrenPagination,
  selectIsListLoading as selectIsGroupListLoading,
} from "../../store/ducks/groups/selectors";
import { fetchCurrent, fetchChildren, update } from "../../store/ducks/groups";
import { Group } from "../../store/ducks/groups/types";
import Members from "../../components/Members";
import { selectUser } from "../../store/ducks/auth/selectors";
import {
  selectCurrent as selectRule,
  selectIsCurrentLoading as selectRuleIsLoading,
} from "../../store/ducks/rule/selectors";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";
import {
  selectIsListLoading as selectIsCompaniesListLoading,
  selectIsListLoading as selectIsCompanyListLoading,
} from "../../store/ducks/companies/selectors";
import Loader from "../../components/Loader";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Button from "../Login/styled/Button";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import { FORM_ERROR } from "final-form";
import Header from "../../components/Header";
import GroupIcon from "@material-ui/icons/Group";
import { InitMembers } from "../../components/Members/types";

export interface UpdateGroupData extends Group {
  appendChildIds: string[];
  removeChildIds: string[];
}

const UpdateGroup = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const group = useSelector(selectCurrent);
  const auth = useSelector(selectUser)!; // auth must be already filled up because it is AuthenticatedRoute
  const rule = useSelector(selectRule)!; // rule must be already filled up because it is AuthenticatedRoute

  const showUpdateButton = hasRule(auth, RULES.REST_updateGroup);

  useEffect(() => {
    dispatch(fetchCurrent(id));
  }, [dispatch, id]);

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      groupId: {},
      name: {},
    },
    rule,
    labels.group
  );

  const isGroupLoading = useSelector(selectIsGroupLoading);
  const isGroupChildrenLoading = useSelector(selectIsGroupChildrenLoading);
  const isRuleLoading = useSelector(selectRuleIsLoading);
  const isCompanyListLoading = useSelector(selectIsCompanyListLoading);
  const isGroupListLoading = useSelector(selectIsGroupListLoading);
  const isCompaniesListLoading = useSelector(selectIsCompaniesListLoading);
  const isGroupViewLoading =
    isGroupLoading ||
    isGroupChildrenLoading ||
    isRuleLoading ||
    isCompanyListLoading ||
    isGroupListLoading ||
    isCompaniesListLoading;

  const onSubmit = (values: Group) => {
    const data = {
      ...values,
      ...members,
    };

    return new Promise((resolve, reject) => {
      dispatch(update(data, { resolve, reject }));
    })
      .then(() => {
        setMembers(InitMembers);
      })
      .catch((error) => {
        return {
          [FORM_ERROR]: error,
        };
      });
  };

  const [members, setMembers] = useState(InitMembers);

  const getMembers = () => members;

  return (
    <Loader isLoading={isGroupViewLoading} text={labels.loading.group}>
      <AuthWrapper>
        {group && (
          <Header level={2} icon={<GroupIcon />}>
            {group.name}
          </Header>
        )}
        <Form
          initialValues={group!}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.groupId} />
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.name} />
                </Grid>
              </Grid>

              <Members
                id={id}
                selectChildren={selectChildren}
                selectChildrenPagination={selectChildrenPagination}
                fetchChildren={fetchChildren}
                getMembers={getMembers}
                setMembers={setMembers}
              />

              <br />
              <br />
              {showUpdateButton && (
                <Button start="true" blue="true" type="submit">
                  <SaveIcon />
                  {labels.button.save}
                </Button>
              )}
            </form>
          )}
        />
      </AuthWrapper>
    </Loader>
  );
};

export default UpdateGroup;
