import { Pagination } from "../../../types/pagination";

export interface LogRecord {
  logLevel: string;
  systemName: string;
  emitterName: string;
  ipAddress: string;
  message: string;
  userId: string;
  logTime: Date;
}

export interface FilteringParams {
  logLevel?: string;
  systemName?: string;
  emitterName?: string;
  userId?: string;
  startDateTime?: Date | null;
  endDateTime?: Date | null;
}

export const DefaultFilteringParams = {};

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 50,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export interface State {
  data: {
    list: Array<LogRecord> | null;
  };
  loading: {
    list: boolean;
  };
  error: {
    list: string | null;
  };
  pagination: {
    list: Pagination;
  };
  filtering: {
    list: FilteringParams;
  };
}
