import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all, select } from "redux-saga/effects";

import { fetchList, fetchListSuccess, fetchListError } from ".";
import { FilteringParams, LogRecord } from "./types";
import { mapPaginationFields, Pagination } from "../../../types/pagination";
import { selectListPagination } from "./selectors";
import { POST, Response } from "../../../utils/request";
import toast from "../../../components/Toaster";

const path = `/api/logs`;
const api = {
  find: () => `${path}/find`,
};

function* fetchListSaga(
  action: PayloadAction<{
    pagination: Pagination;
    filtering: FilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectListPagination);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const response: Response<{ logs: LogRecord[] }> = yield POST(
      api.find(),
      action.payload.filtering,
      mapPaginationFields(requestPagination)
    );

    yield put(
      fetchListSuccess({
        data: response.data.logs,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: action.payload.filtering,
      })
    );
  } catch (error) {
    yield put(fetchListError(error));
    toast.error(error.message);
  }
}

function* fetchListWatcher() {
  yield takeLatest(fetchList.type, fetchListSaga);
}

export function* rootWatcher() {
  yield all([fork(fetchListWatcher)]);
}

export default rootWatcher;
