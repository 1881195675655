import { RootState } from "../..";

export const selectCurrent = (state: RootState) => state.companies.data.current;

export const selectIsCurrentLoading = (state: RootState) =>
  state.companies.loading.current;

export const selectList = (state: RootState) => state.companies.data.list;

export const selectListPagination = (state: RootState) =>
  state.companies.pagination.list;

export const selectListFiltering = (state: RootState) =>
  state.companies.filtering.list;

export const selectIsListLoading = (state: RootState) =>
  state.companies.loading.list;

export const selectChildren = (state: RootState) =>
  state.companies.data.children;

export const selectIsChildrenLoading = (state: RootState) =>
  state.companies.loading.children;

export const selectChildrenPagination = (state: RootState) =>
  state.companies.pagination.children;

export const selectChildrenFiltering = (state: RootState) =>
  state.companies.filtering.children;
