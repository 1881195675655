import moment from "moment";

export const DateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const DateFormat = "YYYY-MM-DD";
export const TimeFormat = "HH:mm:ss";

export function getDate(value: Date) {
  return moment(value).format(DateFormat);
}

export function getDateTime(value: Date) {
  return moment(value).format(DateTimeFormat);
}

export function getTime(value: Date) {
  return moment(value).format(TimeFormat);
}

export function startOfDay(value: Date) {
  return moment(value).utc().startOf("day").format();
}

export function endOfDay(value: Date) {
  return moment(value).utc().endOf("day").format();
}

export function monthAgo(value: Date) {
  return moment(value).subtract(30, "days").utc().startOf("day").format();
}

export function convertMilliSecondsToTimeString(millis: number) {
  return moment
    .utc(moment.duration(millis).as("milliseconds"))
    .format("HH:mm:ss");
}

export function removeTimezoneOffset(value: Date) {
  let newDate = new Date(value);
  const offset = new Date().getTimezoneOffset();
  newDate.setMinutes(newDate.getMinutes() - offset);
  return newDate;
}

export function addTimezoneOffset(value: Date) {
  let newDate = new Date(value);
  const offset = new Date().getTimezoneOffset();
  newDate.setMinutes(newDate.getMinutes() + offset);
  return newDate;
}
