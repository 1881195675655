import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  State,
  FileInfo,
  ItemMeta,
  DefaultPaginationParams,
  FilteringParams,
  DefaultFilteringParams,
  ChildItemInfo,
  ParentItemInfo,
} from "./types";
import { FormSubmissionMeta } from "../../../types";
import { Pagination } from "../../../types/pagination";
import { RequestError } from "../../../utils/request";

const initialState: State = {
  data: {
    file: null,
    itemMeta: null,
    parentItems: null,
    childItems: null,
  },
  loading: {
    file: false,
    itemMeta: false,
    items: false,
  },
  error: {
    file: null,
    itemMeta: null,
    items: null,
  },
  pagination: {
    items: DefaultPaginationParams,
  },
  filtering: {
    items: DefaultFilteringParams,
  },
};

const slice = createSlice({
  name: "files",
  initialState,
  reducers: {
    fetchFile: (state, _: PayloadAction<string>) => {
      state.data.file = null;
      state.loading.file = true;
      state.error.file = null;
    },
    fetchFileSuccess: (state, action: PayloadAction<FileInfo>) => {
      state.data.file = action.payload;
      state.loading.file = false;
    },
    fetchFileError: (state, action: PayloadAction<RequestError>) => {
      state.error.file = action.payload.message;
      state.loading.file = false;
    },
    cleanFile: (state) => {
      state.data.file = null;
    },
    fetchItemMeta: (state, _: PayloadAction<string>) => {
      state.data.itemMeta = null;
      state.loading.itemMeta = true;
      state.error.itemMeta = null;
    },
    fetchItemMetaSuccess: (state, action: PayloadAction<ItemMeta>) => {
      state.data.itemMeta = action.payload;
      state.loading.itemMeta = false;
    },
    fetchItemMetaError: (state, action: PayloadAction<RequestError>) => {
      state.error.itemMeta = action.payload.message;
      state.loading.itemMeta = false;
    },
    cleanItemMeta: (state) => {
      state.data.itemMeta = null;
    },
    fetchItems: (state, action: PayloadAction<{}>) => {
      state.data.parentItems = null;
      state.data.childItems = null;
      state.loading.items = true;
      state.error.items = null;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{
        childItems: Array<ChildItemInfo>;
        parentItems: Array<ParentItemInfo>;
        pagination: Pagination;
        filtering: FilteringParams;
      }>
    ) => {
      state.data.childItems = action.payload.childItems;
      state.data.parentItems = action.payload.parentItems;
      state.loading.items = false;
      state.pagination.items = action.payload.pagination;
      state.filtering.items = action.payload.filtering;
    },
    fetchItemsError: (state, action: PayloadAction<RequestError>) => {
      state.error.items = action.payload.message;
      state.loading.items = false;
    },
    createFiles: (state, action: PayloadAction<{}>) => {
      state.error.file = null;
      state.loading.file = true;
    },
    createFilesSuccess: (state, action: PayloadAction<void>) => {
      state.loading.file = false;
    },
    createFilesError: (state, action: PayloadAction<RequestError>) => {
      state.error.file = action.payload.message;
      state.loading.file = false;
    },
    updateFile: {
      reducer: (state, action: PayloadAction<{}>) => {
        state.error.file = null;
        state.loading.file = true;
      },
      prepare: (payload: {}, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateFileSuccess: {
      reducer: (state, action: PayloadAction<{}>) => {
        state.loading.file = false;
      },
      prepare: (payload: {}, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateFileError: {
      reducer: (state, action: PayloadAction<RequestError>) => {
        state.error.file = action.payload.message;
        state.loading.file = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateItemMeta: {
      reducer: (state, action: PayloadAction<{}>) => {
        state.error.itemMeta = null;
        state.loading.itemMeta = true;
      },
      prepare: (payload: {}, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateItemMetaSuccess: {
      reducer: (state, action: PayloadAction<{}>) => {
        state.loading.itemMeta = false;
      },
      prepare: (payload: {}, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
    updateItemMetaError: {
      reducer: (state, action: PayloadAction<RequestError>) => {
        state.error.itemMeta = action.payload.message;
        state.loading.itemMeta = false;
      },
      prepare: (payload: RequestError, meta: FormSubmissionMeta) => ({
        payload,
        meta,
      }),
    },
  },
});

export const {
  fetchFile,
  fetchFileSuccess,
  fetchFileError,

  cleanFile,

  fetchItemMeta,
  fetchItemMetaSuccess,
  fetchItemMetaError,

  cleanItemMeta,

  fetchItems,
  fetchItemsSuccess,
  fetchItemsError,

  createFiles,
  createFilesSuccess,
  createFilesError,

  updateFile,
  updateFileSuccess,
  updateFileError,

  updateItemMeta,
  updateItemMetaSuccess,
  updateItemMetaError,
} = slice.actions;

export default slice.reducer;
