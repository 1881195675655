import styled from "styled-components";
import Button from "../../../styled/Button";

const StyledButton = styled(Button)`
  && {
    margin: auto;
    display: flex;
    font-family: inherit;
  }
`;

export default StyledButton;
