import React from "react";
import { Field } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "./styled/StyledWrapper";
import { MenuItem, Select as MatSelect } from "@material-ui/core";

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  auth?: boolean;
  value?: string;
  customOnChangeCallback?: () => void;
}

const Select = ({
  name,
  label,
  options,
  required,
  auth,
  disabled,
  customOnChangeCallback,
}: Props) => (
  <Field
    name={name}
    validate={(value: string) => (required ? checkRequired(value) : undefined)}
  >
    {({ input, meta }) => (
      <StyledWrapper auth={auth}>
        <FormControl>
          <InputLabel required={required} error={meta.touched && meta.error}>
            {label}
          </InputLabel>
          <MatSelect
            {...input}
            required={required}
            disabled={disabled}
            onChange={(event) => {
              input.onChange(event);
              customOnChangeCallback && customOnChangeCallback();
            }}
          >
            {options &&
              options.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
          </MatSelect>
          <FormHelperText error>
            {meta.touched && meta.error ? meta.error : " "}
          </FormHelperText>
        </FormControl>
      </StyledWrapper>
    )}
  </Field>
);

export default Select;
