export const logLevelOptions = [
  { label: "NONE", value: "" },
  { label: "INFO", value: "INFO" },
  { label: "WARNING", value: "WARNING" },
  { label: "DETAILEDINFO", value: "DETAILEDINFO" },
  { label: "ERROR", value: "ERROR" },
  { label: "CRITICAL", value: "CRITICAL" },
  { label: "DEBUG", value: "DEBUG" },
];

export const systemNameOptions = [
  { label: "NONE", value: "" },
  { label: "OSX_STUDIO", value: "OSX_STUDIO" },
  { label: "WIN_STUDIO", value: "WIN_STUDIO" },
  { label: "IOS_APP", value: "IOS_APP" },
  { label: "ANDROID_APP", value: "ANDROID_APP" },
  { label: "USER_PORTAL", value: "USER_PORTAL" },
  { label: "STORE", value: "STORE" },
  { label: "BACKEND", value: "BACKEND" },
  { label: "STUDIO (D)", value: "STORE" },
];

export const emitterNameOptions = systemNameOptions;

export const typeOptions = [
  { label: "NONE", value: "" },
  { label: "LOGIN", value: "LOGIN" },
  { label: "LOGOUT", value: "LOGOUT" },
  { label: "DATACHUNK_START", value: "DATACHUNK_START" },
  { label: "DATACHUNK_END", value: "DATACHUNK_END" },
  { label: "NDA", value: "NDA" },
  { label: "LICENSE_AGREEMENT", value: "LICENSE_AGREEMENT" },
];

export const licenseOptions = [{ label: "Royalty", value: "Royalty" }];
