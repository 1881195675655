import styled from "styled-components";

const NavButton = styled.div`
  display: block;

  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: ${(props) => props.theme.color.buttonborder};
  margin-left: auto;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoint.mobile}) {
    display: none;
  }
`;

export default NavButton;
