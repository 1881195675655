import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  selectList,
  selectListPagination,
  selectIsListLoading,
} from "../../store/ducks/events/selectors";
import {
  FilteringParams,
  DefaultPaginationParams,
  DefaultFilteringParams,
} from "../../store/ducks/events/types";
import { useAppDispatch } from "../../store";
import { fetchList } from "../../store/ducks/events";
import { selectCurrent as selectRule } from "../../store/ducks/rule/selectors";
import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";
import { Form } from "react-final-form";
import Input from "../../components/Form/Input";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import { endOfDay, getDate, getTime, startOfDay } from "../../utils/datetime";
import Loader from "../../components/Loader";
import { selectUser } from "../../store/ducks/auth/selectors";
import Select from "../../components/Form/Select";
import DatePicker from "../../components/Form/DatePicker";
import {
  emitterNameOptions,
  systemNameOptions,
  typeOptions,
} from "../../components/Monitoring/constants";
import Map from "./Map";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import SelectCountry from "../../components/SelectCountry";
import MonitoringTable from "../../components/Monitoring";
import CLink from "../../styled/CSVLink";
import Button from "../../styled/Button";
import Grid from "../../styled/Grid";

const columns = [
  { header: labels.events.date, field: "timestamp", mapper: getDate },
  { header: labels.events.time, field: "timestamp", mapper: getTime },
  { header: labels.events.id, field: "userId" },
  { header: labels.events.type, field: "type" },
  { header: labels.events.country, field: "country" },
  { header: labels.events.city, field: "city" },
  { header: labels.events.systemName, field: "systemName" },
  { header: labels.events.emitterName, field: "emitterName" },
];

const Events = () => {
  const dispatch = useAppDispatch();

  const auth = useSelector(selectUser)!;
  const rule = useSelector(selectRule)!;
  const events = useSelector(selectList);
  const isListLoading = useSelector(selectIsListLoading);

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      userId: {},
      country: {},
      city: {},
      type: {},
      emitterName: {},
      systemName: {},
      startDateTime: {},
      endDateTime: {},
    },
    rule,
    labels.events
  );

  const [search, setSearch] = useState<any>(DefaultFilteringParams);

  const getSearch = () => search;

  const onSubmitSearch = (filtering: FilteringParams) => {
    const data = {
      userId:
        filtering.userId || (fields.userId.required ? auth.userId : undefined),
      startDateTime: filtering.startDateTime
        ? startOfDay(filtering.startDateTime)
        : undefined,
      endDateTime: filtering.endDateTime
        ? endOfDay(filtering.endDateTime)
        : undefined,
      country: filtering.country || undefined,
      type: filtering.type || undefined,
      systemName: filtering.systemName || undefined,
      emitterName: filtering.emitterName || undefined,
    };
    setSearch(data);
    dispatch(
      fetchList({
        pagination: {
          pageIndex: 0,
        },
        filtering: data,
      })
    );
  };

  return (
    <Loader isLoading={isListLoading} text={labels.loading.common}>
      <AuthWrapper>
        <Form
          initialValues={{}}
          onSubmit={onSubmitSearch}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={2}>
                  <Input {...fields.userId} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <SelectCountry {...fields.country} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <Select options={typeOptions} {...fields.type} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <Select options={systemNameOptions} {...fields.systemName} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <Select
                    options={emitterNameOptions}
                    {...fields.emitterName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={2}>
                  <DatePicker {...fields.startDateTime} />
                </Grid>

                <Grid item xs={6} md={4} lg={2}>
                  <DatePicker {...fields.endDateTime} />
                </Grid>
              </Grid>

              <br />
              <Grid container spacing={3}>
                <Button blue="true" type="submit">
                  <SearchIcon />
                  {labels.button.search}
                </Button>
              </Grid>
              <br />
            </form>
          )}
        />
        <Map events={events} />
        <br />
        <Grid container spacing={3} end="true">
          {events && (
            <CLink data={events}>
              <Button blue="true">
                <GetAppIcon />
                {labels.button.csv}
              </Button>
            </CLink>
          )}
        </Grid>
        <br />
        <MonitoringTable
          columns={columns}
          idField="logTime"
          selectList={selectList}
          selectListPagination={selectListPagination}
          fetchList={fetchList}
          defaultPaginationParams={DefaultPaginationParams}
          defaultFilteringParams={DefaultFilteringParams}
          getSearch={getSearch}
        />
      </AuthWrapper>
    </Loader>
  );
};

export default Events;
