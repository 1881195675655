import styled from "styled-components";

const TabPanelWrapper = styled.div`
  padding: 8px;

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    padding: 16px;
  }
`;

export default TabPanelWrapper;
