import styled from "styled-components";

const ColorPickerWidget = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid ${(props) => props.theme.color.white};
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),
    0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  position: relative;
  
  top: 22px;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.white};
  background-image: -webkit-linear-gradient(
      45deg,
      ${(props) => props.theme.color.colorPicker} 25%,
      transparent 0,
      transparent 75%,
      ${(props) => props.theme.color.colorPicker} 0,
      ${(props) => props.theme.color.colorPicker}
    ),

  background-image: linear-gradient(
      45deg,
      ${(props) => props.theme.color.colorPicker} 25%,
      transparent 0,
      transparent 75%,
      ${(props) => props.theme.color.colorPicker} 0,
      ${(props) => props.theme.color.colorPicker}
    ),
    linear-gradient(
      45deg,
      ${(props) => props.theme.color.colorPicker} 25%,
      transparent 0,
      transparent 75%,
      ${(props) => props.theme.color.colorPicker} 0,
      ${(props) => props.theme.color.colorPicker}
    );
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px;
  cursor: pointer;
`;
export default ColorPickerWidget;
