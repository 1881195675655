import styled from "styled-components";

const Point = styled.div`
  width: 27px;
  height: 43px;
  position: relative;
  left: -13px;
  top: -21px;
  background-image: url("https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export default Point;
