import React from "react";
import { Line } from "react-chartjs-2";
import {
  defaultHeight,
  defaultLineGraphProperties,
  defaultOptions,
} from "./constants";

interface Props {
  label?: string;
  labels: string[];
  data: number[];
  height?: number;
  options?: any;
  datasetsOptions?: any;
}

const LineGraph = ({
  label,
  labels,
  data,
  height,
  options,
  datasetsOptions,
}: Props) => {
  const properties = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        ...(datasetsOptions || defaultLineGraphProperties),
      },
    ],
  };

  const options_ = {
    ...defaultOptions,
    ...options,
  };

  return (
    <Line
      options={options_}
      data={properties}
      height={height || defaultHeight}
    />
  );
};

export default LineGraph;
