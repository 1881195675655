import { fork, all } from "redux-saga/effects";
import authWatcher from "./ducks/auth/sagas";
import companiesWatcher from "./ducks/companies/sagas";
import countriesWatcher from "./ducks/countries/sagas";
import groupsWatcher from "./ducks/groups/sagas";
import usersWatcher from "./ducks/users/sagas";
import ruleWatcher from "./ducks/rule/sagas";
import eventsWatcher from "./ducks/events/sagas";
import logsWatcher from "./ducks/logs/sagas";
import sessionsWatcher from "./ducks/sessions/sagas";
import filesWatcher from "./ducks/files/sagas";
import languagesWatcher from "./ducks/languages/sagas";
import productsWatcher from "./ducks/products/sagas";

export const sagas = [
  authWatcher,
  companiesWatcher,
  countriesWatcher,
  groupsWatcher,
  usersWatcher,
  ruleWatcher,
  eventsWatcher,
  logsWatcher,
  sessionsWatcher,
  filesWatcher,
  languagesWatcher,
  productsWatcher,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}
