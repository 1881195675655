import styled from "styled-components";
import MatPagination from "@material-ui/lab/Pagination";

const PaginationStyled = styled(MatPagination)`
  && {
    .MuiPaginationItem-page {
      height: 28px;
      min-width: 28px;
      color: ${(props) => props.theme.color.blue};
    }

    .MuiPaginationItem-page:hover,
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${(props) => props.theme.color.blue};
      color: ${(props) => props.theme.color.white};
    }

    .MuiPaginationItem-outlined {
      border-color: ${(props) => props.theme.color.blue};
    }
  }
`;

export default PaginationStyled;
