import styled from "styled-components";

interface Props {
  small?: "true";
}

const ImageWrapper = styled.div<Props>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;

  ${(props) =>
    props.small &&
    `
    width: 20px;
    height: 20px;
  `}
`;

export default ImageWrapper;
