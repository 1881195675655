import React from "react";
import Grid from "@material-ui/core/Grid";
import { Form, FormSpy } from "react-final-form";

import Button from "../../styled/Button";
import Input from "../../components/Form/Input";
import { User } from "../../store/ducks/users/types";
import { Auth } from "../../store/ducks/auth/types";
import PasswordInput from "../../components/Form/PasswordInput";
import toast from "../../components/Toaster";
import { useAppDispatch } from "../../store";
import { changePassword } from "../../store/ducks/auth";
import { FORM_ERROR } from "final-form";
import labels from "../../utils/labels";

interface Props {
  user: User;
  fields: any;
  auth: Auth;
  showUpdateButton: boolean;
}

interface UserPasswordData {
  userId: string;
  password?: string;
  confirmed?: string;
}

const Account = ({ user, fields, showUpdateButton }: Props) => {
  const dispatch = useAppDispatch();

  const onSubmit = (data: UserPasswordData) => {
    if (!data.password) {
      return;
    }

    if (data.password !== data.confirmed) {
      toast.error("Passwords are not equal.");
      return;
    }

    // TS will complain if 'data.password' will be directly passed into 'changePassword'
    const password = data.password;
    return new Promise((resolve, reject) => {
      dispatch(
        changePassword({ password, userId: data.userId }, { resolve, reject })
      );
    }).catch((error) => {
      return {
        [FORM_ERROR]: error,
      };
    });
  };

  return (
    <Form
      initialValues={{ userId: user ? user.userId : "" }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={async (...args) => {
            await handleSubmit(...args);
            form.reset();
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={2}>
              <Input {...fields.userId} />
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={2}>
              <PasswordInput {...fields.password} extendedValidation={true} />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <PasswordInput
                {...fields.confirmed}
                disabled={fields.password.disabled}
              />
            </Grid>
          </Grid>

          <br />
          {showUpdateButton && (
            <FormSpy subscription={{ dirty: true }}>
              {(props) =>
                props.dirty && (
                  <Button blue="true" type="submit">
                    {labels.profile.updatePassword}
                  </Button>
                )
              }
            </FormSpy>
          )}
        </form>
      )}
    />
  );
};

export default Account;
