import {
  Child,
  FilteringParams as ChildrenFilteringParams,
} from "../../../types/children";
import { Pagination } from "../../../types/pagination";

export interface Group {
  groupId: string;
  name: string;
  members?: number;
}

export interface FilteringParams {
  idFilter?: string;
}

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 25,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export const DefaultFilteringParams = {
  idFilter: "",
};

export interface State {
  data: {
    current: Group | null;
    list: Array<Group> | null;
    children: Array<Child> | null;
  };
  loading: {
    current: boolean;
    list: boolean;
    children: boolean;
  };
  error: {
    current: string | null;
    list: string | null;
    children: string | null;
  };
  pagination: {
    list: Pagination;
    children: Pagination;
  };
  filtering: {
    list: FilteringParams;
    children: ChildrenFilteringParams;
  };
}
