import React from "react";
import SignUp from "./SignUp";

const SignUpUser = () => {
  return (
    <SignUp
      description={<p>your user ID consists of your 'firstname.lastname'.</p>}
    />
  );
};

export default SignUpUser;
