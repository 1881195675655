import React from "react";
import Grid from "@material-ui/core/Grid";
import { SessionDetails as SessionDetailsI } from "../../store/ducks/sessions/types";
import labels from "../../utils/labels";
import Li from "./styled/li";
import TableHead from "@material-ui/core/TableHead";
import TableWrapper from "./styled/table";
import TableRow from "@material-ui/core/TableRow";
import { Table, TableBody, TableCell } from "@material-ui/core";

interface Props {
  session: SessionDetailsI;
  sessionHistoryParameters: any;
}

const SessionSettings = ({ session, sessionHistoryParameters }: Props) => {
  return (
    <>
      {(session.parameters.globalParameters ||
        session.parameters.globalSessionParameters) && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            {session.parameters.globalParameters &&
              session.parameters.globalParameters.length > 0 && (
                <Grid item>
                  <label style={{ color: "black", fontSize: 25 }}>
                    {labels.sessions.globalStats}:
                  </label>
                  <ul>
                    {session.parameters.globalParameters.map(
                      (parameter, index) => (
                        <Li
                          key={index}
                        >{`${parameter.name}: ${parameter.parameter}`}</Li>
                      )
                    )}
                  </ul>
                </Grid>
              )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {session.parameters.globalSessionParameters &&
              session.parameters.globalSessionParameters.length > 0 && (
                <Grid item>
                  <label style={{ color: "black", fontSize: 25 }}>
                    {labels.sessions.sessionStats}:
                  </label>
                  <ul>
                    {session.parameters.globalSessionParameters.map(
                      (parameter, index) => (
                        <Li
                          key={index}
                        >{`${parameter.name}: ${parameter.parameter}`}</Li>
                      )
                    )}
                  </ul>
                </Grid>
              )}
          </Grid>
        </Grid>
      )}
      {sessionHistoryParameters.length > 0 && (
        <Grid container spacing={3}>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{labels.sessions.parameter}</TableCell>
                  <TableCell>{labels.sessions.preSession}</TableCell>
                  <TableCell>{labels.sessions.postSession}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessionHistoryParameters.map((param: any) => (
                  <TableRow>
                    <TableCell>{param["key"]}</TableCell>
                    <TableCell>{param["previous"]}</TableCell>
                    <TableCell>{param["current"]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Grid>
      )}
    </>
  );
};

export default SessionSettings;
