import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all, select } from "redux-saga/effects";

import {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,
  fetchList,
  fetchListSuccess,
  fetchListError,
  fetchChildren,
  fetchChildrenSuccess,
  fetchChildrenError,
  create,
  createSuccess,
  createError,
  update,
  updateSuccess,
  updateError,
  delete_,
  deleteSuccess,
  deleteError,
} from ".";
import { FormSubmissionMeta } from "../../../types";
import { CreateGroupData } from "../../../pages/CreateGroup";
import { UpdateGroupData } from "../../../pages/UpdateGroup";
import { FilteringParams, Group } from "./types";
import {
  FilteringParams as ChildrenFilteringParams,
  fromArray,
} from "../../../types/children";
import { DELETE, GET, POST, PUT, Response } from "../../../utils/request";
import { mapPaginationFields, Pagination } from "../../../types/pagination";
import {
  selectListPagination,
  selectListFiltering,
  selectChildrenFiltering,
  selectChildrenPagination,
} from "./selectors";
import toast from "../../../components/Toaster";
import history from "../../../utils/history";
import { ROUTES_PATHS } from "../../../Routes";

const path = `/api/groups`;
const api = {
  single: (groupId: string) => `${path}/${groupId}/get`,
  list: () => `${path}/list`,
  children: (groupId: string) => `${path}/${groupId}/children/get`,
  create: (groupId: string) => `${path}/${groupId}/create`,
  update: (groupId: string) => `${path}/${groupId}/update`,
  delete: (groupId: string) => `${path}/${groupId}/delete`,
};

function* fetchCurrentSaga(action: PayloadAction<string>) {
  try {
    const response: Response<{ group: Group }> = yield GET(
      api.single(action.payload)
    );

    yield put(fetchCurrentSuccess(response.data.group));
  } catch (error) {
    yield put(fetchCurrentError(error));
    toast.error(error.message);
  }
}

function* fetchListSaga(
  action: PayloadAction<{
    pagination: Pagination;
    filtering: FilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectListPagination);
    const oldFiltering = yield select(selectListFiltering);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{ groups: Group[] }> = yield GET(api.list(), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
    });

    yield put(
      fetchListSuccess({
        data: response.data.groups,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchListError(error));
    toast.error(error.message);
  }
}

function* fetchChildrenSaga(
  action: PayloadAction<{
    id: string;
    pagination: Pagination;
    filtering: ChildrenFilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectChildrenPagination);
    const oldFiltering = yield select(selectChildrenFiltering);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const requestFiltering = {
      ...oldFiltering,
      ...action.payload.filtering,
    };

    const response: Response<{
      children: Array<Array<string | null>>;
    }> = yield GET(api.children(action.payload.id), {
      ...mapPaginationFields(requestPagination),
      ...requestFiltering,
    });

    yield put(
      fetchChildrenSuccess({
        data: response.data.children.map((children) => fromArray(children)),
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        filtering: {
          ...requestFiltering,
          ...action.payload.filtering,
        },
      })
    );
  } catch (error) {
    yield put(fetchChildrenError(error));
    toast.error(error.message);
  }
}

function* createSaga(
  action: PayloadAction<CreateGroupData, string, FormSubmissionMeta>
) {
  try {
    const response: Response<{ group: Group }> = yield POST(
      api.create(action.payload.groupId),
      action.payload
    );

    yield put(createSuccess(response.data.group, action.meta));
    toast.success("Group created.");

    history.push(`/${ROUTES_PATHS.group}/${response.data.group.groupId}`);
  } catch (error) {
    yield put(createError(error, action.meta));
    toast.error(error.message);
  }
}

function* updateSaga(
  action: PayloadAction<UpdateGroupData, string, FormSubmissionMeta>
) {
  try {
    yield PUT(api.update(action.payload.groupId), action.payload);

    const response: Response<{ group: Group }> = yield GET(
      api.single(action.payload.groupId)
    );

    yield put(updateSuccess(response.data.group, action.meta));
    toast.success("Group updated.");

    yield put(fetchCurrent(action.payload.groupId));
    yield put(
      fetchChildren({
        id: action.payload.groupId,
      })
    );
  } catch (error) {
    yield put(updateError(error, action.meta));
    toast.error(error.message);
  }
}

function* deleteSaga(action: PayloadAction<string>) {
  try {
    yield DELETE(api.delete(action.payload));

    yield put(deleteSuccess());
    toast.success("Group deleted.");
    yield put(fetchList({}));
  } catch (error) {
    yield put(deleteError(error));
    toast.error(error.message);
  }
}

function* fetchCurrentWatcher() {
  yield takeLatest(fetchCurrent.type, fetchCurrentSaga);
}

function* fetchListWatcher() {
  yield takeLatest(fetchList.type, fetchListSaga);
}

function* fetchChildrenWatcher() {
  yield takeLatest(fetchChildren.type, fetchChildrenSaga);
}

function* createWatcher() {
  yield takeLatest(create.type, createSaga);
}

function* updateWatcher() {
  yield takeLatest(update.type, updateSaga);
}

function* deleteWatcher() {
  yield takeLatest(delete_.type, deleteSaga);
}

export function* rootWatcher() {
  yield all([
    fork(fetchCurrentWatcher),
    fork(fetchListWatcher),
    fork(fetchChildrenWatcher),
    fork(createWatcher),
    fork(updateWatcher),
    fork(deleteWatcher),
  ]);
}

export default rootWatcher;
