import { createGlobalStyle } from "styled-components";
import background from "./images/background.jpg";

export const theme = {
  color: {
    white: "rgb(255,255,255)",
    yellow: "rgba(255, 171, 64, 0.87)",
    brightred: "#ed002e",
    black: "#000",
    red: "#ac153b",
    darkred: "#690d24",
    blue: "#009fe3",
    darkblue: "#073346",
    gray: "#bcbcbc",
    lightgray: "#dadada",
    darkgray: "#3c3c3b",
    subsectiongray: "#9d9d9c",
    lightgreen: "#bfea21",
    green: "#96bb1a",
    buttonborder: "#333",

    editorborder: "#ccc",
    editorbackground: "#555",
    lightblue: "rgba(0,159,227,0.3)",
    colorPicker: "#ddd",
    customFileInput: "#f9f9f9",
    purchase: "#f5f8f9",
  },

  breakpoint: {
    mobile: "768px",
    small: "600px",
    medium: "960px",
    large: "1280px",
  },
};

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "SourceSansPro-Light";
    src: url("${require("./fonts/SourceSansPro-Light.otf")}") format("opentype");
  }
  
  * {
    box-sizing: border-box;
  }

  body, html {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  body {
    margin: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 20%;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-image: url(${background});
    font-family: "SourceSansPro-Light", "Helvetica Neue", "Helvetica";
    font-size: 12pt;
    line-height: 1.42857143;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
  }

  h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: ${theme.color.black};
    display: flex;
  }

  p {
    color: ${theme.color.black};
    margin: 0 0 10px;
  }

  h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  h1 {
    font-size: 36pt;
    color: ${theme.color.blue};
  }
  h2 {
    font-size: 28pt;
  }
  h3 {
    font-size: 20pt;
  }

`;
