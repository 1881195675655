import React from "react";
import Header from "../components/Header";
import Link from "../styled/Link";

const APIDocumentation = () => {
  return (
    <article>
      <Header>API Documentation</Header>
      <p>
        This is a documentation for all RESTful API functionalities of the
        neuromore back-end system.
        <br></br>This section is only permitted by admins.
      </p>
      <Link
        to={`//backend-test.neuromore.com/swagger-ui/#!/`}
        target={"_blank"}
      >
        Open Swagger in external window
      </Link>
    </article>
  );
};

export default APIDocumentation;
