import React, { useState } from "react";
import { Field } from "react-final-form";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { required as checkRequired } from "../../utils/form";
import StyledWrapper from "./styled/StyledWrapper";
import { Editor as TextField } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  auth?: boolean;
  initValue?: string | undefined;
}

const Editor = ({ name, label, required, auth, initValue }: Props) => {
  const getInitState = () => {
    if (!initValue) {
      return EditorState.createEmpty();
    }

    const contentBlock = htmlToDraft(initValue);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  };

  const [editorState, setEditorState] = useState(getInitState());

  return (
    <Field
      name={name}
      validate={(value: string) =>
        required ? checkRequired(value) : undefined
      }
    >
      {({ input, meta }) => (
        <StyledWrapper auth={auth}>
          <FormControl>
            <InputLabel required={required} error={meta.touched && meta.error}>
              {label}
            </InputLabel>
            <TextField
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(editorState: EditorState) => {
                setEditorState(editorState);
                input.onChange(
                  draftToHtml(convertToRaw(editorState.getCurrentContent()))
                );
              }}
            />
            <FormHelperText error>
              {meta.touched && meta.error ? meta.error : " "}
            </FormHelperText>
          </FormControl>
        </StyledWrapper>
      )}
    </Field>
  );
};

export default Editor;
