import React from "react";
import { useAppDispatch } from "../../store";
import {
  DefaultFilteringParams,
  DefaultPaginationParams,
} from "../../store/ducks/users/types";
import { fetchList, delete_ } from "../../store/ducks/users";
import {
  selectList,
  selectListPagination,
  selectIsListLoading,
} from "../../store/ducks/users/selectors";
import { ROUTES_PATHS } from "../../Routes";
import { useHistory } from "react-router-dom";
import CRMTable, { TableColumn } from "../../components/CRM";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import labels from "../../utils/labels";
import config from "config";

const columns: Array<TableColumn> = [
  { header: labels.profile.userId, field: "userId", filterField: "idFilter" },
  { header: labels.profile.firstname, field: "firstname" },
  { header: labels.profile.lastname, field: "lastname" },
  { header: labels.profile.role, field: "role", filterField: "roleFilter" },
  { header: labels.profile.email, field: "email", filterField: "emailFilter" },
  { header: labels.profile.country, field: "country" },
  { header: labels.profile.city, field: "city" },
];

if (!config.CRMUsers?.hideMembers) {
  columns.push({
    header: labels.profile.members,
    field: "members",
    defaultValue: 0,
  });
}

const ListUsers = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useSelector(selectUser)!;

  const showCreateButton = hasRule(user, RULES.FRONTEND_SectionCreateUser);
  const showShowButton = hasRule(user, RULES.REST_getUser);
  const showDeleteButton = hasRule(user, RULES.REST_deleteUser);

  const createUser = () => {
    return history.push(`/${ROUTES_PATHS.createUser}`);
  };

  const showUser = (id: string) => {
    return history.push(`/${ROUTES_PATHS.user}/${id}`);
  };

  const deleteUser = (id: string) => {
    dispatch(delete_(id));
  };

  return (
    <CRMTable
      columns={columns}
      idField="userId"
      selectList={selectList}
      selectListPagination={selectListPagination}
      selectIsListLoading={selectIsListLoading}
      fetchList={fetchList}
      defaultPaginationParams={DefaultPaginationParams}
      defaultFilteringParams={DefaultFilteringParams}
      createButtonLabel={labels.profile.create}
      showCreateButton={showCreateButton}
      onCreate={createUser}
      showShowButton={showShowButton}
      onShow={showUser}
      showDeleteButton={showDeleteButton}
      onDelete={deleteUser}
    />
  );
};

export default ListUsers;
