import React, { useState } from "react";
import Nav from "./styled/Nav";
import Link from "../../styled/Link";
import Wrapper from "../Logo/styled/Wrapper";
import config from "config";
import {
  faHome,
  faUser,
  faDownload,
  faChartBar,
  faSitemap,
  faPlusSquare,
  faChevronRight,
  faUsers,
  faChevronDown,
  faBriefcase,
  faTachometerAlt,
  faThLarge,
  faList,
  faCogs,
  faBook,
  faSignOutAlt,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import NavButton from "./styled/NavButton";
import { Collapse, Tooltip } from "@material-ui/core";
import HeaderWrapper from "./styled/HeaderWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROUTES_PATHS } from "../../Routes";
import { useAppDispatch } from "../../store";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import { RULES } from "../../store/ducks/rule/types";
import { logout } from "../../store/ducks/auth";
import { hasAccess } from "./utils";
import NavBar from "./styled/NavBar";
import ExternalLink from "../../styled/ExternalLink";

const Navigation = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isMonOpen, setMonIsOpen] = useState(false);

  const auth = useSelector(selectUser)!;

  const toggle = () => setIsOpen(!isOpen);
  const toggleMon = () => setMonIsOpen(!isMonOpen);

  const [isNavOpen, openNavbar] = useState(false);
  const toggleHeader = () => openNavbar(!isNavOpen);

  const [active, setActive] = useState<string>(ROUTES_PATHS.home);

  return (
    <Nav>
      <HeaderWrapper>
        <ExternalLink href={config.branding.logo.link} target="_blank">
          <Wrapper brand>
            <img src={config.logo} alt={config.branding.logo.alt} />
          </Wrapper>
        </ExternalLink>
        <NavButton onClick={toggleHeader}>
          <FontAwesomeIcon icon={faBars} size="lg" />
        </NavButton>
      </HeaderWrapper>

      <NavBar
        className="collapse"
        style={{ display: isNavOpen ? "block" : "none" }}
      >
        <div>
          <Link
            to={`/${ROUTES_PATHS.home}`}
            onClick={() => setActive(ROUTES_PATHS.home)}
          >
            <Button
              section
              active={active === ROUTES_PATHS.home ? "true" : undefined}
            >
              <Icon icon={faHome} size="lg" />
              Home
            </Button>
          </Link>

          <Link
            to={`/${ROUTES_PATHS.profile}`}
            onClick={() => setActive(ROUTES_PATHS.profile)}
          >
            <Button
              section
              active={active === ROUTES_PATHS.profile ? "true" : undefined}
            >
              <Icon icon={faUser} size="lg" />
              Profile
            </Button>
          </Link>

          {Boolean(config.downloads) &&
            hasAccess(auth, RULES.FRONTEND_SectionDownload) && (
              <Link
                to={`/${ROUTES_PATHS.downloads}`}
                onClick={() => setActive(ROUTES_PATHS.downloads)}
              >
                <Button
                  section
                  active={
                    active === ROUTES_PATHS.downloads ? "true" : undefined
                  }
                >
                  <Icon icon={faDownload} size="lg" />
                  Downloads
                </Button>
              </Link>
            )}

          {hasAccess(auth, RULES.FRONTEND_SectionSession) && (
            <Link
              to={`/${ROUTES_PATHS.sessions}`}
              onClick={() => setActive(ROUTES_PATHS.sessions)}
            >
              <Button
                section
                active={active === ROUTES_PATHS.sessions ? "true" : undefined}
              >
                <Icon icon={faChartBar} size="lg" />
                Sessions
              </Button>
            </Link>
          )}

          {Boolean(config.filesystem) &&
            hasAccess(auth, RULES.FRONTEND_SectionItemEditor) && (
              <Link
                to={`/${ROUTES_PATHS.filesystem}`}
                onClick={() => setActive(ROUTES_PATHS.filesystem)}
              >
                <Button
                  section
                  active={
                    active === ROUTES_PATHS.filesystem ? "true" : undefined
                  }
                >
                  <Icon icon={faSitemap} size="lg" />
                  Filesystem
                </Button>
              </Link>
            )}

          {hasAccess(auth, RULES.FRONTEND_SectionCustomer) && (
            <>
              <Button section onClick={toggle}>
                <Icon icon={faUsers} size="lg" />
                CRM
                <Tooltip title={isOpen ? "Collapse" : "Expand"} placement="top">
                  <div style={{ marginLeft: "auto" }}>
                    {/* Mat Tooltip doest work in custom componets */}
                    <Icon
                      arrow="true"
                      icon={isOpen ? faChevronRight : faChevronDown}
                      size="lg"
                    />
                  </div>
                </Tooltip>
              </Button>

              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {hasAccess(auth, RULES.FRONTEND_SectionShowCompanies) && (
                  <Link
                    to={`/${ROUTES_PATHS.companies}`}
                    onClick={() => setActive(ROUTES_PATHS.companies)}
                  >
                    <Button
                      subsection
                      active={
                        active === ROUTES_PATHS.companies ? "true" : undefined
                      }
                    >
                      <Icon icon={faBriefcase} size="lg" />
                      Companies
                      <Tooltip title="Create Company" placement="top">
                        <Link
                          arrow="true"
                          to={`/${ROUTES_PATHS.createCompany}`}
                        >
                          <Icon gray="true" icon={faPlusSquare} size="lg" />
                        </Link>
                      </Tooltip>
                    </Button>
                  </Link>
                )}
                {hasAccess(auth, RULES.FRONTEND_SectionShowGroups) && (
                  <Link
                    to={`/${ROUTES_PATHS.groups}`}
                    onClick={() => setActive(ROUTES_PATHS.groups)}
                  >
                    <Button
                      subsection
                      active={
                        active === ROUTES_PATHS.groups ? "true" : undefined
                      }
                    >
                      <Icon icon={faUsers} size="lg" />
                      Groups
                      <Tooltip arrow title="Create Group" placement="top">
                        <Link arrow="true" to={`/${ROUTES_PATHS.createGroup}`}>
                          <Icon gray="true" icon={faPlusSquare} size="lg" />
                        </Link>
                      </Tooltip>
                    </Button>
                  </Link>
                )}
                {hasAccess(auth, RULES.FRONTEND_SectionShowUsers) && (
                  <Link
                    to={`/${ROUTES_PATHS.users}`}
                    onClick={() => setActive(ROUTES_PATHS.users)}
                  >
                    <Button
                      subsection
                      active={
                        active === ROUTES_PATHS.users ? "true" : undefined
                      }
                    >
                      <Icon icon={faUser} size="lg" />
                      Users
                      <Tooltip title="Create User" placement="top">
                        <Link arrow="true" to={`/${ROUTES_PATHS.createUser}`}>
                          <Icon gray="true" icon={faPlusSquare} size="lg" />
                        </Link>
                      </Tooltip>
                    </Button>
                  </Link>
                )}
              </Collapse>
            </>
          )}

          {hasAccess(auth, RULES.FRONTEND_SectionMonitoring) && (
            <>
              <Button section onClick={toggleMon}>
                <Icon icon={faTachometerAlt} size="lg" />
                Monitoring
                <Tooltip
                  title={isMonOpen ? "Collapse" : "Expand"}
                  placement="top"
                >
                  <div style={{ marginLeft: "auto" }}>
                    {" "}
                    {/* Mat Tooltip doest work in custom componets */}
                    <Icon
                      arrow="true"
                      icon={isMonOpen ? faChevronRight : faChevronDown}
                      size="lg"
                    />
                  </div>
                </Tooltip>
              </Button>

              <Collapse in={isMonOpen} timeout="auto" unmountOnExit>
                <Link
                  to={`/${ROUTES_PATHS.events}`}
                  onClick={() => setActive(ROUTES_PATHS.events)}
                >
                  <Button
                    subsection
                    active={active === ROUTES_PATHS.events ? "true" : undefined}
                  >
                    <Icon icon={faThLarge} size="lg" />
                    Events
                  </Button>
                </Link>
                <Link
                  to={`/${ROUTES_PATHS.logs}`}
                  onClick={() => setActive(ROUTES_PATHS.logs)}
                >
                  <Button
                    subsection
                    active={active === ROUTES_PATHS.logs ? "true" : undefined}
                  >
                    <Icon icon={faList} size="lg" />
                    Logs
                  </Button>
                </Link>
              </Collapse>
            </>
          )}

          {hasAccess(auth, RULES.FRONTEND_SectionSwagger) && (
            <Link
              to={`/${ROUTES_PATHS.apidocs}`}
              onClick={() => setActive(ROUTES_PATHS.apidocs)}
            >
              <Button
                section
                active={active === ROUTES_PATHS.apidocs ? "true" : undefined}
              >
                <Icon icon={faCogs} size="lg" />
                API Documentation
              </Button>
            </Link>
          )}

          {hasAccess(auth, RULES.FRONTEND_SectionDoc) && (
            <Link
              to={`/${ROUTES_PATHS.documentation}`}
              onClick={() => setActive(ROUTES_PATHS.documentation)}
            >
              <Button
                section
                active={
                  active === ROUTES_PATHS.documentation ? "true" : undefined
                }
              >
                <Icon icon={faBook} size="lg" />
                Documentation
              </Button>
            </Link>
          )}

          <Button section onClick={() => dispatch(logout())}>
            <Icon icon={faSignOutAlt} size="lg" />
            Logout
          </Button>
        </div>
      </NavBar>
    </Nav>
  );
};

export default Navigation;
