import React from "react";
import Autocomplete from "./Form/Autocomplete";
import {
  selectIsListLoading,
  selectList,
} from "../store/ducks/countries/selectors";
import { fetchList } from "../store/ducks/countries";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const SelectCountry = (params: Props) => {
  return (
    <Autocomplete
      {...params}
      selectList={selectList}
      selectIsListLoading={selectIsListLoading}
      fetchList={fetchList}
      labelField="countryName"
      valueField="countryName"
      filterField="name"
    />
  );
};

export default SelectCountry;
