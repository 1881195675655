import React, { ReactFragment } from "react";
import TabPanelWrapper from "./styled/TabPanelWrapper";

interface Props {
  children: ReactFragment;
  value: number;
  index: number;
}

const TabPanel = ({ children, value, index }: Props) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      <TabPanelWrapper>{children}</TabPanelWrapper>
    </div>
  );
};

export default TabPanel;
