import styled from "styled-components";
import { Checkbox as MatCheckbox } from "@material-ui/core/";

const Checkbox = styled(MatCheckbox)`
  && {
    .MuiIconButton-label {
      color: ${(props) => props.theme.color.yellow};
    }
  }
`;

export default Checkbox;
