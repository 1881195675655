import React, { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../store";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import { genderOptions } from "../Profile/constants";
import SelectCountry from "../../components/SelectCountry";
import Checkbox from "../../components/Checkbox";
import Button from "../Login/styled/Button";
import { Form } from "react-final-form";
import { email } from "../../utils/form";
import PasswordInput from "../../components/Form/PasswordInput";
import ReCAPTCHA from "react-google-recaptcha";
import config from "config";
import toast from "../../components/Toaster";
import { signUp } from "../../store/ducks/auth";
import { FORM_ERROR } from "final-form";
import Icon from "../../styled/Icon";
import { faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Views } from "./constants";
import PurchaseView from "./styled/PurchaseView";
import { SignUpData } from "../SignUp/SignUp";
import { signUpFields } from "../SignUp/constants";

interface Props {
  view: Views;
  forwardTo: Views | undefined;
  setEmail: Dispatch<SetStateAction<string | null>>;
  goToStart: () => void;
  openThanks: () => void;
  openLogin: () => void;
}

const SignUp = ({
  forwardTo,
  setEmail,
  goToStart,
  openThanks,
  openLogin,
}: Props) => {
  const dispatch = useAppDispatch();

  const [reCaptchaValue, setReCaptchaValue] = useState<string | null>(null);

  const [newsletter, setNewsletter] = React.useState(false);

  const onSubmit = (values: SignUpData) => {
    if (!reCaptchaValue) {
      return toast.error(
        "Are you a crawler? Let your consciousness validate that you are human :)"
      );
    }

    return new Promise((resolve, reject) => {
      dispatch(signUp(values, { resolve, reject }));
    })
      .then(() => {
        if (forwardTo === Views.login) {
          setEmail(values.email);
          openLogin();
        } else {
          openThanks();
        }
        return;
      })
      .catch((error) => {
        return {
          [FORM_ERROR]: error,
        };
      });
  };

  return (
    <PurchaseView>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <h2>Sign Up</h2>
        </Grid>
        <Grid item>
          <p>your user ID consists of your 'firstname.lastname'.</p>
        </Grid>
      </Grid>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Input {...signUpFields.firstname} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Input {...signUpFields.lastname} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Input {...signUpFields.email} validation={email} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <PasswordInput
                  {...signUpFields.password}
                  extendedValidation={true}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Select options={genderOptions} {...signUpFields.gender} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SelectCountry {...signUpFields.country} />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item direction="column">
                <ReCAPTCHA
                  sitekey={config.checkout!.recaptchaKey}
                  onChange={setReCaptchaValue}
                />
              </Grid>

              <Grid item direction="column">
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Checkbox
                    label="Subscribe to our newsletter"
                    getValue={() => newsletter}
                    setValue={() => setNewsletter(!newsletter)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <br />
            <Grid
              container
              item
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Button blue="true" type="submit">
                  <Icon icon={faUserPlus} size="lg" />
                  Sign Up
                </Button>
              </Grid>
              <Grid item>
                <Button gray type="button" onClick={goToStart}>
                  <Icon icon={faTimes} size="lg" />
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </PurchaseView>
  );
};

export default SignUp;
