import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableWrapper from "../../styled/Table/TableWrapper";
import {
  selectChildItems,
  selectParentItems,
  selectListPagination,
} from "../../store/ducks/files/selectors";
import Icon from "../../styled/Icon";
import { faFolderOpen, faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";
import { fetchItems } from "../../store/ducks/files";
import TextLink from "../../styled/TextLink";
import {
  ChildItemInfo,
  DefaultFilteringParams,
  DefaultPaginationParams,
  FileType,
} from "../../store/ducks/files/types";
import { fileIcons } from "./constants";
import { SelectedItem } from "./index";
import TreeIdentifier from "./styled/Common/TreeIdentifier";

interface Props {
  getSearch: () => any;
  selectedItem: SelectedItem | null;
  setSelectedItem: Dispatch<SetStateAction<SelectedItem | null>>;
}

const TreeTable = ({ getSearch, selectedItem, setSelectedItem }: Props) => {
  const dispatch = useAppDispatch();

  const parentItems = useSelector(selectParentItems);
  const items = useSelector(selectChildItems);
  const pagination = useSelector(selectListPagination);

  useEffect(() => {
    dispatch(
      fetchItems({
        pagination: DefaultPaginationParams,
        filtering: DefaultFilteringParams,
      })
    );
  }, [dispatch]);

  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      fetchItems({
        pagination: {
          pageIndex: newPage,
        },
        filtering: getSearch(),
      })
    );
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const pageSize = parseInt((event.target as HTMLSelectElement).value, 10);
    dispatch(
      fetchItems({
        pagination: {
          pageSize,
          pageIndex: 0,
        },
        filtering: getSearch(),
      })
    );
  };

  const getParents = () =>
    parentItems
      ? parentItems.reduce((out, item) => {
          out[item.id] = {
            id: item.id,
            name: item.name,
            parentId: item.parentId,
          };
          return out;
        }, {} as any)
      : {};

  const parents = getParents();

  const goToParent = (id: string | null | undefined) => {
    if (id) {
      getFileSystem(id, { pageIndex: 0 });
      setSelectedItem({
        id: id,
        type: FileType.FOLDER,
        name: parents[id].name,
      });
    } else {
      getFileSystem(null, { pageIndex: 0 });
      setSelectedItem(null);
    }
  };

  const goToItem = (item: ChildItemInfo) => {
    if (item.type === FileType.FOLDER) {
      getFileSystem(item.id, { pageIndex: 0 });
    }
    setSelectedItem({
      id: item.id,
      type: item.type,
      name: item.name,
    });
  };

  const getFileSystem = (itemId: string | null, paginationParams: any) => {
    dispatch(
      fetchItems({
        pagination: {
          ...pagination,
          ...paginationParams,
        },
        filtering: {
          ...getSearch(),
          itemId: itemId,
        },
      })
    );
  };

  return (
    <>
      <TableWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Up</TableCell>
              <TableCell>Folder</TableCell>
              <TableCell>Item</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.parentId &&
                      parents[item.parentId] &&
                      parents[item.parentId].id && (
                        <TextLink
                          onClick={() =>
                            goToParent(parents[item.parentId!].parentId)
                          }
                        >
                          <Icon default="true" icon={faLevelUpAlt} size="lg" />
                        </TextLink>
                      )}
                  </TableCell>
                  <TableCell>
                    {item.parentId &&
                    parents[item.parentId] &&
                    parents[item.parentId].id ? (
                      <TextLink onClick={() => goToParent(item.parentId)}>
                        <Icon icon={faFolderOpen} size="lg" />
                        {parents[item.parentId].name}
                      </TextLink>
                    ) : (
                      "root"
                    )}
                  </TableCell>
                  <TableCell>
                    {selectedItem && selectedItem.id === item.id ? (
                      <div>
                        <Icon
                          icon={fileIcons[item.type]}
                          default="true"
                          size="lg"
                        />
                        <TreeIdentifier>{item.name}</TreeIdentifier>
                      </div>
                    ) : (
                      <TextLink onClick={() => goToItem(item)}>
                        <Icon icon={fileIcons[item.type]} default="true" />
                        <TreeIdentifier>{item.name}</TreeIdentifier>
                      </TextLink>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={pagination.totalElements || 0}
                rowsPerPage={pagination.pageSize || 0}
                page={pagination.pageIndex || 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableWrapper>
    </>
  );
};

export default TreeTable;
